<template>
  <div class="content-transference-component">
    <div
      class="card-transference"
      v-for="day in listTransferences"
      :key="day.id"
    >
      <div class="row">
        <h1 class="date">{{ day.day | moment('dddd, DD/MM/YY') }}</h1>
      </div>
      <div v-for="option in day.cashoutRequests" :key="option.id">
        <AppModalTransference
          :id="option.id"
          :status="status"
          :details="getDetails"
        />
        <a
          @click="openModal(option.id)"
          data-toggle="modal"
          :data-target="'#' + option.id"
          class="line"
        >
          <em
            :class="{
              'icon transfer gray': !option.automatic,
              'icon transfer-programmed gray': option.automatic,
            }"
          ></em>
          <div class="content-card-transference">
            <h1 class="title-transference mt-2 pl-4">{{ getNote(option) }}</h1>
            <div class="status-transference">
              <h1>
                {{ option.id }} &nbsp; - &nbsp;
                <b :class="'status-' + option.status">{{
                  getLabelStatus(option.status)
                }}</b>
              </h1>
            </div>
            <div :class="'status-web-' + option.status">
              <p>{{ getLabelStatus(option.status) }}</p>
            </div>
          </div>
          <div class="value" :class="{ tachado: option.status === 'canceled' }">
            {{
              option.amount
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div v-if="isMobile" class="mx-auto">
        <button
          v-if="getMore"
          type="button"
          class="btn btn-outline-orange-more mb-6"
          @click="getMoreTransfers()"
        >
          Carregar mais
        </button>
      </div>
      <div v-else class="mx-auto justify-content-md-center">
        <app-paginator :count="count" :perPage="perPage" @exchange="turnPage" />
      </div>
    </div>
  </div>
</template>

<script>
import AppModalTransference from '@/components/account/transference/modal/ModalTransference';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AppPaginator from '@/components/theme/Paginator';
export default {
  name: 'ContentTransference',
  data() {
    return {
      details: {},
      status: {
        pending: 'pendente',
        provisioned: 'em processamento',
        approved: 'confirmada',
        canceled: 'cancelada',
        reproved: 'devolvida',
      },
    };
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    listTransferences: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    count: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    perPage: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    isMobile: {
      required: true,
    },
  },
  components: {
    AppModalTransference,
    AppPaginator,
  },
  methods: {
    async getDetailsTransferApi(id) {
      const api = new PaymentAccountsApi();
      let response = await api.getDetailsTransfer(id);

      if (response.status == 200) {
        this.details = response.data;
      } else {
        this.details = null;
      }
    },
    getLabelStatus(status) {
      return this.status[status];
    },
    async openModal(id) {
      this.$bus.$emit('spinner-run');
      await this.getDetailsTransferApi(id);
      this.$bus.$emit('spinner-stop');
      if (this.details != null) {
        $(`#${id}`).modal();
      } else {
        $(`#${id}`).modal('hide');
      }
    },
    getNote(option) {
      if (option.splitterIdDestination != null) {
        return `Transferência para splitter #${option.splitterIdDestination}.`;
      }

      if (option.type.toLowerCase() === 'ted') {
        return 'Transferência TED.';
      }

      if (option.automatic) {
        return 'Transferência automática.';
      }

      return 'Transferência manual.';
    },
    turnPage(numberPage) {
      this.$emit('captureTurnPage', numberPage);
    },
    getMoreTransfers() {
      this.$emit('captureMoreTransfers', true);
    },
  },
  computed: {
    getDetails() {
      return this.details;
    },
    getMore() {
      return this.count > this.listTransferences.length;
    },
  },
};
</script>
