<template>
  <div class="content-transference">
    <div class="top p-4">
      <div class="content-one">
        <div class="content-center" v-if="addTransferPermission">
          <WhiteLabel>
            <a
              data-toggle="modal"
              :data-target="'#' + modalTransferBase"
              class="btn btn-transference"
              v-if="!getBalance.balanceBlocked"
            >
              <em class="icon transfer white"></em>Transferir
            </a>
          </WhiteLabel>
          <WhiteLabel>
            <a
              data-toggle="modal"
              :data-target="'#' + modalBalanceBlocked"
              class="btn btn-transference"
              v-if="getBalance.balanceBlocked"
            >
              <em class="icon transfer white"></em>Transferir
            </a>
          </WhiteLabel>
          <AppModalBalanceBlocked :id="modalBalanceBlocked" />
        </div>
        <!-- <div class="content-center">
          <a @click="openModal" class="cursor-pointer">
            <h1 class="automatic-transfer">
              <em class="icon transfer-programmed gray-2"></em>
              Transf. automáticas semanais:
              {{ getSettingsFormatting }}
            </h1>
          </a>
          <div class="icon blue-arrow">
            <AppModalPreferencesBase
              id="modalPreferencesBase"
              :settings="settings"
            />
          </div>
        </div> -->
      </div>
      <div class="content-two">
        <AppFormFilterTransference
          @captureTransference="transferenceAfterEvent"
          @captureNotFound="notFound"
          :numberPage="numberPage"
          :perPage="perPage"
          :moreTransfers="getMoreTransfers"
          :isMobile="isMobile"
        />
      </div>
    </div>
    <div v-if="getCount === 0">
      <NotFoundTransference :rangeDate="rangeDateNotFound" />
    </div>
    <div v-if="getCount !== 0">
      <ContentTransference
        :listTransferences="getListTransferences"
        :count="getCount"
        :perPage="perPage"
        :isMobile="isMobile"
        @captureTurnPage="turnPageAfterEvent"
        @captureMoreTransfers="moreTransfersAfterEvent"
      />
    </div>
    <AppModalTransferBase :id="modalTransferBase" :balance="getBalance" />
  </div>
</template>
<script>
import AppFormFilterTransference from '@/components/account/transference/FormFilterTransference';
import NotFoundTransference from '@/components/account/transference/NotFoundTransference';
import ContentTransference from '@/components/account/transference/ContentTransference';
import AppModalBalanceBlocked from '@/components/account/transference/modal/ModalBalanceBlocked';
import AppModalTransferBase from '@/components/account/transference/modal/ModalTransferBase';
// import AppModalPreferencesBase from '@/components/account/preferences/ModalPreferencesBase';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import { mapGetters } from 'vuex';

export default {
  name: 'Transference',
  components: {
    AppFormFilterTransference,
    NotFoundTransference,
    ContentTransference,
    AppModalBalanceBlocked,
    AppModalTransferBase,
    // AppModalPreferencesBase,
    WhiteLabel,
  },
  data() {
    return {
      rangeDateNotFound: {
        start: new Date(),
        end: new Date(),
      },
      settings: null,
      balance: {},
      modalBalanceBlocked: 'modalBalanceBlocked',
      modalTransferBase: 'modalTransferBase',
      transferences: null,
      automaticDays: null,
      // modalPreferencesBase: 'modalPreferencesBase',
      numberPage: 1,
      perPage: 12,
      moreTransfers: 0,
    };
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getBalanceAccount();
    await this.getSettingsAccount();
    this.$bus.$emit('spinner-stop');

    await this.getSettingsAccount();
    this.$bus.$on('closePreferences', async(result) => {
      await this.getSettingsAccount();
    });
  },
  methods: {
    async getSettingsAccount() {
      const api = new PaymentAccountsApi();
      this.settings = await api.getSettings();
    },
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },
    transferenceAfterEvent(transferences) {
      this.transferences = transferences;
    },
    notFound(startDate, endDate) {
      this.rangeDateNotFound.end = endDate;
      this.rangeDateNotFound.start = startDate;
    },
    turnPageAfterEvent(numberPage) {
      this.numberPage = numberPage;
    },
    moreTransfersAfterEvent(moreTransfers) {
      this.moreTransfers++;
    },
    // openModal() {
    //   /* eslint-disable no-undef */
    //   $('#modalPreferencesBase').modal('show');
    // },
  },
  computed: {
    getBalance() {
      return this.balance;
    },
    getCount() {
      return this.transferences !== null ? this.transferences.count : 0;
    },
    getMoreTransfers() {
      return this.moreTransfers;
    },
    getListTransferences() {
      return this.transferences.days;
    },
    getSettingsFormatting() {
      let daysFormatting = '';
      if (this.settings) {
        if (this.settings.isMonday) daysFormatting = 'Seg, ';
        if (this.settings.isTuesday) daysFormatting += 'Ter, ';
        if (this.settings.isWednesday) daysFormatting += 'Qua, ';
        if (this.settings.isThursday) daysFormatting += 'Qui, ';
        if (this.settings.isFriday) daysFormatting += 'Sex,';

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        } else {
          const last = daysFormatting.lastIndexOf(',');
          daysFormatting = daysFormatting.substring(last, '');
        }
      }

      return daysFormatting;
    },
    isAndroid() {
      return navigator.userAgent.match(/Android/i);
    },
    isIOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    isMobile() {
      return navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
    },
    isAccountHolder() {
      if (this.whoAmI) {
        return this.whoAmI.accessedByHolder;
      }
    },
    paymentAccountTransferPermissionIndex() {
      return this.permissions.findIndex((x) => x.name === 'paymentAccounts');
    },
    addTransferPermission() {
      if (
        this.permissions[this.paymentAccountTransferPermissionIndex].grant ===
          'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      permissions: 'account/permissions',
      whoAmI: 'account/whoAmI',
    }),
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
