<template>
  <ButtonFilter>
    <form class="form-filter" @submit.prevent="send">
      <h1 class="sub-title">Período</h1>
      <div class="form-group content-form">
        <div class="dropdown period-mobile">
          <div
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center
            "
            id="dropdownMenuButtonPeriod"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-nowrap lbl-select">{{ periodSelectLabel }}</span>
          </div>
          <div
            class="dropdown-menu w-100"
            aria-labelledby="dropdownMenuButtonPeriod"
            @click.stop
          >
            <div
              class="material-radio"
              v-for="(data, index) in period"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="periodmobile"
                :id="data.value"
                :value="data.value"
                v-model="selectedPeriod"
              />
              <label :for="data.value" class="form-check-label lbl-filter">{{
                data.label
              }}</label>
            </div>
            <div class="report-period" v-if="selectedPeriod === 4">
              <div
                id="calendar-date-start"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="changeCalendarDateStart"
                class="
                  form-control
                  dropdown-select-like
                  rounded-pill
                  text-left text-lg-center text-nowrap
                "
              >
                <div
                  v-if="
                    selectedDate.start.getTime() !== selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">&nbsp;</span>
                  <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
                  <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
                  <span>{{ selectedDate.end | moment("DD/MM/YY") }}</span>
                </div>
                <div
                  v-if="
                    selectedDate.start.getTime() === selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">no dia&nbsp;</span>
                  <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
                </div>
              </div>
              <div
                class="dropdown-menu"
                aria-labelledby="calendar-date-start"
                @click.stop
                :class="{ show: calendarDateStart }"
              >
                <v-date-picker
                  mode="range"
                  v-model="selectedDate"
                  :show-day-popover="false"
                  :tint-color="datePickerColor"
                  show-caps
                  is-inline
                ></v-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown period-web">
          <div class="form-group content-form">
            <div class="dropdown">
              <div
                class="
                  form-control
                  dropdown-select-like
                  rounded-pill
                  text-left text-lg-center text-nowrap
                "
                id="dropdownMenuButton-c"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  v-if="
                    selectedDate.start.getTime() !== selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">&nbsp;</span>
                  <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
                  <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
                  <span>{{ selectedDate.end | moment("DD/MM/YY") }}</span>
                </div>
                <div
                  v-if="
                    selectedDate.start.getTime() === selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">no dia&nbsp;</span>
                  <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
                </div>
              </div>
              <div
                class="dropdown-menu dropdown-menu-fixed calendar"
                aria-labelledby="dropdownMenuButton-c"
                @click.stop
              >
                <v-date-picker
                  mode="range"
                  v-model="selectedDate"
                  :show-day-popover="false"
                  :tint-color="datePickerColor"
                  show-caps
                  is-inline
                  is-double-paned
                ></v-date-picker>
                <hr />
                <div class="footer-calendar">
                  <div>
                    <div class="dropdown">
                      <div
                        class="
                          form-control
                          dropdown-select-like
                          rounded-pill
                          text-left text-lg-center
                        "
                        id="dropdownMenuButton-x"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="changeShowPeriod()"
                      >
                        <span class="text-nowrap lbl-select">{{
                          periodSelectLabel
                        }}</span>
                      </div>
                      <div
                        class="dropdown-menu w-100 period"
                        :class="{ show: showPeriod }"
                        aria-labelledby="dropdownMenuButton-x"
                        @click.stop
                      >
                        <div
                          class="material-radio"
                          v-for="(data, index) in period"
                          :key="index"
                        >
                          <label class="form-check-label lbl-filter">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="status-period"
                              :value="data.value"
                              v-model="selectedPeriod"
                            />
                            {{ data.label }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field-date">
                    <div class="date-start border-date">
                      <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
                    </div>
                    <div class="calendar-txt">
                      <p>a</p>
                    </div>
                    <div class="date-end border-date">
                      <span>{{ selectedDate.end | moment("DD/MM/YY") }}</span>
                    </div>
                  </div>
                  <div class="content-btn">
                    <WhiteLabel>
                      <button class="btn btn-applay" @click="send">
                        Aplicar
                      </button>
                    </WhiteLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="report-period period-mobile" v-if="selectedPeriod === 4">
          <div
            id="calendar-date-start"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center text-nowrap
            "
          >
            <!-- @click="changeCalendarDateStart" -->
            <div
              v-if="selectedDate.start.getTime() !== selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">&nbsp;</span>
              <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
              <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
              <span>{{ selectedDate.end | moment("DD/MM/YY") }}</span>
            </div>
            <div
              v-if="selectedDate.start.getTime() === selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">no dia&nbsp;</span>
              <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
            </div>
          </div>
          <div
            class="dropdown-menu"
            aria-labelledby="calendar-date-start"
            @click.stop
          >
            <!-- :class="{'show':calendarDateStart}" -->
            <v-date-picker
              mode="range"
              v-model="selectedDate"
              :show-day-popover="false"
              tint-color="#FF7606"
              show-caps
              is-inline
            ></v-date-picker>
          </div>
        </div>
      </div>
      <div class="form-group content-form">
        <div class="detail-web-mobile">
          <WhiteLabel>
            <AppToggle
              :label="detalharLancamento.label"
              :value="detalharLancamento.value"
              @valueToggle="setValueDetalharLancamento($event)"
            />
          </WhiteLabel>
        </div>
      </div>
      <div class="d-flex d-md-none mt-5">
        <button type="submit" class="btn btn-send-filter">
          Aplicar filtros
        </button>
      </div>
    </form>
  </ButtonFilter>
</template>
<script>
/* eslint-disable no-undef */
import ButtonFilter from '@/components/shared/ButtonFilter';
import AppToggle from '@/components/shared/Toggle';
import WhiteLabel from '@/components/shared/WhiteLabel';
import UpcomingReleasesFilters from '@/models/UpcomingReleasesFilters';
import moment from 'moment';
export default {
  name: 'AppFormFilterUpcomingReleases',
  components: {
    ButtonFilter,
    AppToggle,
    WhiteLabel,
  },
  data() {
    return {
      period: [
        { value: 1, label: 'Próximos 7 dias' },
        { value: 2, label: 'Próximos 30 dias' },
        { value: 3, label: 'Próximos 3 meses' },
        { value: 4, label: 'Informar período' },
      ],
      selectedPeriod: 1,
      detalharLancamento: { value: false, label: 'Detalhar lançamentos' },
      selectedDate: {
        start: new Date(),
        end: new Date().addDays(30),
      },
      showPeriod: false,
      filter: new UpcomingReleasesFilters(),
      calendarDateStart: false,
    };
  },
  async created() {
    await this.send();
  },
  methods: {
    changeShowDatepicker: function() {
      $('.dropdown-menu.calendar').removeClass('show');
    },
    changeShowPeriod: function() {
      this.showPeriod = !this.showPeriod;
    },
    setValueDetalharLancamento($event) {
      this.detalharLancamento.value = $event;
    },
    send() {
      this.changeShowDatepicker();
      this.$emit('filter', {
        params: this.filter,
        detailRelease: this.detalharLancamento.value,
      });
    },
    changeCalendarDateStart: function(event) {
      this.calendarDateStart = !this.calendarDateStart;
    },
  },
  watch: {
    'selectedDate.start': async function(newValue) {
      this.filter.startDate = moment(newValue).format('YYYY-MM-DD');
    },
    'selectedDate.end': async function(newValue) {
      this.filter.endDate = moment(newValue).format('YYYY-MM-DD');
    },
    selectedPeriod: async function(newValue) {
      this.filter.index = 0;
      switch (newValue) {
      case 1:
        this.selectedDate.start = new Date().addDays(-7);
        this.selectedDate.end = new Date();
        break;
      case 2:
        this.selectedDate.start = new Date();
        this.selectedDate.end = new Date().addDays(30);
        break;
      case 3:
        this.selectedDate.start = new Date();
        this.selectedDate.end = new Date().addDays(90);
        break;
      case 4:
        break;
      }
    },
    'detalharLancamento.value': async function(newValue) {
      this.send();
    },
  },
  computed: {
    periodSelectLabel: function() {
      let objetc = this.period.filter((s) => s.value === this.selectedPeriod);
      if (objetc.length > 0) {
        return objetc[0].label;
      } else {
        return 'Selecione Período';
      }
    },
    datePickerColor: function() {
      return window.localStorage.getItem('tertiaryColor') || '#FF7606';
    },
  },
};
</script>
