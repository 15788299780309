<template>
  <div v-if="!switchedToTED">
    <a @click="selectDestino(1)">
      <div class="other-user">
        <em class="icon icon-user m-3"></em>
        <span class="user bold">
          Transferir para a conta bancária do Splitter
        </span>
        <em class="arrow-right-icon-new icon gray-2"></em>
      </div>
    </a>
    <a @click="selectDestino(2)">
      <div class="other-user">
        <em class="icon icon-people m-3"></em>
        <span class="user bold">TED partindo do Splitter</span>
        <em class="arrow-right-icon-new icon gray-2"></em>
      </div>
    </a>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import AccountApi from '@/services/AccountApi';

export default {
  name: 'SplitterModalSelectDestination',
  props: {
    id: {
      type: String,
      default: '',
    },
    balance: {
      type: Number,
      default: 0,
    },
    changeControl: {
      type: Function,
      default: () => 1,
    },
  },
  data() {
    return {
      switchedToTED: false,
      bankData: 0,
      modalHowMuchTransferOwnAccount:
        'modalHowMuchTransferOwnAccount' +
        Math.random().toString('16').slice(2, 8),
      modalWhoIstheFavored:
        'modalWhoIstheFavored' + Math.random().toString('16').slice(2, 8),
    };
  },
  async created() {
    $(function () {
      $('#tooltipDestination').tooltip();
    });
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getBankDataApi();
    this.$bus.$emit('spinner-stop');

    // $(function() {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
  },
  methods: {
    async getBankDataApi() {
      const api = new AccountApi();
      this.bankData = await api.getBankData();
    },
    selectDestino(number) {
      this.changeControl(number);
    },
  },
  computed: {
    getBankAccount() {
      return this.bankData.bankAccount;
    },
  },
};
</script>
