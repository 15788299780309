<template>
    <div class="container col-12 col-lg-6 col-md-6 p-3">
        <div class="card shadow-sm rounded">
            <div class="card-body container">
                <div class="row justify-content-center mt-2">
                    <h3>{{title}}</h3>
                </div>
                <div class="row mt-4">
                    <div class="col-12 mb-2">
                        <h4>
                            Limite diurno
                        </h4>
                    </div>
                    <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                        <label>Em dias úteis</label>
                        <money
                            v-model="dailyWorkingDaysLimitValue"
                            class="form-control input-value input-money"
                            :decimal="','"
                            :thousands="'.'"
                            :precision="2"
                            :disabled="!editMode"
                        ></money>
                        <small class="text-info" :style="{visibility: editMode? 'visible' : 'hidden'}">
                            O valor máximo é {{dailyWorkingDaysMaxLimit | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })}}.
                        </small>
                    </div>
                    <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                        <label>Aos finais de semana</label>
                        <money
                            v-model="dailyWeekendLimitValue"
                            class="form-control input-value input-money"
                            :decimal="','"
                            :thousands="'.'"
                            :precision="2"
                            :disabled="!editMode"
                        ></money>
                        <small class="text-info" :style="{visibility: editMode? 'visible' : 'hidden'}">
                            O valor máximo é {{dailyWeekendMaxLimit | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })}}.
                        </small>
                    </div>
                </div>
                <div class="header-box"></div>
                <div class="row mt-4">
                    <div class="col-12 mb-2">
                        <h4>
                            Limite noturno
                        </h4>
                    </div>
                    <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                        <label>Em dias úteis</label>
                        <money
                            v-model="nocturnalWorkingDaysLimitValue"
                            class="form-control input-value input-money"
                            :decimal="','"
                            :thousands="'.'"
                            :precision="2"
                            :disabled="!editMode"
                        ></money>
                        <small class="text-info" :style="{visibility: editMode? 'visible' : 'hidden'}">
                            O valor máximo é {{nocturnalWorkingDaysMaxLimit | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })}}.
                        </small>
                    </div>
                    <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                        <label>Aos finais de semana</label>
                        <money
                            v-model="nocturnalWeekendLimitValue"
                            class="form-control input-value input-money"
                            :decimal="','"
                            :thousands="'.'"
                            :precision="2"
                            :disabled="!editMode"
                        ></money>
                        <small class="text-info" :style="{visibility: editMode? 'visible' : 'hidden'}">
                            O valor máximo é {{nocturnalWeekendMaxLimit | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })}}.
                        </small>
                    </div>
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="col-6 col-lg-6 col-md-6" v-if="!editMode">
                        <button
                        class="btn btn-md btn-orange col-12"
                        @click="getMaxLimitValue()"
                        > Editar Limites</button>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6 col-sm-6" v-if="editMode">
                        <button
                        class="btn btn-md btn-secondary col-12"
                        @click="cancelUpdate()"
                        > Cancelar</button>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6 col-sm-6" v-if="editMode">
                        <button
                        class="btn btn-md btn-orange col-12"
                        @click="saveLimit()"
                        >Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import TransferLimitError from '@/errors/TransferLimitError';

export default {
    name: 'EditLimits',
    mounted(){
        this.dailyWorkingDaysLimitValue = this.dailyWorkingDaysLimit == null? 0.00 : this.dailyWorkingDaysLimit;
        this.nocturnalWorkingDaysLimitValue = this.nocturnalWorkingDaysLimit == null? 0.00 : this.nocturnalWorkingDaysLimit;
        this.dailyWeekendLimitValue = this.dailyWeekendLimit == null? 0.00 : this.dailyWeekendLimit;
        this.nocturnalWeekendLimitValue = this.nocturnalWeekendLimit == null? 0.00 : this.nocturnalWeekendLimit;
    },
    props:{
        // eslint-disable-next-line vue/require-prop-types
        title:{
            default: null,
        },
        // eslint-disable-next-line vue/require-prop-types
        dailyWorkingDaysLimit:{
            default: null,
        },
        // eslint-disable-next-line vue/require-prop-types
        nocturnalWorkingDaysLimit:{
            default: null,
        },
        // eslint-disable-next-line vue/require-prop-types
        dailyWeekendLimit:{
            default: null,
        },
        // eslint-disable-next-line vue/require-prop-types
        nocturnalWeekendLimit:{
            default: null,
        },
        // eslint-disable-next-line vue/require-prop-types
        sameHolderDestination:{
            default: true,
        },
    },
    data: () => ({
        editMode: false,
        dailyWorkingDaysLimitValue: 0.00,
        nocturnalWorkingDaysLimitValue: 0.00,
        dailyWeekendLimitValue: 0.00,
        nocturnalWeekendLimitValue: 0.00,
        dailyWorkingDaysMaxLimit: 0.00,
        nocturnalWorkingDaysMaxLimit: 0.00,
        dailyWeekendMaxLimit: 0.00,
        nocturnalWeekendMaxLimit: 0.00,
    }),
    watch:{
        dailyWorkingDaysLimit: function(value){
            this.dailyWorkingDaysLimitValue = value == null? 0 : value;
        },
        nocturnalWorkingDaysLimit: function(value){
            this.nocturnalWorkingDaysLimitValue = value == null? 0 : value;
        },
        dailyWeekendLimit: function(value){
            this.dailyWeekendLimitValue = value == null? 0 : value;
        },
        nocturnalWeekendLimit: function(value){
            this.nocturnalWeekendLimitValue = value == null? 0 : value;
        },
    },
    methods:{
        switchBetweenEditView(value){
            this.$emit('switchBetweenEditViewAnotherHolder', value);
            this.$emit('switchBetweenEditViewSameHolder', value);
            this.editMode = value;
        },
        async getMaxLimitValue(){
            this.$bus.$emit('spinner-run');
            let paymentAccApi = new PaymentAccountsApi();
            let response = await paymentAccApi.getMaxLimits(this.sameHolderDestination);
            this.$bus.$emit('spinner-stop');
            if (response.status !== 200) {
                const modelError = new TransferLimitError(response.data);
                const error = modelError.getMessage();
                this.$alert.error('Ops', error);
                return;
            }
            let workingDays = response.data.transferAmountLimits.find(e => e.workingDaysLimit != null);
            let weekend = response.data.transferAmountLimits.find(e => e.weekendLimit != null);
            this.dailyWorkingDaysMaxLimit = workingDays.workingDaysLimit.dailyCumulativeAmount;
            this.nocturnalWorkingDaysMaxLimit = workingDays.workingDaysLimit.nocturnalCumulativeAmount;
            this.dailyWeekendMaxLimit = weekend.weekendLimit.dailyCumulativeAmount;
            this.nocturnalWeekendMaxLimit = weekend.weekendLimit.nocturnalCumulativeAmount;

            this.switchBetweenEditView(true);
        },
        checkLimits(){
            if(this.dailyWorkingDaysLimitValue < 1 ||
               this.nocturnalWorkingDaysLimitValue < 1 ||
               this.dailyWeekendLimitValue < 1 ||
               this.nocturnalWeekendLimitValue < 1){
                this.$alert.error('Ops', 'Valor do limite não pode ser menor que R$ 1,00');
                return false;
            }

            return true;
        },
        cancelUpdate(){
            this.$emit('refresh');
            this.switchBetweenEditView(false);
        },
        async saveLimit(){
            if(!this.checkLimits())
                return;

            const model = {
                product: 'external-transfer',
                sameHolderDestination: this.sameHolderDestination,
                workingDaysLimit:{
                    nocturnalCumulativeAmount: this.nocturnalWorkingDaysLimitValue,
                    dailyCumulativeAmount: this.dailyWorkingDaysLimitValue,
                },
                weekendLimit:{
                    nocturnalCumulativeAmount: this.nocturnalWeekendLimitValue,
                    dailyCumulativeAmount: this.dailyWeekendLimitValue,
                },
            };
            this.$bus.$emit('spinner-run');
            let paymentAccApi = new PaymentAccountsApi();
            let response = await paymentAccApi.updateLimit(model);
            this.$bus.$emit('spinner-stop');
            if(response.status != 200){
                const modelError = new TransferLimitError(response.data);
                const error = modelError.getMessage();
                this.$alert.error('Ops', error);
                return;
            }
            this.switchBetweenEditView(false);
            this.$emit('refresh');
        },
    },
};
</script>
<style scoped>
    .card{
        border: 2px solid;
    }
</style>
