import { withParams } from 'vuelidate/lib';

const bankBranch = withParams({ type: 'bankBranch' }, value => {
  if (!value) return true;

  var regex = new RegExp('^[0-9]{1,4}(-[0-9a-zA-Z]{1,2})?$');
  return regex.test(value);
});

export { bankBranch };
