import axios from 'axios';

class Splitter {
  async getAll({ name, taxDocument, index, length }) {
    let params = {
      name: name,
      taxDocuments: taxDocument,
      index: index,
      length: length,
    };

    const allSplitters = await axios.get(`${process.env.VUE_APP_PAYMENTS_API_URL}/splitters`, { params });

    return allSplitters.data;
  }

  async get(id) {
    const found = await axios.get(`${process.env.VUE_APP_PAYMENTS_API_URL}/splitters/find/${id}`);

    return found.data;
  }

  async getBalance(parameters) {
    const model = parameters;
    const found = await axios.post(`${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/splitter-balance`, model);

    return found.data;
  }

  async create(splitter) {
    const payload = {
      name: splitter.name,
      anticipatedTransfer: splitter.anticipatedTransfer,
      email: splitter.email,
      mobile: splitter.mobile,
      confirmEmail: splitter.confirmEmail,
      address: {
        district: splitter.address.district,
        line1: splitter.address.line1,
        line2: splitter.address.line2,
        streetNumber: splitter.address.streetNumber,
        zipCode: splitter.address.zipCode,
        cityCode: splitter.address.cityCode,
        state: splitter.address.state,
      },
      bankAccount: {
        holderName: splitter.bankAccount.holderName,
        taxDocument: splitter.bankAccount.taxDocument,
        bankNumber: splitter.bankAccount.bankNumber,
        accountNumber: splitter.bankAccount.accountNumber,
        bankBranchNumber: splitter.bankAccount.bankBranchNumber,
        variation: splitter.bankAccount.type === 'poupanca' ? splitter.bankAccount.variation : null,
        type: splitter.bankAccount.type,
      },
    };
    const created = await axios.post(`${process.env.VUE_APP_PAYMENTS_API_URL}/splitters`, payload);

    return created.data;
  }

  async search(name, index, length) {
    const params = {
      name, index, length,
    };

    const result = await axios.get(`${process.env.VUE_APP_PAYMENTS_API_URL}/splitters`, { params });

    return result.data;
  }

  async update(id, splitter) {
    const payload = {
      name: splitter.name,
      anticipatedTransfer: splitter.anticipatedTransfer,
      email: splitter.email,
      mobile: splitter.mobile,
      confirmEmail: splitter.confirmEmail,
      address: {
        district: splitter.address.district,
        line1: splitter.address.line1,
        line2: splitter.address.line2,
        streetNumber: splitter.address.streetNumber,
        zipCode: splitter.address.zipCode,
        cityCode: splitter.address.cityCode,
        state: splitter.address.state,
      },
      bankAccount: {
        holderName: splitter.bankAccount.holderName,
        taxDocument: splitter.bankAccount.taxDocument,
        bankNumber: splitter.bankAccount.bankNumber,
        accountNumber: splitter.bankAccount.accountNumber,
        bankBranchNumber: splitter.bankAccount.bankBranchNumber,
        variation: splitter.bankAccount.type === 'poupanca' ? splitter.bankAccount.variation : null,
        type: splitter.bankAccount.type,
      },
    };
    const updated = await axios.put(`${process.env.VUE_APP_PAYMENTS_API_URL}/splitters/${id}`, payload);

    return updated.data;
  }
}

export default Splitter;
