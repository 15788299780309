export class Address {
  district = '';
  line1 = '';
  line2 = '';
  streetNumber = '';
  zipCode = '';
  cityCode = '';
  city = '';
  state = '';
}

export class BankAccount {
  holderName = '';
  taxDocument = '';
  bankNumber = '';
  accountNumber = '';
  bankBranchNumber = '';
  variation = '';
  type = 'corrente';
}

export class Splitter {
  id = '';
  name = '';
  address = new Address();
  bankAccount = new BankAccount();
  balanceBlocked = false;
  email = '';
  mobile = '';
  confirmEmail = '';
  transferDays = 32;
  anticipatedTransfer = false;
}

export class SplitterParams extends Splitter {
  constructor(splitter) {
    this.id = splitter.id || '';
    this.name = splitter.name || '';
    this.transferDays = splitter.transferDays || 0;
    this.anticipatedTransfer = splitter.anticipatedTransfer || false;
    this.address = {
      district: splitter.district || '',
      line1: splitter.line1 || '',
      line2: splitter.line2 || '',
      streetNumber: splitter.streetNumber || '',
      zipCode: splitter.zipCode || '',
      cityCode: splitter.cityCode || '',
      city: splitter.city || '',
      state: splitter.state || '',
    };
    this.bankAccount = {
      holderName: splitter.holderName || '',
      taxDocument: splitter.taxDocument || '',
      bankNumber: splitter.bankNumber || '',
      accountNumber: splitter.accountNumber || '',
      bankBranchNumber: splitter.bankBranchNumber || '',
      variation: variation || '',
      type: splitter.type || '',
    };
  }
}

export class SplitterList {
  splitters = [];
  count = 0;
}

export class SplitterBalance {
  id = '';
  splitterId = '';
  transferFee = 0;
  tedFee = 0;
  currentBalance = 0;
  availableBalance = 0;
  futureBalance = 0;
  anticipatedBalance = 0;
  balanceBlocked = false;
}
