<template>
  <div>
    <!-- <ModalBase :hasFooter="false" :id="id"> -->
    <div class="content d-flex flex-column align-items-center settings">
      <em class="icon-check icon mt-6"></em>
      <span class="title">Configurações Salvas!</span>
      <span class="text-center"
        >O saldo da sua conta será transferido todas as {{ days }}</span
      >
      <WhiteLabel>
        <button
          class="
            btn btn-orange btn-gray btn-pill
            pl-5
            pr-5
            align-self-center
            mt-6
            mb-4
          "
          aria-label="Close"
          data-dismiss="modal"
          @click="close"
        >
          Concluir
        </button>
      </WhiteLabel>
    </div>
    <!-- </ModalBase> -->
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalSettingsSave',
  components: {
    WhiteLabel,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    days: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$bus.$emit('closePreferences');
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 1rem;

  .icon {
    font-size: 4rem;
    padding: 1rem;
    padding-bottom: 4rem;
    color: #afdb26;
  }
  .title {
    font-size: 1.4rem;
    display: block;
  }
}
</style>
