import {
  Offset,
} from '@/models/Offset';
import moment from 'moment';

export default class TransferenceFilters {
  startDate = moment().add(-7, 'days').format('YYYY-MM-DD')
  endDate = moment().format('YYYY-MM-DD')
  status = 'all'
  offset = new Offset()
}
