<template>
  <div>
    <section v-for="(period, indexPeriod) in historic.days" :key="indexPeriod">
      <div class="header-release">
        <span class="date" v-if="period.day">{{
          period.day | moment('ddd, DD/MM/YYYY')
        }}</span>
        <div>
          <span :class="[period.amount < 0 ? 'text-red' : 'text-color-green']">
            {{
              period.amount
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </span>
        </div>
      </div>
      <div class="content-release">
        <section
          class="pt-2"
          v-for="(release, indexRelease) in period.transactions"
          :key="indexRelease"
        >
          <div
            class="header-collapse"
            :data-toggle="!detailsData ? 'collapse' : false"
            :href="`#${indexPeriod}${indexRelease}`"
            role="button"
            aria-expanded="false"
            :aria-controls="`${indexPeriod}${indexRelease}`"
            @click="
              !detailsData
                ? getDetails(period, indexPeriod, indexRelease, release.type)
                : null
            "
          >
            <div class="types">
              <AppIconHistoric :type="release.type" :count="release.count" />
            </div>
            <div class="value-release inline-flex">
              <span
                :class="[
                  release.amount < 0 || release.type === 'canceled'
                    ? 'text-red'
                    : 'text-color-green',
                ]"
                >{{ release.type === 'canceled' ? '-' : ''
                }}{{
                  release.amount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}</span
              >
              <em
                v-if="!detailsData"
                :id="`icon-${indexPeriod}${indexRelease}`"
                class="icon-arrow-down"
              ></em>
            </div>
          </div>
          <div :id="`${indexPeriod}${indexRelease}`">
            <div>
              <div
                class="details-release"
                v-for="(operation, indice) in getIndex(
                  indexPeriod,
                  indexRelease
                )"
                :key="indice"
              >
                <div>
                  <a
                    :data-toggle="'modal'"
                    :data-target="'#' + modalreleasedetails"
                    class="btn-transference"
                    @click="setTransactionOpened(operation)"
                  >
                    <div class="line1">
                      <span>{{ description(operation, release.type) }}</span>
                      <span
                        class="value"
                        :class="[
                          operation.amount < 0 || operation.type === 'canceled'
                            ? 'text-red'
                            : 'text-color-green',
                        ]"
                        >{{ operation.type === 'canceled' ? '-' : ''
                        }}{{
                          operation.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}</span
                      >
                    </div>
                    <div class="line2">
                      <span></span>
                      <span>{{ operation.datetime }}</span>
                    </div>
                  </a>
                </div>
                <div
                  class="line2"
                  v-if="
                    getLastItem(indexPeriod, indexRelease) === operation &&
                    showMore(indexPeriod, indexRelease)
                  "
                >
                  <button
                    type="submit"
                    class="btn btn-orange btn-load"
                    @click="
                      getDetails(
                        period,
                        indexPeriod,
                        indexRelease,
                        release.type
                      )
                    "
                  >
                    Carregar mais
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <AppModalReleaseDetails
      :id="modalreleasedetails"
      :transaction="transactionOpened"
      :origin="view"
      @open-transaction="openOriginalPixDebitTransaction($event)"
    />
  </div>
</template>
<script>
import AppModalReleaseDetails from '@/components/account/ModalReleaseDetails';
import AppIconHistoric from '@/components/shared/IconHistoric';
import moment from 'moment';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import PaymentsApi from '@/services/PaymentsApi';

export default {
  name: 'AppHistoric',

  components: {
    AppModalReleaseDetails,
    AppIconHistoric,
  },

  props: {
    historic: {
      required: true,
      type: Object,
    },
    view: {
      type: String,
      required: true,
    },
    detailsData: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    transactionOpened: {},
    details: [],
    modalreleasedetails: 'modalreleasedetails',
    lengthDefault: 30,
  }),

  watch: {
    detailsData: function (newValue) {
      this.historic.days.map((period, indexPeriod) => {
        period.transactions.map((transaction, indexRelease) => {
          const index = `#${indexPeriod}${indexRelease}`;
          if (newValue) {
            this.$bus.$emit('spinner-run');
            this.getDetails(
              period,
              indexPeriod,
              indexRelease,
              transaction.type
            );
            /* eslint-disable no-undef */
            $(index).addClass('collapse show');
            this.$bus.$emit('spinner-stop');
          } else {
            /* eslint-disable no-undef */
            $(index).addClass('collapse').removeClass('show');
          }
        });
      });
    },
  },

  methods: {
    async getDetails(period, indexPeriod, indexRelease, type) {
      this.$bus.$emit('spinner-run');
      var index = `${indexPeriod}${indexRelease}`;
      const collapsed = this.isCollapse(index);
      const length = this.lengthDefault;
      let indice = 0;
      let item = $(`#${index}`).find('.btn-load')[0];

      if (collapsed === 'false' && !item) {
        let newData;
        const date = moment(period.day).format('YYYY-MM-DD');
        if (this.view === 'upcoming') {
          newData = this.getDetailsRelease({ date, type, indice, length });
        } else if (this.view === 'extract') {
          newData = this.getDetailsExtract({ date, type, indice, length });
        }

        newData.then((result) => {
          let that = this;
          this.$set(that.details, index, result);
        });
      } else {
        index = index === '00' ? '0' : parseInt(index);
        var result = null;
        const date = moment(period.day).format('YYYY-MM-DD');
        if (this.view === 'upcoming') {
          if (!this.isNullOrUndefined(this.details[index])) {
            indice = this.details[index].transactions.length;
            result = await this.getDetailsRelease({
              date,
              type,
              indice,
              length,
            });
            this.details[index].transactions = this.details[
              index
            ].transactions.concat(result.transactions);
          }
        } else if (this.view === 'extract') {
          if (!this.isNullOrUndefined(this.details[index])) {
            indice = this.details[index].transactions.length;
            result = await this.getDetailsExtract({
              date,
              type,
              indice,
              length,
            });
            this.details[index].transactions = this.details[
              index
            ].transactions.concat(result.transactions);
          }
        }
      }
      this.$bus.$emit('spinner-stop');
    },

    isNullOrUndefined(object) {
      if (object === null || object === undefined) return true;

      return false;
    },

    isCollapse(index) {
      /* eslint-disable no-undef */
      $('#icon-' + index).toggleClass('icon-arrow-up icon-arrow-down');
      return $(`div[href='#${index}']`).attr('aria-expanded');
    },

    async getDetailsRelease(day) {
      const api = new PaymentsApi();
      var response = await api.getDetailsReleases(day);
      return response;
    },

    async getDetailsExtract(filter) {
      const api = new PaymentAccountsApi();
      var response = await api.getDetailsExtract(filter);
      return response;
    },

    setTransactionOpened(transaction) {
      this.transactionOpened = transaction;
      /* eslint-disable no-undef */
      $(`#${this.modalReleaseDetails}`).modal();
    },

    getIndex(a, b) {
      const index = +`${a}${b}`;
      if (this.details[index]) {
        return this.details[index].transactions;
      }

      return [];
    },

    getLastItem(a, b) {
      const index = +`${a}${b}`;

      return this.details[index].transactions[
        this.details[index].transactions.length - 1
      ];
    },

    showMore(a, b) {
      const index = +`${a}${b}`;
      return (
        this.details[index].transactions.length < this.details[index].count
      );
    },

    description(transaction, type) {
      var originalDate = `${
        new Date(transaction.originalDate).getTime() < new Date().getTime()
          ? `- Data original ${moment(transaction.originalDate).format(
              'DD/MM/YYYY'
            )}`
          : ''
      }`;
      switch (transaction.type) {
        case 'canceled':
          return `#${transaction.id} - NSU ${transaction.card.nsu} - ${
            transaction.card.cardBrand
          } - Final ${transaction.card.cardNumber} ${
            transaction.card.installments > 1
              ? `- ${transaction.card.installmentNumber}/${transaction.card.installments}`
              : ''
          } ${originalDate}`;

        case 'contested':
          return `#${transaction.id} - NSU ${transaction.card.nsu} - ${
            transaction.card.cardBrand
          } - Final ${transaction.card.cardNumber} ${
            transaction.card.installments > 1
              ? `- ${transaction.card.installmentNumber}/${transaction.card.installments}`
              : ''
          } ${originalDate}`;

        case 'card':
        case 'cardFee':
        case 'cardCommission':
        case 'revertedChargeback':
          return `#${transaction.id} - NSU ${transaction.card.nsu} - ${
            transaction.card.cardBrand
          } - Final ${transaction.card.cardNumber} ${
            transaction.card.installments > 1
              ? `- ${transaction.card.installmentNumber}/${transaction.card.installments}`
              : ''
          } ${originalDate}`;

        case 'bankSlip':
        case 'bankSlipFee':
        case 'bankSlipCommission':
          return `#${transaction.id} - Número ${transaction.bankSlip.bankSlipNumber} - ${transaction.bankSlip.payerName} ${originalDate}`;

        case 'otherTransfer': {
          if (transaction.transfer) {
            return `${
              transaction.transfer.id !== null
                ? ` #${transaction.transfer.id} - `
                : ''
            }${transaction.description} ${originalDate}`;
          } else {
            return `${transaction.description} ${originalDate}`;
          }
        }

        case 'bankTransferFee':
          return 'Taxa de transferência bancária';
        case 'transfer':
          return `${
            transaction.transfer.id !== null
              ? ` #${transaction.transfer.id}`
              : ''
          } Transferência manual para conta bancária ${
            transaction.transfer.accountNumber
          }`;

        case 'automaticTransfer':
          return `${
            transaction.transfer.id !== null
              ? ` #${transaction.transfer.id}`
              : ''
          } Transferência automática para conta bancária ${
            transaction.transfer.accountNumber
          }`;

        case 'internalTransfer': {
          var person = '';
          if (
            transaction.transferInformation !== null &&
            transaction.transferInformation.source !== null
          ) {
            person =
              transaction.transferInformation.source.isSplitter === true
                ? ' do Splitter '
                : ' de ';
            return `${
              transaction.transfer.id !== null
                ? ` #${transaction.transfer.id}`
                : ''
            } Transferência recebida ${person} ${
              transaction.transferInformation.source.fullName
            }`;
          } else if (
            transaction.transferInformation !== null &&
            transaction.transferInformation.destination !== null
          ) {
            person =
              transaction.transferInformation.destination.isSplitter === true
                ? 'o Splitter '
                : ' ';
            return `${
              transaction.transfer.id !== null
                ? ` #${transaction.transfer.id}`
                : ''
            } Transferência efetuada para ${person} ${
              transaction.transferInformation.destination.fullName
            }`;
          } else {
            return `${
              transaction.transfer.id !== null
                ? ` #${transaction.transfer.id}`
                : ''
            } Transferência para splitter ${transaction.transfer.holderName}`;
          }
        }

        case 'returnTransfer':
          return `#${transaction.id} - Identificador ${transaction.transfer.id} - Devolução de Transferência`;

        case 'billing': {
          if (transaction.card != null) {
            return `#${transaction.description} - ${transaction.card.nsu} - ${
              transaction.card.cardBrand
            } - Final ${transaction.card.cardNumber} ${
              transaction.card.installments > 1
                ? `- ${transaction.card.installmentNumber}/${transaction.card.installments}`
                : ''
            } ${originalDate}`;
          }
          return `#${transaction.description} - ${transaction.bankSlip.bankSlipNumber} -${transaction.bankSlip.payerName} ${originalDate}`;
        }

        case 'billPayment': {
          return `#${transaction.billPayment.id} ${
            transaction.billPayment.description != null
              ? `- ${transaction.billPayment.description}`
              : ''
          } - ${transaction.billPayment.recipientName}`;
        }

        case 'billPaymentFee': {
          return 'Taxa de pagamento de conta';
        }

        case 'billPaymentReturn': {
          return `#${transaction.billPayment.id} - Estorno do pagamento - ${transaction.billPayment.recipientName}`;
        }

        case 'commission': {
          if (transaction.card != null) {
            return `#${transaction.id} - NSU ${transaction.card.nsu} - ${
              transaction.card.cardBrand
            } - Final ${transaction.card.cardNumber} ${
              transaction.card.installments > 1
                ? `- ${transaction.card.installmentNumber}/${transaction.card.installments}`
                : ''
            } - Comissão`;
          } else {
            return `#${transaction.id} - Número ${transaction.bankSlip.bankSlipNumber} - ${transaction.bankSlip.payerName} - Comissão`;
          }
        }

        case 'pixOrderDebit': {
          var returnStr = `#${transaction.id}`;

          if (transaction.description)
            returnStr += ` - ${transaction.description}`;

          if (transaction.transferInformation) {
            if (transaction.transferInformation.destination) {
              if (transaction.transferInformation.destination.fullName)
                returnStr += ` - ${transaction.transferInformation.destination.fullName}`;
              if (transaction.transferInformation.destination.taxDocument)
                returnStr += ` (${transaction.transferInformation.destination.taxDocument})`;
            }
          }

          return returnStr;
        }

        case 'pixOrderDebitReturn':
          return `#${transaction.id} ${
            transaction.description ? ` - ${transaction.description}` : ''
          }`;

        case 'pixOrderCredit': {
          var returnStr = `#${transaction.id}`;

          if (transaction.description)
            returnStr += `- ${transaction.description}`;

          if (transaction.transferInformation) {
            if (transaction.transferInformation.destination) {
              if (transaction.transferInformation.destination.fullName)
                returnStr += `- ${transaction.transferInformation.destination.fullName}`;
              if (transaction.transferInformation.destination.taxDocument)
                returnStr += ` (${transaction.transferInformation.destination.taxDocument})`;
            }
          }

          return returnStr;
        }

        case 'pixFee':
          return 'Taxa transferência Pix';

        case 'pixFeeReturn':
          return 'Devolução taxa transferência Pix';

        case 'returnBankTransferFee': {
          return 'Devolução de taxa de transferência';
        }

        case 'liquidatingBankTransfer': {
          var returnStr = `#${transaction.id}`;

          if (transaction.description)
            returnStr += ` - ${transaction.description}`;

          if (transaction.transferInformation) {
            if (transaction.transferInformation.destination) {
              if (transaction.transferInformation.destination.fullName)
                returnStr += ` - ${transaction.transferInformation.destination.fullName}`;
              if (transaction.transferInformation.destination.taxDocument)
                returnStr += ` (${transaction.transferInformation.destination.taxDocument})`;
            }
          }

          return returnStr;
        }
        default:
          break;
      }
    },

    openOriginalPixDebitTransaction(transaction) {
      const originalTransaction = { ...transaction };
      originalTransaction.amount = -transaction.amount;
      originalTransaction.type = 'pixOrderDebit';
      this.setTransactionOpened(originalTransaction);
    },
  },
};
</script>

<style>
.btn-load {
  width: 300px;
  margin: 0 auto;
  border-radius: 100px;
}
.text-color-green {
  color: #00d09d;
}
</style>
