<template>
  <div>
    <ModalBase :hasFooter="false" :title="title" :id="id" size="modal-md">
      <div slot="content" v-if="control === 0">
        <div class="content-preferences-account">
          <div class="row mb-3">
            <div class="form-group col-8 mb-4">
              <label for="inputName"
                >Digite o nome identificador do Splitter</label
              >
              <input
                type="text"
                class="form-control mb-1"
                maxlength="18"
                placeholder="Nome do Splitter"
                v-model="splitterName"
              />
            </div>
            <div class="form-group col-4 mt-4">
              <WhiteLabel>
                <button
                  class="btn btn-pill btn-orange"
                  @click="handleSearchSplitter"
                >
                  Pesquisar
                </button>
              </WhiteLabel>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="card-list">
                <li
                  class="card"
                  v-for="splitter in splittersFound"
                  :key="splitter.id"
                >
                  <div class="col-12 text-center">
                    <p class="mt-3">
                      <strong>{{ splitter.name }}</strong>
                    </p>
                    <p>
                      ({{ splitter.bankAccount.taxDocument }})
                      <br />
                      <strong>Dias para repasse:</strong>
                      {{ splitter.transferDays }}
                    </p>
                    <WhiteLabel>
                      <button
                        class="btn btn-pill btn-outline-orange"
                        @click="handleSelectSplitter(splitter.id)"
                      >
                        Selecionar
                      </button>
                    </WhiteLabel>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div slot="content" v-if="control === 1">
        <div class="content-preferences-account">
          <span
            >Escolha um ou mais dias em que o seu saldo deve ser transferido
            automaticamente</span
          >

          <WhiteLabel class="checkbox">
            <div class="pr-2">
              <input
                type="checkbox"
                id="monday"
                v-model="transferDays.monday"
              />
              <label for="monday" class="btn text-uppercase">SEG</label>
            </div>

            <div class="pr-2">
              <input
                type="checkbox"
                id="tuesday"
                v-model="transferDays.tuesday"
              />
              <label
                for="tuesday"
                class="btn btn-check pl-3 pr-3 text-uppercase"
                >TER</label
              >
            </div>

            <div class="pr-2">
              <input
                type="checkbox"
                id="wednesday"
                v-model="transferDays.wednesday"
              />
              <label
                for="wednesday"
                class="btn btn-check pl-3 pr-3 text-uppercase"
                >QUA</label
              >
            </div>

            <div class="pr-2">
              <input
                type="checkbox"
                id="thursday"
                v-model="transferDays.thursday"
              />
              <label
                for="thursday"
                class="btn btn-check pl-3 pr-3 text-uppercase"
                >QUI</label
              >
            </div>

            <div class="pr-2">
              <input
                type="checkbox"
                id="friday"
                v-model="transferDays.friday"
              />
              <label for="friday" class="btn btn-check pl-3 pr-3 text-uppercase"
                >SEX</label
              >
            </div>
          </WhiteLabel>

          <p class="mt-4">Splitter selecionados</p>
          <div class="col-12 no-padding">
            <ul class="condensed-list">
              <li v-for="splitter in splittersToConfig" :key="splitter.id">
                <div class="row">
                  <div class="col-9 mt-2">{{ splitter.name }}</div>
                  <div class="col-3">
                    <WhiteLabel>
                      <button
                        class="btn btn-pill btn-outline-orange"
                        @click="handleRemoveSplitter(splitter.id)"
                      >
                        Remover
                      </button>
                    </WhiteLabel>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="row mb-5">
            <div class="col-8"></div>
            <div class="col-4">
              <WhiteLabel>
                <button
                  class="btn btn-link-orange btn-block"
                  @click="handleAddMoreSplitter"
                >
                  Adicionar +
                </button>
              </WhiteLabel>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <WhiteLabel>
                <button
                  class="btn btn-orange btn-pill btn-block"
                  @click="handleSaveConfigs"
                  :disable="!splittersToConfig.length"
                >
                  Salvar
                </button>
              </WhiteLabel>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import SplitterApi from '@/services/v2/SplitterApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalPreferencesBase',
  components: {
    ModalBase,
    WhiteLabel,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    settings: {
      required: true,
    },
  },
  data() {
    return {
      title: 'Preferências da Contas',
      control: 0,
      controls: {
        0: 'Preferências da Contas',
        1: 'COnfigurações da transferência',
      },
      automaticDays: null,
      transferDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      },
      downloadBalanceAutomatically: false,
      splitterName: '',
      splittersFound: [],
      splittersToConfig: [],
      splitterSearchIndex: 0,
    };
  },
  mounted() {
    this.$bus.$on('closePreferences', (result) => {
      this.control = 0;
      this.transferDays = [];
      this.downloadBalanceAutomatically = false;
      this.automaticDays = null;
      this.transferDays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      };
      this.splittersFound = [];
      this.splittersToConfig = [];
      this.splitterSearchIndex = 0;
      this.splitterName = '';
    });
  },
  methods: {
    async handleSearchSplitter() {
      this.$bus.$emit('spinner-run');
      const api = new SplitterApi();
      const splittersFound = await api.search(
        this.splitterName,
        this.splitterSearchIndex,
        30
      );

      this.splittersFound = splittersFound.splitters;
      this.$bus.$emit('spinner-stop');
    },
    async handleSelectSplitter(id) {
      const api = new PaymentAccountsApi();
      const splitterSettings = await api.getSplitterSettings(id);
      const currentSplitter = this.splittersFound.find(
        (splitter) => splitter.id === id
      );

      if (splitterSettings.splitterId) {
        this.transferDays = {
          monday: splitterSettings.isMonday,
          tuesday: splitterSettings.isTuesday,
          wednesday: splitterSettings.isWednesday,
          thursday: splitterSettings.isThursday,
          friday: splitterSettings.isFriday,
        };
      }

      this.splittersToConfig.push(currentSplitter);
      this.control = 1;
    },
    async handleRemoveSplitter(id) {
      for (let i = 0; i < this.splittersToConfig.length; i++) {
        if (this.splittersToConfig[i].id === id) {
          this.splittersToConfig.splice(i, 1);
        }
      }
    },
    handleSaveConfigs() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      let payload = {
        splitters: [],
      };

      for (let i = 0; i < this.splittersToConfig.length; i++) {
        payload.splitters.push({
          splitterId: this.splittersToConfig[i].id,
          monday: this.transferDays.monday,
          tuesday: this.transferDays.tuesday,
          wednesday: this.transferDays.wednesday,
          thursday: this.transferDays.thursday,
          friday: this.transferDays.friday,
        });
      }

      api
        .createSplitterTransferDays(payload)
        .then((response) => {
          this.$bus.$emit('spinner-stop');
          this.$bus.$emit('closePreferences');
        })
        .catch((error) => {
          this.$bus.$emit('spinner-stop');
          this.$bus.$emit(
            'alert-error',
            'Não foi possível salvar as configurações do Splitter.'
          );
        });
    },
    handleAddMoreSplitter() {
      this.control = 0;
    },
  },
};
</script>

<style scoped>
.card-list {
  list-style: none;
  padding: 0;
}

.condensed-list {
  list-style: none;
  padding: 0;
}
.condensed-list li {
  padding: 10px 25px;
  color: #7d8287;
}
.condensed-list li:nth-of-type(odd) {
  background-color: #f0f0f0;
}
.no-padding {
  padding: 0;
}
.btn-link-orange {
  color: #ff7606;
}
</style>
