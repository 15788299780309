<template>
  <div>
    <ModalBase :hasFooter="false" :title="title" :id="id" size="modal-md">
      <div slot="content" v-if="control === 0">
        <AppModalPreferencesAccount
          id="modal-preferences-account"
          :settings="settings"
          :bankAccount="bankAccount"
          @savePreferencesFinished="savePreferencesFinished"
          @openConfirm="showConfirm"
          @setSettingsAccount="setSettingsAccount"
        />
      </div>
      <div slot="content" v-if="control === 1">
        <AppModalPreferencesAccountConfirm
          :days="transferDays"
          @openImportantInfo="changeControl"
        />
      </div>
      <div slot="content" v-if="control === 1.1">
        <AppModalImportantInfo @activeTransference="setSettingsAccount" />
      </div>
      <div slot="content" v-if="control === 1.2">
        <AppModalSettingsSave id="modal-settings-save" :days="automaticDays" />
      </div>
      <div slot="content" v-if="control === 2">
        <AppModalAutomaticTransfersOff
          @activeTransference="setSettingsAccount"
        />
      </div>
    </ModalBase>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import AppModalPreferencesAccount from '@/components/account/preferences/ModalPreferencesAccount';
import AppModalAutomaticTransfersOff from '@/components/account/preferences/ModalAutomaticTransfersOff';
import AppModalSettingsSave from '@/components/account/preferences/ModalSettingsSave';
import AppModalPreferencesAccountConfirm from '@/components/account/preferences/ModalPreferencesAccountConfirm';
import AppModalImportantInfo from '@/components/account/preferences/ModalImportantInfo';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import { mapGetters } from 'vuex';

export default {
  name: 'AppModalPreferencesBase',
  components: {
    ModalBase,
    AppModalPreferencesAccount,
    AppModalAutomaticTransfersOff,
    AppModalSettingsSave,
    AppModalPreferencesAccountConfirm,
    AppModalImportantInfo,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    settings: {
      required: true,
    },
  },
  data() {
    return {
      title: 'Preferências da Conta',
      control: 0,
      controls: {
        0: 'Preferências da Conta',
        1: 'Preferências da Conta',
      },
      automaticDays: null,
      days: [
        { value: 'monday', label: 'Seg' },
        { value: 'tuesday', label: 'Ter' },
        { value: 'wednesday', label: 'Qua' },
        { value: 'thursday', label: 'Qui' },
        { value: 'friday', label: 'Sex' },
      ],
      transferDays: [],
      transferDaysCopy: [],
      downloadBalanceAutomatically: false,
    };
  },
  mounted() {
    this.$bus.$on('closePreferences', (result) => {
      this.changeControl(0);
      this.transferDays = [];
      this.downloadBalanceAutomatically = false;
      this.automaticDays = null;
    });
  },
  methods: {
    savePreferencesFinished() {
      if (this.downloadBalanceAutomatically) {
        this.automaticDays = this.getDaysFormatting();
        this.changeControl(1.2);
      }
    },
    changeControl(number) {
      this.$nextTick(function () {
        if (number >= 1 && this.transferDays.length === 0) {
          this.control = 2;
          this.title = this.controls[2];
          this.downloadBalanceAutomatically = false;
          return;
        }
        this.control = number;
        this.title = this.controls[number];
      });
    },
    async showConfirm(days, control) {
      this.changeControl(control);
      this.transferDays = days;
    },
    async setSettingsAccount(downloadBalanceAutomatically) {
      this.downloadBalanceAutomatically = downloadBalanceAutomatically;
      if (downloadBalanceAutomatically === false) {
        this.transferDays = [];
      }
      let params = {};
      this.days.map((day) => {
        const achou = this.transferDays.filter(
          (transferDay) => day.value === transferDay
        );
        if (achou.length === 1) {
          params[day.value] = true;
        } else {
          params[day.value] = false;
        }
      });
      const api = new PaymentAccountsApi();
      const result = await api.setSettings(params);

      if (result) {
        this.savePreferencesFinished();
      }
    },
    getDaysFormatting() {
      let daysFormatting = '';
      let daysFormattingArray = ['', '', '', '', ''];
      if (this.transferDays) {
        this.transferDays.map((day) => {
          if (day === 'monday') daysFormattingArray[0] = 'Segunda-feira';
          if (day === 'tuesday') daysFormattingArray[1] = 'Terça-feira';
          if (day === 'wednesday') daysFormattingArray[2] = 'Quarta-feira';
          if (day === 'thursday') daysFormattingArray[3] = 'Quinta-feira';
          if (day === 'friday') daysFormattingArray[4] = 'Sexta-feira';
        });

        let daysFormatted = [];
        for (let i = 0; i < daysFormattingArray.length; i++) {
          if (daysFormattingArray[i] !== '') {
            daysFormatted.push(daysFormattingArray[i]);
          }
        }

        daysFormatting = daysFormatted.join(', ');

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        }
      }

      return daysFormatting;
    },
  },
  watch: {
    settings: function () {
      if (
        this.settings &&
        !this.settings.isMonday &&
        !this.settings.isTuesday &&
        !this.settings.isWednesday &&
        !this.settings.isThursday &&
        !this.settings.isFriday
      ) {
        this.downloadBalanceAutomatically = false;
      } else {
        this.downloadBalanceAutomatically = true;
      }

      if (this.settings.isMonday) this.transferDays.push('monday');
      if (this.settings.isTuesday) this.transferDays.push('tuesday');
      if (this.settings.isWednesday) this.transferDays.push('wednesday');
      if (this.settings.isThursday) this.transferDays.push('thursday');
      if (this.settings.isFriday) this.transferDays.push('friday');
    },
    transferDays: function () {
      if (this.transferDays.length === 0) {
        this.downloadBalanceAutomatically = false;
      }
    },
    downloadBalanceAutomatically: function (newValue) {
      if (newValue === false) {
        this.transferDays = [];
      } else {
        this.transferDays =
          this.transferDaysCopy.length > 0
            ? this.transferDaysCopy
            : this.transferDays;
      }
    },
  },
  computed: {
    ...mapGetters({
      bankAccount: 'account/bankAccount',
    }),
  },
};
</script>

<style>
</style>
