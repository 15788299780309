<template>
  <div class="leftContent" v-if="details !== null">
    <div class="top">
      <div class="circle">
        <em class="icon transfer gray-2"></em>
      </div>
      <div class="value">
        <span>{{
          details.amount
            | currency("R$ ", 2, {
              decimalSeparator: ",",
              thousandsSeparator: ".",
            })
        }}</span>
      </div>
      <span class="description">Transferência - {{ details.id }}</span>
      <span class="prevision">{{ dateTransfer }}</span>
      <div :class="'status-' + details.status">{{ getStatusFormated }}</div>
    </div>
    <span :class="'type-transference-' + details.status">{{
      getStatusMessage(details)
    }}</span>
  </div>
</template>

<script>
const moment = require('moment');
require('moment/locale/pt-br');
export default {
  name: 'AppHeaderTransferToAccount',
  props: {
    details: {
      required: true,
      type: Object,
    },
    dateTransfer: {
      required: true,
      type: String,
    },
    getStatusFormated: {
      required: true,
      type: String,
    },
  },
  methods: {
    getStatusMessage: (details) => {
      if (details === undefined || details === null) return '';

      if (details.status === undefined || details.status === null) return '';

      switch (details.status.toLowerCase()) {
      case 'pending':
        return 'Sua transferência foi solicitada. Se tudo der certo o dinheiro cai na conta em breve.';
      case 'approved':
        return `Dinheiro na conta! ${
          details.type === 'internalTransfer'
            ? 'O dinheiro foi transferido e já está disponível'
            : `${`Recebemos a confirmação do banco em ${moment(
              details.approvedAt
            ).format('LLL')}`}`
        }.`;
      case 'provisioned':
        return 'Dinheiro a caminho do banco, fique atento à sua conta bancária.';
      case 'canceled':
        return 'A transferência foi cancelada e o dinheiro devolvido à sua conta. Consulte seu extrato.';
      case 'reproved':
        return 'Aconteceu algum problema com a conta informada. Verifique seus dados bancários.';
      default:
        break;
      }
    },
  },
};
</script>

<style>
</style>
