import CustomApiError from './CustomError';

export default class HiddenFeatureError extends CustomApiError {
  toDictionary() {
    return {
      FEATURE_NOT_FOUND:
        'Não foi possível ocultar esta funcionalidade. Por favor, entre em contato com o nosso suporte.',
    };
  }
}
