<template>
  <div>
    <div class="header-box pt-4 pb-4 mb-4">
      <div class="row mx-0 justify-content-center">
        <div class="align-self-center text-center mb-0">
          <img
            :src="logo.display"
            class="top-logo mb-2"
            v-if="logo.display !== '' && !cropping"
          />
          <div
            class="top-logo mb-2 mr-auto ml-auto"
            v-if="logo.display === '' || cropping"
          >
            <h1 class="mt-3">
              <i
                class
                :class="
                  preset.holder.company === null
                    ? 'icon-user'
                    : 'icon-briefcase'
                "
              ></i>
            </h1>
          </div>
          <h3 class="font-weight-light mb-2">
            {{ preset.account.commercialName }}
          </h3>
          <h6
            class="font-weight-light mb-0"
            v-if="preset.holder.company !== null"
          >
            {{ preset.holder.company.fullName }}
          </h6>
          <h6
            class="font-weight-light mb-0"
            v-if="preset.holder.company === null"
          >
            {{ preset.holder.fullName }}
          </h6>
        </div>
      </div>
    </div>
    <div class="container">
      <form action="#">
        <div class="d-flex mb-3">
          <div class="ml-auto">
            <WhiteLabel>
              <a href="#" @click="$router.go(-1)" class="pull-right mt-2 mr-4">
                <i class="icon-arrow-left-circle"></i> Voltar
              </a>
            </WhiteLabel>
          </div>
          <div>
            <WhiteLabel>
              <button
                class="btn btn-orange"
                id="action"
                @click.prevent="isDisabled = !isDisabled"
                v-if="isDisabled && this.whoAmI.accessedByHolder"
              >
                <i class="icon-pencil"></i>
                Editar campos
              </button>
            </WhiteLabel>
          </div>
          <div>
            <WhiteLabel>
              <button
                class="btn btn-orange"
                id="action"
                @click.prevent="save"
                v-if="!isDisabled"
                :disabled="$v.$invalid"
              >
                Salvar alterações
              </button>
            </WhiteLabel>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-w-full">
            <div class="card">
              <div class="card-content">
                <h3 class="font-weight-light ml-3">Personalização</h3>
                <hr />
                <div class="form-row mb-4">
                  <div class="col-6">
                    <div class="col-12 col-w-full">
                      <div class="row">
                        <div class="col-6">
                          <WhiteLabel>
                            <h4 class="font-weight-light text-orange">
                              Insira o logo do seu negócio
                            </h4>
                          </WhiteLabel>
                        </div>
                        <div class="col-6">
                          <WhiteLabel>
                            <h4 class="font-weight-light text-orange">
                              Insira a versão negativa do logo
                            </h4>
                          </WhiteLabel>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div
                            class="no-logo rounded mb-4 row"
                            v-if="logo.display === '' && !cropping"
                          >
                            <div class="d-flex" v-if="isDisabled">
                              <h3 class="m-auto">
                                <i class="icon-picture text-muted"></i>
                              </h3>
                            </div>
                          </div>

                          <img
                            :src="logo.display"
                            class="rounded logo mb-2"
                            v-if="logo.display !== '' && !cropping"
                          />
                          <div class="logo-cropper" v-if="cropping">
                            <vue-cropper
                              ref="cropper"
                              :guides="true"
                              :aspectRatio="1"
                              :view-mode="0"
                              drag-mode="crop"
                              :background="true"
                              :src="logo.display"
                              alt="Source Image"
                              :img-style="{ width: '200px', height: '200px' }"
                            ></vue-cropper>
                          </div>
                        </div>
                        <div class="col-6">
                          <div
                            class="no-logo rounded mb-4 row"
                            v-if="
                              logo.negativeDisplay === '' && !negativeCropping
                            "
                          >
                            <div class="d-flex" v-if="isDisabled">
                              <h3 class="m-auto">
                                <i class="icon-picture text-muted"></i>
                              </h3>
                            </div>
                          </div>

                          <img
                            :src="logo.negativeDisplay"
                            class="rounded logo mb-2"
                            v-if="
                              logo.negativeDisplay !== '' && !negativeCropping
                            "
                          />
                          <div class="logo-cropper" v-if="negativeCropping">
                            <vue-cropper
                              ref="negativeCropper"
                              :guides="true"
                              :aspectRatio="1"
                              :view-mode="0"
                              drag-mode="crop"
                              :background="true"
                              :src="logo.negativeDisplay"
                              alt="Source Image"
                              :img-style="{ width: '200px', height: '200px' }"
                            ></vue-cropper>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="text-center col-6">
                          <big>
                            <i
                              class="icon-action-undo mr-2"
                              @click.prevent="undoLogoChange()"
                              v-show="cropping || logo.changedLogo"
                            ></i>
                          </big>
                          <a
                            href="#"
                            class="mr-2 text-orange"
                            @click.prevent="cropImage"
                            v-show="cropping"
                          >
                            <big>
                              <i class="icon-crop text-orange"></i>
                            </big>
                            Recortar
                          </a>
                          <label v-if="!cropping">
                            <big>
                              <i
                                class="icon-cloud-upload"
                                :class="!isDisabled ? 'text-orange' : ''"
                              ></i>
                            </big>
                            <input
                              type="file"
                              id="file"
                              accept="image/*"
                              ref="front"
                              @change="changeLogo()"
                              :disabled="isDisabled"
                            />
                          </label>
                        </div>

                        <div class="text-center col-6">
                          <big>
                            <i
                              class="icon-action-undo mr-2"
                              @click.prevent="undoNegativeLogoChange()"
                              v-show="
                                negativeCropping || logo.changedNegativeLogo
                              "
                            ></i>
                          </big>
                          <a
                            href="#"
                            class="mr-2 text-orange"
                            @click.prevent="cropNegativeImage"
                            v-show="negativeCropping"
                          >
                            <big>
                              <i class="icon-crop text-orange"></i>
                            </big>
                            Recortar
                          </a>
                          <label v-if="!negativeCropping">
                            <big>
                              <i
                                class="icon-cloud-upload"
                                :class="!isDisabled ? 'text-orange' : ''"
                              ></i>
                            </big>
                            <input
                              type="file"
                              id="negativeFile"
                              accept="image/*"
                              ref="negativeFront"
                              @change="changeNegativeLogo()"
                              :disabled="isDisabled"
                            />
                          </label>
                        </div>
                      </div>
                      <small class="text-muted" v-if="!negativeImageUploadError"
                        >Envie uma imagem que contenha a logo de sua empresa
                        preferencialmente com fundo branco ou transparente para
                        melhor aplicação em documentos</small
                      >
                      <p
                        class="text-danger text-center"
                        v-if="imageUploadError"
                      >
                        A imagem que você enviou é muito pequena.
                        <br />Tente enviar outra de pelo menos 200px de largura
                        e altura.
                      </p>
                    </div>
                  </div>
                  <div class="col-6"></div>
                </div>

                <hr :class="logo.display !== '' && !cropping ? 'mb-3' : ''" />

                <div class="form-row">
                  <div class="col-12 col-w-full">
                    <WhiteLabel>
                      <h4 class="font-weight-light text-orange">
                        Defina um gradiente para os cards e botões
                      </h4>
                    </WhiteLabel>
                  </div>
                  <div class="col-12 col-w-full mb-4">
                    <ColorButton
                      label="Cor 1"
                      ref="primaryColorButton"
                      :color="layout.primaryColor"
                      :onChange="(color) => changeColor('primaryColor', color)"
                      :disabled="isDisabled"
                    />
                    <ColorButton
                      label="Cor 2"
                      ref="secondaryColorButton"
                      :color="layout.secondaryColor"
                      :onChange="
                        (color) => changeColor('secondaryColor', color)
                      "
                      :disabled="isDisabled"
                    />
                  </div>
                  <div class="col-6 col-w-full mb-4">
                    <label>Cards</label>
                    <WhiteLabel :theme="layout">
                      <div class="details-balance example"></div>
                    </WhiteLabel>
                  </div>
                  <div class="col-3 col-w-full mb-4">
                    <label>Botão com gradiente</label>
                    <WhiteLabel :theme="layout">
                      <a
                        href="#"
                        class="
                          btn btn-block btn-orange btn-pill
                          mb-3
                          text-white
                        "
                        >Botão de exemplo</a
                      >
                    </WhiteLabel>
                  </div>
                  <div class="col-12 col-w-full">
                    <hr class="mb-3" />
                  </div>
                  <div class="col-12 col-w-full">
                    <WhiteLabel>
                      <h4 class="font-weight-light text-orange">
                        Defina uma cor para os títulos e os links
                      </h4>
                    </WhiteLabel>
                  </div>
                  <div class="col-12 col-w-full mb-4">
                    <ColorButton
                      label="Cor"
                      ref="tertiaryColorButton"
                      :color="layout.tertiaryColor"
                      :onChange="(color) => changeColor('tertiaryColor', color)"
                      :disabled="isDisabled"
                    />
                  </div>

                  <div class="col-3 col-w-full mb-4">
                    <label>Título</label>
                    <WhiteLabel :theme="layout">
                      <h4 class="font-weight-light text-orange">
                        Título de exemplo
                      </h4>
                    </WhiteLabel>
                  </div>
                  <div class="col-3 col-w-full mb-4">
                    <label>Links</label>
                    <div class="row">
                      <div class="col-12">
                        <WhiteLabel :theme="layout">
                          <a href="#" class>Link de exemplo</a>
                        </WhiteLabel>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 col-w-full mb-4">
                    <label>Botão com bordas</label>
                    <div class="row">
                      <div class="col-12">
                        <WhiteLabel :theme="layout">
                          <a
                            href="#"
                            class="btn btn-outline-orange btn-small btn-pill"
                            >Botão de exemplo</a
                          >
                        </WhiteLabel>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-row justify-content-center"
                  v-if="logo.display !== '' && !cropping"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="ml-auto">
            <WhiteLabel>
              <a href="#" @click="$router.go(-1)" class="pull-right mt-2 mr-4">
                <i class="icon-arrow-left-circle"></i> Voltar
              </a>
            </WhiteLabel>
          </div>
          <div>
            <WhiteLabel>
              <button
                class="btn btn-orange"
                id="action"
                @click.prevent="isDisabled = !isDisabled"
                v-if="isDisabled && this.whoAmI.accessedByHolder"
              >
                <i class="icon-pencil"></i>
                Editar campos
              </button>
            </WhiteLabel>
          </div>
          <div>
            <WhiteLabel>
              <button
                v-if="!isDisabled"
                class="btn btn-orange"
                id="action"
                @click.prevent="save"
                :disabled="$v.$invalid"
              >
                Salvar alterações
              </button>
            </WhiteLabel>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert2';
import HolderError from '@/errors/HolderError';
import AccountApi from '@/services/AccountApi';
import ZipCodeApi from '@/services/ZipCodeApi';
import LayoutApi from '@/services/v2/LayoutApi';
import { Presets, Address, PresetParams } from '@/models/Presets';
import { address } from '@/validations/address';
import { alphaNumeric } from '@/validations/alphaNumeric';
import { bankAccountNumber } from '@/validations/bankAccountNumber';
import { bankBranch } from '@/validations/bankBranch';
import { onlyLetters } from '@/validations/onlyLetters';
import { onlyNumbers } from '@/validations/onlyNumbers';
import { softDescriptor } from '@/validations/softDescriptor';
import { required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import Alert from '@/models/Alert';
import VueCropper from 'vue-cropperjs';
import ColorButton from '@/components/shared/ColorButton';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppCustomize',
  mounted() {
    this.$bus.$emit('spinner-run');
    this.getLayout();
    this.presets();
    this.$bus.$emit('spinner-stop');
  },
  created() {
    this.fetchLayout();
  },
  data() {
    return {
      isDisabled: true,
      preset: new Presets(),
      logo: {
        display: '',
        negativeDisplay: '',
        image: '',
        negativeImage: '',
        changedLogo: false,
        changedNegativeLogo: false,
      },
      cropping: false,
      negativeCropping: false,
      imageUploadError: false,
      negativeImageUploadError: false,
      cropped: null,
      negativeCropped: null,
      plan: '',
      oldPlanType: '',
      migrate: false,
      password: '',
      listBanks: [],
      bankAccountOwnerName: '',
      bankAccountOwnerTaxDocument: '',
      changedBankAccount: false,
      layout: {
        primaryColor: '#ff7606',
        secondaryColor: '#fa3645',
        tertiaryColor: '#ff7606',
        logo: null,
        negativeLogo: null,
      },
    };
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      updateLayout: 'layout/updateLayout',
      fetchLayout: 'layout/fetchLayout',
    }),
    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
      this.preset.address.cityCode = this.preset.address.city.code;
      if (this.differentBankAccountOwner) {
        this.bankAccountOwnerName = this.preset.bankAccount.holderFullName;
        this.bankAccountOwnerTaxDocument =
          this.preset.bankAccount.holderTaxDocument;
      } else {
        if (this.preset.bankAccount) {
          if (!this.preset.bankAccount.isJuristic) {
            this.preset.bankAccount.holderTaxDocument =
              this.preset.holder.taxDocument;
          } else {
            this.preset.bankAccount.holderTaxDocument =
              this.preset.holder.company !== null
                ? this.preset.holder.company.taxDocument
                : null;
          }
        }
      }
      if (this.preset.account.transferPlan.oldPlan) {
        if (this.preset.account.transferPlan.anticipated === false) {
          this.oldPlanType = '1';
        } else if (this.preset.account.transferPlan.anticipated === true) {
          this.oldPlanType = '2';
        }
      }
      if (!this.preset.account.transferPlan.oldPlan) {
        if (
          this.preset.account.transferPlan.anticipated === false &&
          this.preset.account.transferPlan.daysPinpad === 32
        ) {
          this.plan = '1';
        } else if (
          this.preset.account.transferPlan.anticipated === true &&
          this.preset.account.transferPlan.daysPinpad === 2
        ) {
          this.plan = '2';
        } else if (
          this.preset.account.transferPlan.anticipated === true &&
          this.preset.account.transferPlan.daysPinpad === 32
        ) {
          this.plan = '3';
        }
      }
    },
    changeColor(key, color) {
      this.layout[key] = color;
    },
    changeLogo() {
      var image = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        image.src = e.target.result;
        this.$refs.cropper.replace(e.target.result);
      };
      reader.readAsDataURL(this.$refs.front.files[0]);
      var imageFile = this.$refs.front.files[0];

      var _this = this;
      image.onload = function () {
        if (this.width < 200 || this.height < 200) {
          _this.logo.changedLogo = false;
          _this.cropping = false;
          _this.imageUploadError = true;
        } else {
          _this.imageUploadError = false;
          _this.cropping = true;
          _this.logo.changedLogo = true;
          _this.logo.display = image.src;
          _this.logo.image = imageFile;
        }
      };
      document.getElementById('file').value = '';
    },
    changeNegativeLogo() {
      var image = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        image.src = e.target.result;
        this.$refs.negativeCropper.replace(e.target.result);
      };

      reader.readAsDataURL(this.$refs.negativeFront.files[0]);
      var imageFile = this.$refs.negativeFront.files[0];

      var _this = this;
      image.onload = function () {
        if (this.width < 200 || this.height < 200) {
          _this.logo.changedNegativeLogo = false;
          _this.negativeCropping = false;
          _this.imageUploadError = true;
        } else {
          _this.imageUploadError = false;
          _this.negativeCropping = true;
          _this.logo.changedNegativeLogo = true;
          _this.logo.negativeDisplay = image.src;
          _this.logo.negativeImage = imageFile;
        }
      };
      document.getElementById('negativeFile').value = '';
    },
    previewLogo() {
      var previewData = new Alert();
      previewData.image = this.logo.display;
      previewData.name =
        this.preset.account.comercialName !== null
          ? this.preset.account.comercialName
          : this.preset.holder.company !== null
          ? this.preset.holder.company.fullName
          : this.preset.holder.fullName;
      previewData.address = `${this.preset.address.line1} ${this.preset.address.streetNumber}`;
      previewData.district = this.preset.address.district;
      previewData.city = this.preset.address.city.name;
      previewData.uf = this.preset.address.city.state;
      previewData.phone = this.preset.holder.phone;
      previewData.taxDocument =
        this.preset.holder.company !== null
          ? this.preset.holder.company.taxDocument
          : this.preset.holder.taxDocument;

      if (this.preset.holder.company === null) {
        this.$bus.$emit('alert-preview-logo-natural-person', previewData);
      } else {
        this.$bus.$emit('alert-preview-logo-juristic-person', previewData);
      }
    },
    async save() {
      if (this.$v.$invalid) {
        var alert = new Alert();
        alert.title = 'Ops';
        alert.message = 'Dados inválidos para alteração!';
        this.$bus.$emit('alert-warning', alert);
        return;
      }

      if (this.cropping || this.negativeCropping) {
        var alert = new Alert();
        alert.title = 'Ops';
        alert.message = 'Conclua a edição do seu logo antes de salvar';
        this.$bus.$emit('alert-warning', alert);
      } else {
        swal({
          title: 'Informe sua senha para atualizar seus dados',
          input: 'password',
          inputPlaceholder: 'Digite sua senha',
          inputAttributes: {
            minlength: 8,
            autocapitalize: 'off',
          },
          confirmButtonText: 'Salvar alterações',
          showLoaderOnConfirm: true,
          showCloseButton: true,
          preConfirm: (password) => {
            if (!password || password.length === 0) {
              swal.showValidationError('A senha é obrigatória!');
            } else {
              this.password = password;
            }
          },
          allowOutsideClick: () => !swal.isLoading(),
        }).then(async (res) => {
          if (
            res.dismiss &&
            (res.dismiss === 'close' ||
              res.dismiss === 'cancel' ||
              res.dismiss === 'overlay')
          ) {
            !swal.isLoading();
          } else {
            if (this.preset.bankAccount) {
              this.preset.bankAccount.variation === ''
                ? (this.preset.bankAccount.variation = null)
                : null;
            }

            this.$bus.$emit('spinner-run');
            const layoutApi = new LayoutApi();

            layoutApi
              .create({
                primaryColor: this.layout.primaryColor,
                secondaryColor: this.layout.secondaryColor,
                tertiaryColor: this.layout.tertiaryColor,
                logo: this.logo.changedLogo ? this.logo.image : null,
                negativeLogo: this.logo.changedNegativeLogo
                  ? this.logo.negativeImage
                  : null,
              })
              .then((response) => {
                this.logo.changedLogo = false;
                this.logo.changedNegativeLogo = false;

                window.localStorage.setItem(
                  'primaryColor',
                  this.layout.primaryColor
                );
                window.localStorage.setItem(
                  'secondaryColor',
                  this.layout.secondaryColor
                );
                window.localStorage.setItem(
                  'tertiaryColor',
                  this.layout.tertiaryColor
                );

                window.location = '/';
              })
              .catch(() => {
                var alert = new Alert();
                alert.title = 'Ops';
                const modelError = new HolderError(response.data);
                const error = modelError.getMessage();
                alert.error = error;
                alert.confirmButtonText = 'Sair';
                this.password = '';
                this.$bus.$emit('spinner-stop');
                this.$bus.$emit('alert-error', alert);
              });

            this.$bus.$emit('spinner-stop');
            this.isDisabled = true;
            this.$alert.info('Pronto', 'Alterações salvas com sucesso!');
          }
        });
      }
    },

    async getLayout() {
      const api = new LayoutApi();
      const layout = await api.fetch();

      if (layout.error) {
        return;
      }

      Object.keys(layout).map((attribute) => {
        if (layout[attribute] === null) {
          return;
        }

        this.layout[attribute] = layout[attribute];
      });

      if (layout.logo) {
        this.layout.logo = layout.logo;

        this.logo.image = new File([this.layout.logo], {
          type: 'image/png',
        });

        this.logo.display = this.layout.logo;
      }

      if (layout.negativeLogo) {
        this.layout.negativeLogo = layout.negativeLogo;

        this.logo.negativeImage = new File([this.layout.negativeLogo], {
          type: 'image/png',
        });

        this.logo.negativeDisplay = this.layout.negativeLogo;
      }
    },

    cropImage() {
      this.logo.display = this.$refs.cropper.getCroppedCanvas().toDataURL();
      const block = this.logo.display.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];
      this.logo.image = this.b64toBlob(realData, contentType);
      this.cropping = false;
    },

    cropNegativeImage() {
      this.logo.negativeDisplay = this.$refs.negativeCropper
        .getCroppedCanvas()
        .toDataURL();
      const block = this.logo.negativeDisplay.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];
      this.logo.negativeImage = this.b64toBlob(realData, contentType);
      this.negativeCropping = false;
    },

    result(output) {
      this.logo.display = output;
    },
    negativeResult(output) {
      this.logo.negativeDisplay = output;
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    undoLogoChange() {
      this.$alert.question(
        'Desfazer alterações',
        'Deseja realmente desfazer alterações em seu logotipo?'
      );
      this.$alert.onConfirm = async () => {
        const api = new LayoutApi();
        this.$bus.$emit('spinner-run');
        await api.fetch().then((response) => {
          if (response.status === 422) {
            this.logo.display = '';
            this.logo.image = '';
            this.logo.changedLogo = false;
          } else {
            this.logo.image = new File([response.logo], {
              type: 'image/png',
            });

            this.logo.display = response.logo;

            this.logo.changedLogo = false;
          }
        });
        if (this.cropping) {
          this.cropping = false;
        }
        this.$bus.$emit('spinner-stop');
      };
    },
    undoNegativeLogoChange() {
      this.$alert.question(
        'Desfazer alterações',
        'Deseja realmente desfazer alterações em seu logotipo?'
      );
      this.$alert.onConfirm = async () => {
        const api = new LayoutApi();
        this.$bus.$emit('spinner-run');
        await api.fetch().then((response) => {
          if (response.status === 422) {
            this.logo.negativeDisplay = '';
            this.logo.negativeImage = '';
            this.logo.changednNegativeLogo = false;
          } else {
            this.logo.negativeImage = new File([response.negativeLogo], {
              type: 'image/png',
            });

            this.logo.negativeDisplay = response.negativeLogo;

            this.logo.changedNegativeLogo = false;
          }
        });
        if (this.negativeCropping) {
          this.negativeCropping = false;
        }
        this.$bus.$emit('spinner-stop');
      };
    },
  },
  agencyTokens: {
    X: {
      pattern: /[0-9xX]/,
      transform: (v) => v.toLocaleUpperCase(),
    },
  },
  validations: {
    preset: {
      account: {
        softDescriptor: {
          required,
          softDescriptor,
        },
      },
      address: {
        zipCode: {
          required,
        },
        line1: {
          required,
          address,
        },
        streetNumber: {
          required,
          alphaNumeric,
        },
        line2: {
          alphaNumeric,
        },
        district: {
          required,
          onlyLetters,
        },
      },
      bankAccount: {
        bankBranchNumber: {
          bankBranch,
        },
        accountNumber: {
          bankAccountNumber,
        },
        variation: {
          onlyNumbers,
        },
      },
    },
  },
  directives: {
    mask,
  },
  watch: {
    preset: function (val) {
      if (val.bankAccount != null) {
        if (this.preset.bankAccount != null)
          this.preset.bankAccount.type = val.bankAccount.type.toLowerCase();
        else
          this.preset.bankAccount = {
            type: val.bankAccount.type.toLowerCase(),
          };
      }
    },
    'preset.account.softDescriptor': function () {
      this.preset.account.softDescriptor =
        this.preset.account.softDescriptor.toUpperCase();
      this.preset.account.softDescriptor =
        this.preset.account.softDescriptor.replace(/[^a-zA-Z]/g, '');
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    type: function () {
      return this.preset.bankAccount.type;
    },
    differentBankAccountOwner() {
      if (!this.preset) return '';
      if (this.preset.bankAccount != null)
        return (
          this.preset.bankAccount.holderFullName !== null &&
          this.preset.bankAccount.holderFullName !==
            this.preset.holder.fullName &&
          this.preset.holder.company !== null &&
          this.preset.bankAccount.holderFullName !==
            this.preset.holder.company.fullName
        );
    },
    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.isOriginPartner;
    },
  },
  components: {
    VueCropper,
    ColorButton,
    WhiteLabel,
  },
};
</script>
<style lang="scss">
#action {
  cursor: pointer;
}
.swal2-input {
  width: 50% !important;
}
.swal-wide {
  width: 750px !important;
}
</style>
