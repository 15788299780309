<template>
  <div>
    <ModalBase :hasFooter="false" :id="id" :size="'modal-lg'" :noHeader="true">
      <div slot="content" class="content-modal-transfer">
        <AppHeaderTransferToAccount
          v-if="getDetails !== null"
          :details="getDetails"
          :dateTransfer="dateTransfer"
          :getStatusFormated="getStatusFormated"
        />
        <div class="rightContent">
          <button
            type="button"
            class="close button-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="top" v-if="getDetails !== null">
            <div
              v-if="
                getDetails.type === 'transfer' ||
                getDetails.type === 'automaticTransfer'
              "
              class="content-account-transfer"
            >
              <AppDetailsTransference
                :value="getDetails.amount"
                :fee="getDetails.transferFee"
                :estimated="prediction"
                :days="days"
              />
              <AppDetailsBank
                :pBankData="getDetails.bankAccount"
                :holderName="getDetails.holderName"
                :holderTaxDocument="getDetails.holderTaxDocument"
                class="pl-2"
              />
            </div>
            <AppTransferOtherAccount
              v-else
              :value="getDetails.amount"
              :estimated="prediction"
              :holderTaxDocument="getDetails.holderTaxDocument"
              :holderName="getDetails.holderName"
              :transferFee="getDetails.transferFee"
              :debitedAmount="getDetails.debitedAmount"
              :typeTransfer="getDetails.type"
              :bankAccountDetails="getDetails.bankAccount"
            />
          </div>
          <div class="bottom" v-if="getDetails !== null">
            <AppTimeLine :data="getDetails.timeline" />
          </div>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import AppDetailsTransference from '@/components/account/transference/DetailsTransference';
import AppDetailsBank from '@/components/account/transference/DetailsBank';
import AppHeaderTransferToAccount from '@/components/account/transference/HeaderTransferToAccount';
import AppTimeLine from '@/components/account/transference/TimeLine';
import AppTransferOtherAccount from '@/components/account/transference/TransferOtherAccount';

import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import moment from 'moment';

export default {
  name: 'AppModalTransference',
  components: {
    ModalBase,
    AppDetailsTransference,
    AppDetailsBank,
    AppTimeLine,
    AppHeaderTransferToAccount,
    AppTransferOtherAccount,
  },
  data() {
    return {
      days: {
        0: 'DOM',
        1: 'SEG',
        2: 'TER',
        3: 'QUA',
        4: 'QUI',
        5: 'SEX',
        6: 'SAB',
      },
      status: {
        pending: 'Pendente',
        provisioned: 'Provisionada',
        transferred: 'Transferida',
        canceled: 'Cancelada',
        reproved: 'Devolvida',
        approved: 'Aprovada',
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    // await this.getDetailsTransferApi()
    this.$bus.$emit('spinner-stop');
  },
  methods: {
    async getDetailsTransferApi() {
      const api = new PaymentAccountsApi();
      let response = await api.getDetailsTransfer(this.id);

      if (response.status === 200) {
        this.details = response.data;
      } else {
        this.details = {
          bankAccount: {
            bankName: '',
          },
        };
      }
    },
  },
  computed: {
    getDetails() {
      return this.details;
    },
    dateTransfer() {
      let ret = '';
      if (this.details === null) return '';
      let date = null;

      if (this.details.status === 'transferred') {
        date = new Date(this.details.approvedAt);
      } else if (this.details.status === 'canceled') {
        date = new Date(this.details.canceledAt);
      } else if (this.details.status === 'reproved') {
        date = new Date(this.details.reprovedAt);
      } else if (
        this.details.status === 'processing' ||
        this.details.status === 'pending'
      ) {
        date = new Date(this.details.estimatedAt);
      }

      if (date === null) return '';

      let numberDay = date.getDay();
      if (this.days[numberDay] === undefined || numberDay === 5) {
        ret += this.days[1];
      } else {
        ret += this.days[numberDay + 1];
      }
      ret += ' - ' + moment(date).format('HH:MM:SS');
      return ret;
    },
    getStatusFormated() {
      if (this.details === null) return '';
      if (
        this.details.type === 'internalTransfer' &&
        this.details.status === 'approved'
      )
        return 'Confirmada';
      let ret = this.status[this.details.status];
      if (ret) return ret;
      else return '';
    },
    prediction() {
      if (this.details.estimatedAt === null) return '';
      let ret = '';
      let date = new Date(this.details.estimatedAt);
      let numberDay = date.getDay();
      if (this.days[numberDay] === undefined || numberDay === 5) {
        ret += this.days[1];
      } else {
        ret += this.days[numberDay + 1];
      }
      ret += ', ' + moment(date).format('DD/MM/YYYY');
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
