import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const url = withParams({ type: 'url' }, value => {
  var str = noAccents(value);
  if (!str) return true;
  var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  return regex.test(str);
});

export { url };
