<template>
  <div>
    <div class="header-box pt-4 pb-4 mb-4">
      <div class="row mx-0 justify-content-center">
        <div class="align-self-center text-center mb-0">
          <h3 class="font-weight-light mb-2">Plano: {{ planName }}</h3>
          <h6 class="font-weight-light mb-0">{{ whoAmI.holder.fullName }}</h6>
          <h6
            class="font-weight-light mb-2"
            v-if="whoAmI.holder.company !== null"
          >
            {{ whoAmI.holder.company.fullName }}
          </h6>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-w-full">
          <div class="card">
            <div class="card-content">
              <div class="align-self-center text-center mb-0">
                <h3 class="font-weight-light mt-3">Cartão</h3>
                <hr />
                <div class="container">
                  <div class="row">
                    <div
                      class="justify-content-center"
                      :class="hasOnline ? 'col-12 col-w-full' : 'col-4'"
                    >
                      <h5 class="font-weight-light mb-4">Débito</h5>
                      <table class="table mb-5 card-fees-slim">
                        <thead>
                          <tr>
                            <th v-if="!debitHasEqualFees"></th>
                            <th>
                              <h6 class="font-weight-light mb-1">
                                Taxa Aplicada
                              </h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border-bottom"
                            v-for="(fee, index) of fees.cardBrandFees"
                            :key="index"
                            v-show="
                              fee.debit !== null &&
                              !debitHasEqualFees"
                          >
                            <td class="card-brand">
                              <span
                                class="payment-method"
                                :class="fee.brand"
                              ></span>
                            </td>
                            <td class="align-middle card-fee">
                              <WhiteLabel>
                                <h4 class="font-weight-light text-orange mb-1">
                                  {{ fee.debit }}%
                                </h4>
                              </WhiteLabel>
                            </td>
                          </tr>
                          <tr class="border-bottom" v-if="debitHasEqualFees">
                            <td
                              class="align-middle card-fee"
                              v-if="fees.cardBrandFees !== undefined"
                            >
                              <WhiteLabel>
                                <h4 class="font-weight-light text-orange mb-1">
                                  {{ fees.cardBrandFees[0].debit }}%
                                </h4>
                              </WhiteLabel>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                    <div
                      class="justify-content-center"
                      :class="hasOnline ? 'col-12 col-w-full' : 'col-8'"
                    >
                      <h5 class="font-weight-light mb-4">Crédito</h5>
                      <div class="row">
                        <div
                          class="justify-content-center"
                          :class="hasOnline ? 'col-6' : 'col-12 col-w-full'"
                        >
                          <table class="table">
                            <thead>
                              <tr>
                                <th v-if="!creditHasEqualFees"></th>
                                <th></th>
                                <th v-if="creditHasEqualFees"></th>
                                <th>
                                  <h6 class="font-weight-light mb-1">
                                    Taxa Aplicada
                                  </h6>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="border-bottom">
                                <th v-if="!creditHasEqualFees"></th>
                                <th v-if="creditHasEqualFees"></th>
                                <th>Rotativo</th>
                                <th>Parcelado 2 à 6</th>
                                <th>Parcelado 7 à 12</th>
                              </tr>
                              <tr
                                class="border-bottom"
                                v-for="(fee, index) of fees.cardBrandFees"
                                :key="index"
                                v-show="
                                  !creditHasEqualFees"
                              >
                                <td class="card-brand">
                                  <span
                                    class="align-middle payment-method"
                                    :class="fee.brand"
                                  ></span>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h4
                                      class="font-weight-light text-orange mb-1"
                                    >
                                      {{ fee.creditSingleInstallment }}%
                                    </h4>
                                  </WhiteLabel>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h4
                                      class="font-weight-light text-orange mb-1"
                                    >
                                      {{ fee.creditMultipleInstallment }}%
                                    </h4>
                                  </WhiteLabel>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h4
                                      class="font-weight-light text-orange mb-1"
                                    >
                                      {{ fee.multipleInstallmentPercent7To12 }}%
                                    </h4>
                                  </WhiteLabel>
                                </td>
                              </tr>
                              <tr
                                class="border-bottom"
                                v-if="creditHasEqualFees"
                              >
                                <td></td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h4
                                      class="font-weight-light text-orange mb-1"
                                      v-if="fees.cardBrandFees !== undefined"
                                    >
                                      {{
                                        fees.cardBrandFees[0]
                                          .creditSingleInstallment
                                      }}%
                                    </h4>
                                  </WhiteLabel>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h4
                                      class="font-weight-light text-orange mb-1"
                                      v-if="fees.cardBrandFees !== undefined"
                                    >
                                      {{
                                        fees.cardBrandFees[0]
                                          .creditMultipleInstallment
                                      }}%
                                    </h4>
                                  </WhiteLabel>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h4
                                      class="font-weight-light text-orange mb-1"
                                      v-if="fees.cardBrandFees !== undefined"
                                    >
                                      {{
                                        fees.cardBrandFees[0]
                                          .multipleInstallmentPercent7To12
                                      }}%
                                    </h4>
                                  </WhiteLabel>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot></tfoot>
                          </table>
                          <small
                            class="ml-2 align-self-center payment-method-text"
                            v-if="whoAmI.account.saleOnline && !hasOnline"
                          >
                            +
                            {{
                              fees.cardFee.onlineAmount
                                | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })
                            }}
                            de taxa de transação.
                          </small>
                        </div>
                        <div
                          class="justify-content-center col-6"
                          v-if="hasOnline"
                        >
                          <h6 class="font-weight-light mb-4">
                            Transações online/digitadas ({{
                              whoAmI.account.transferPlan.daysOnline
                            }}
                            dias)
                          </h6>
                          <table class="table mb-2 card-fees-slim">
                            <thead>
                              <tr>
                                <th v-if="!onlineCreditHasEqualFees"></th>
                                <th v-if="onlineCreditHasEqualFees"></th>
                                <th>
                                  <h6 class="font-weight-light mb-1">
                                    Taxa Aplicada
                                  </h6>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="border-bottom">
                                <th v-if="!creditHasEqualFees"></th>
                                <th v-if="creditHasEqualFees"></th>
                                <th>Rotativo</th>
                                <th>Parcelado 2 à 6</th>
                                <th>Parcelado 7 à 12</th>
                              </tr>
                              <tr
                                class="border-bottom"
                                v-for="(fee, index) of fees.cardBrandFees"
                                :key="index"
                                v-show="
                                  !onlineCreditHasEqualFees"
                              >
                                <td class="card-brand">
                                  <span
                                    class="payment-method"
                                    :class="fee.brand"
                                  ></span>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h3
                                      class="font-weight-light text-orange mb-1"
                                    >
                                      {{ fee.creditSingleInstallment }}%
                                    </h3>
                                  </WhiteLabel>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h3
                                      class="font-weight-light text-orange mb-1"
                                    >
                                      {{ fee.creditMultipleInstallment }}%
                                    </h3>
                                  </WhiteLabel>
                                </td>
                                <td class="align-middle card-fee">
                                  <WhiteLabel>
                                    <h3
                                      class="font-weight-light text-orange mb-1"
                                    >
                                      {{ fee.multipleInstallmentPercent7To12 }}%
                                    </h3>
                                  </WhiteLabel>
                                </td>
                              </tr>
                              <tr
                                class="border-bottom"
                                v-if="
                                  onlineCreditHasEqualFees &&
                                  fees.cardBrandFees !== undefined
                                "
                              >
                                <td class="align-middle card-fee">
                                  <h3
                                    class="font-weight-light text-orange mb-1"
                                    v-if="fees.cardBrandFees !== undefined"
                                  >
                                    {{
                                      fees.cardBrandFees[0]
                                        .creditMultipleInstallment
                                    }}%
                                  </h3>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot></tfoot>
                          </table>
                          <small
                            class="ml-2 align-self-center payment-method-text"
                          >
                            +
                            {{
                              fees.transactionFee
                                | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })
                            }}
                            de taxa de transação.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row justify-content-center mt-4">
                        <h6 class="font-weight-light">
                          Taxas de antecipação para transações no crédito
                        </h6>
                      </div>
                      <div class="row">
                        <table class="table mb-2">
                          <thead>
                            <tr>
                              <th>Nº Parcelas</th>
                              <th
                                v-for="(fee, index) of fees.anticipationFees"
                                :key="index"
                              >
                                {{ fee.installmentNumber }}X
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-bottom">
                              <td class="align-middle card-fee">
                                <h6 class="font-weight-light mb-1">Taxa(%)</h6>
                              </td>
                              <td
                                class="align-middle card-fee"
                                v-for="(fee, index) of fees.anticipationFees"
                                :key="index"
                              >
                                <WhiteLabel>
                                  <h4
                                    class="font-weight-light text-orange mb-1"
                                  >
                                    {{
                                      whoAmI.account.transferPlan.anticipated
                                        ? fee.autoPercent
                                        : fee.manualPercent
                                    }}%
                                  </h4>
                                </WhiteLabel>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                        <small
                          class="
                            ml-2
                            align-self-center
                            payment-method-text
                            mb-5
                          "
                        >
                          A taxa de antecipação é aplicada nas parcelas
                          antecipadas, sendo calculado a partir do seu somatório
                          com a taxa base aplicada (com base no número de
                          parcelas da transação).
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="align-self-center text-center mb-0">
                <h3 class="font-weight-light mt-3">Boleto</h3>
                <hr />
                <table class="table mb-5 bankSlip-fees-slim">
                  <thead>
                    <tr>
                      <th>
                        <h5 class="font-weight-light mb-1">Taxa Aplicada</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom">
                      <td class="align-middle">
                        <WhiteLabel>
                          <h3 class="font-weight-light text-orange mb-1">
                            {{
                              fees.bankSlip
                                | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })
                            }}
                          </h3>
                        </WhiteLabel>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- PIX SECTION -->
          <div class="card">
            <div class="card-content">
              <div class="align-self-center text-center mb-0">
                <h3 class="font-weight-light mt-3">Pix</h3>
                <hr />
                <table class="table mb-5 bankSlip-fees-slim">
                  <thead>
                    <tr>
                      <th>
                        <h5 class="font-weight-light mb-1">Taxa Aplicada no Crédito</h5>
                      </th>
                      <th>
                        <h5 class="font-weight-light mb-1">Taxa Aplicada no Débito</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom">
                      <td class="align-middle">
                        <WhiteLabel>
                          <h3 class="font-weight-light text-orange mb-1">
                            {{
                              accountBalance.pixFeeCredit
                                | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })
                            }}
                          </h3>
                        </WhiteLabel>
                      </td>
                      <td class="align-middle">
                        <WhiteLabel>
                          <h3 class="font-weight-light text-orange mb-1">
                            {{
                              accountBalance.pixFee
                                | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })
                            }}
                          </h3>
                        </WhiteLabel>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="align-self-center text-center mb-0">
                <h3 class="font-weight-light mt-3">Ted</h3>
                <hr />
                <table class="table mb-5 bankSlip-fees-slim">
                  <thead>
                    <tr>
                      <th>
                        <h5 class="font-weight-light mb-1">Taxa Aplicada</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom">
                      <td class="align-middle">
                        <WhiteLabel>
                          <h3 class="font-weight-light text-orange mb-1">
                            {{
                              accountBalance.tedFee
                                | currency("R$ ", 2, {
                                  decimalSeparator: ",",
                                  thousandsSeparator: ".",
                                })
                            }}
                          </h3>
                        </WhiteLabel>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentsApi from '@/services/PaymentsApi';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import { Fees } from '@/models/Fees';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppFeeList',
  components: {
    WhiteLabel,
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.findFees();
    this.equalFees();
    if (this.hasOnline) {
      this.onlineEqualFees();
    }
    this.getAccountbalance();
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      accountBalance: {},
      fees: new Fees(),
      debitHasEqualFees: true,
      creditHasEqualFees: true,
      onlineCreditHasEqualFees: true,
    };
  },
  methods: {
    getAccountbalance(){
      const paymentAccountApi = new PaymentAccountsApi();
      paymentAccountApi.getBalance().then(
        success => {
          this.accountBalance = success;
        }
      );
    },
    async findFees() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      this.fees = await api.fees();
      this.$bus.$emit('spinner-stop');
    },
    equalFees() {
      let commonFee = this.fees.cardBrandFees[0].debit;
      for (let i = 0; i < this.fees.cardBrandFees.length; i++) {
        if (this.fees.cardBrandFees[i + 1] !== undefined) {
          if (
            commonFee !== this.fees.cardBrandFees[i].debit &&
            this.fees.cardBrandFees[i].debit !== null
          ) {
            this.debitHasEqualFees = false;
            break;
          }
          if (
            this.fees.cardBrandFees[i].creditMultipleInstallment !==
            this.fees.cardBrandFees[i + 1].creditMultipleInstallment
          ) {
            this.creditHasEqualFees = false;
          }
        }
      }
    },
    onlineEqualFees() {
      for (let i = 0; i < this.fees.cardBrandFees.length; i++) {
        if (this.fees.cardBrandFees[i + 1] !== undefined) {
          if (
            this.fees.cardBrandFees[i].creditMultipleInstallment !==
            this.fees.cardBrandFees[i + 1].creditMultipleInstallment
          ) {
            this.onlineCreditHasEqualFees = false;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    hasOnline() {
      return (
        this.whoAmI.account.saleOnline &&
        this.whoAmI.account.transferPlan.daysPinpad !==
          this.whoAmI.account.transferPlan.daysOnline
      );
    },
    planName() {
      if (this.whoAmI.account.transferPlan.oldPlan) {
        if (this.whoAmI.account.transferPlan.anticipated === false) {
          return 'Sem antecipação automática (descontinuado)';
        } else if (this.whoAmI.account.transferPlan.anticipated === true) {
          return 'Antecipação automática - sexta-feira (descontinuado)';
        }
      } else if (!this.whoAmI.account.transferPlan.oldPlan) {
        if (
          this.whoAmI.account.transferPlan.anticipated === false &&
          this.whoAmI.account.transferPlan.daysPinpad === 32
        ) {
          return 'Sem antecipação automática';
        } else if (
          this.whoAmI.account.transferPlan.anticipated === true &&
          this.whoAmI.account.transferPlan.daysPinpad === 2
        ) {
          return 'Antecipação automática em 2 dias';
        } else if (
          this.whoAmI.account.transferPlan.anticipated === true &&
          this.whoAmI.account.transferPlan.daysPinpad === 32
        ) {
          return 'Antecipação automática em 32 dias';
        }
      }
      return '';
    },
  },
};
</script>
<style>
h5 {
  margin-top: 10px;
}
</style>
