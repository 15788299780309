import CustomApiError from './CustomError';

export default class TransferLimitError extends CustomApiError {
    toDictionary(){
        return{
            MAXIMUM_LIMIT_NOT_FOUND: 'Valor máximo de limite não cadastrado pelo parceiro',
            AMOUNT_NOT_ALLOWED: 'O valor máximo de limite não pode ser ultrapassado',
        };
    }
}
