<template>
  <div>
    <div class="content-preferences-account">
      <div v-if="!hasBankAccount">
        <h3>Conta bancária não cadastrada</h3>
      </div>
      <div v-else>
        <WhiteLabel>
          <AppToggle
            class="switch switch-danger switch-to-success"
            label="Transferir saldo automaticamente"
            toggleTitle="Ativando essa função você escolhe um ou mais dias em que o seu saldo deve ser transferido para sua conta bancária"
            :value="downloadBalanceAutomatically"
            v-model="downloadBalanceAutomatically"
            @valueToggle="setValueDownloadBalanceAutomatically($event)"
          />
        </WhiteLabel>
        <div v-if="downloadBalanceAutomatically" class="margin-top">
          <span class="mt-3 py-2">
            Escolha um ou mais dias em que o seu saldo deve ser transferido
            automaticamente
          </span>
          <div class="checkbox">
            <WhiteLabel class="checkbox">
              <div class="pr-2" v-for="data in days" :key="data.value">
                <input
                  @click="showMenu = true"
                  type="checkbox"
                  :id="data.label"
                  :value="data.value"
                  v-model="transferDays"
                />
                <label
                  :for="data.label"
                  class="
                    btn btn-check btn-transference
                    pl-3
                    pr-3
                    text-uppercase
                  "
                  >{{ data.label }}</label
                >
              </div>
            </WhiteLabel>
          </div>

          <p class="mt-4">Conta de destino</p>
          <AppDetailsBank class="data-account-user" :pBankData="bankAccount" />
        </div>
        <div class="align-self-center" v-if="confirmTransferPermission">
          <WhiteLabel>
            <button
              class="btn btn-orange btn-gray btn-pill pl-5 pr-5 mt-4"
              data-toggle="modal"
              data-target="#modal-preferences-account-confirm"
              v-if="
                downloadBalanceAutomatically
                  ? transferDays.length > 0 && showMenu
                  : true
              "
              @click="openConfirm"
            >
              Salvar
            </button>
          </WhiteLabel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import AppToggle from '@/components/shared/Toggle';
import WhiteLabel from '@/components/shared/WhiteLabel';
import AppModalPreferencesAccountConfirm from '@/components/account/preferences/ModalPreferencesAccountConfirm';
import AppDetailsBank from '@/components/account/transference/DetailsBank';
import AccountApi from '@/services/AccountApi';
import { mapGetters } from 'vuex';

export default {
  name: 'AppModalPreferencesAccount',
  components: {
    AppToggle,
    AppDetailsBank,
    WhiteLabel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    settings: {
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    bankAccount: null,
  },
  data() {
    return {
      downloadBalanceAutomatically: false,
      transferDaysCopy: [],
      days: [
        { value: 'monday', label: 'Seg' },
        { value: 'tuesday', label: 'Ter' },
        { value: 'wednesday', label: 'Qua' },
        { value: 'thursday', label: 'Qui' },
        { value: 'friday', label: 'Sex' },
      ],
      transferDays: [],
      showMenu: false,
    };
  },
  async mounted() {
    this.accountApi = new AccountApi();
  },
  methods: {
    setValueDownloadBalanceAutomatically($event) {
      this.downloadBalanceAutomatically = $event;
    },
    async setSettingsAccount() {
      this.$emit('setSettingsAccount', this.downloadBalanceAutomatically);
    },
    openConfirm() {
      this.$emit('openConfirm', this.transferDays, 1);
    },
  },
  watch: {
    settings: function () {
      this.settingsCopy = this.settings;
      if (
        this.settings &&
        !this.settings.isMonday &&
        !this.settings.isTuesday &&
        !this.settings.isWednesday &&
        !this.settings.isThursday &&
        !this.settings.isFriday
      ) {
        this.downloadBalanceAutomatically = false;
      } else {
        this.downloadBalanceAutomatically = true;
      }

      if (this.settings.isMonday) this.transferDays.push('monday');
      if (this.settings.isTuesday) this.transferDays.push('tuesday');
      if (this.settings.isWednesday) this.transferDays.push('wednesday');
      if (this.settings.isThursday) this.transferDays.push('thursday');
      if (this.settings.isFriday) this.transferDays.push('friday');
    },
    transferDays: function () {
      if (this.transferDays.length === 0) {
        this.downloadBalanceAutomatically = false;
      }
    },
    downloadBalanceAutomatically: function (newValue) {
      if (newValue === false) {
        this.transferDaysCopy = this.transferDays;
        this.transferDays = [];
      } else {
        this.transferDays =
          this.transferDaysCopy.length > 0
            ? this.transferDaysCopy
            : this.transferDays;
      }
    },
  },
  computed: {
    ...mapGetters({
      permissions: 'account/permissions',
      whoAmI: 'account/whoAmI',
    }),
    isAccountHolder() {
      if (this.whoAmI) {
        return this.whoAmI.accessedByHolder;
      }
    },
    paymentAccountTransferManagementPermissionIndex() {
      return this.permissions.findIndex(
        (x) => x.name === 'paymentAccounts.settings'
      );
    },
    confirmTransferPermission() {
      if (
        (this.permissions[
          this.paymentAccountTransferManagementPermissionIndex
        ] != null &&
          this.permissions[this.paymentAccountTransferManagementPermissionIndex]
            .grant === 'edit') ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasBankAccount() {
      return this.bankAccount != null;
    },
  },
};
</script>
