<template>
  <div>
    <holder-access-control>
    </holder-access-control>
  </div>
</template>
<script>
import HolderAccessControl from './HolderAccessControl.vue';

  export default {
    components: {
      HolderAccessControl,
    },
    data() {
      return {

      };
    },
  };
</script>
