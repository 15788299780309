import { render, staticRenderFns } from "./SplitterModalScheduledTransference.vue?vue&type=template&id=2109f213&scoped=true"
import script from "./SplitterModalScheduledTransference.vue?vue&type=script&lang=js"
export * from "./SplitterModalScheduledTransference.vue?vue&type=script&lang=js"
import style0 from "./SplitterModalScheduledTransference.vue?vue&type=style&index=0&id=2109f213&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2109f213",
  null
  
)

export default component.exports