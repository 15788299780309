<template>
  <div>
    <div class="container">
      <form action="#">
        <div class="row">
          <div class="col-12 col-w-full">
            <div class="card">
              <div class="d-flex mb-3 btn-iteraction">
                <div class="card-content mr-auto">
                  <h3 class="font-weight-light ml-3">Permissões do Lojista</h3>
                  <h6 class="font-weight-light mb-0">
                    Controla quais funcionalidades serão exibidas no NetBanking
                    de seus lojistas
                  </h6>
                </div>
                <div class="ml-auto">
                  <div>
                    <WhiteLabel>
                      <a
                        href="#"
                        @click="$router.go(-1)"
                        class="pull-right mt-2 mr-4"
                      >
                        <i class="icon-arrow-left-circle"></i> Voltar
                      </a>
                    </WhiteLabel>
                  </div>
                </div>
                <div>
                  <WhiteLabel>
                    <button
                      class="btn btn-orange"
                      id="action"
                      @click.prevent="isDisabled = !isDisabled"
                      v-if="isDisabled && this.whoAmI.accessedByHolder"
                    >
                      <i class="icon-pencil"></i>
                      Editar campos
                    </button>
                  </WhiteLabel>
                  <div>
                    <WhiteLabel>
                      <button
                        v-if="!isDisabled"
                        class="btn btn-orange"
                        id="action"
                        @click.prevent="save"
                      >
                        Salvar alterações
                      </button>
                    </WhiteLabel>
                  </div>
                </div>
              </div>
              <div
                class="group-grid-toggle"
                v-for="group in this.modelFeatures"
                :key="group.father.featureName"
              >
                <div class="card-column">
                  <WhiteLabel>
                    <AppToggle
                      :isDisabled="isDisabled"
                      :label="group.father.name"
                      :toggleTitle="group.father.info"
                      :value="group.father.enabled"
                      @valueToggle="onChangeFeatureState($event, group.father)"
                    >
                    </AppToggle>
                  </WhiteLabel>
                  <hr v-if="group.list.length > 0" />
                </div>
                <div
                  class="card-line"
                  v-for="feature in group.list"
                  :key="feature.featureName"
                >
                  <WhiteLabel>
                    <AppToggle
                      :isDisabled="!group.father.enabled || isDisabled"
                      :label="feature.name"
                      :toggleTitle="feature.info"
                      :value="feature.enabled"
                      @valueToggle="onChangeFeatureState($event, feature)"
                    >
                    </AppToggle>
                  </WhiteLabel>
                </div>
              </div>
              <div class="d-flex mb-3 btn-iteraction">
                <div class="ml-auto">
                  <WhiteLabel>
                    <a
                      href="#"
                      @click="$router.go(-1)"
                      class="pull-right mt-2 mr-4"
                    >
                      <i class="icon-arrow-left-circle"></i> Voltar
                    </a>
                  </WhiteLabel>
                </div>
                <div>
                  <WhiteLabel>
                    <button
                      class="btn btn-orange"
                      id="action"
                      @click.prevent="isDisabled = !isDisabled"
                      v-if="isDisabled && this.whoAmI.accessedByHolder"
                    >
                      <i class="icon-pencil"></i>
                      Editar campos
                    </button>
                  </WhiteLabel>
                </div>
                <div>
                  <WhiteLabel>
                    <button
                      v-if="!isDisabled"
                      class="btn btn-orange"
                      id="action"
                      @click.prevent="save"
                    >
                      Salvar alterações
                    </button>
                  </WhiteLabel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AppToggle from '@/components/shared/Toggle';
import WhiteLabel from '@/components/shared/WhiteLabel';
import { mapActions, mapGetters } from 'vuex';
import AccountApi from '../../../services/AccountApi';
import Alert from '@/models/Alert';
import HiddenFeatureError from '@/errors/HiddenFeatureError';

export default {
  name: 'AppHolderAccessControl',

  components: {
    WhiteLabel,
    AppToggle,
  },

  data: () => ({
    isDisabled: true,
    modelFeatures: [],
    listFeatures: [],
    listKnownFeatures: [
      {
        name: 'Transação no Cartão',
        featureName: 'transacao-cartao',
        father: '',
      },
      {
        name: 'Transação no Boleto',
        featureName: 'transacao-boleto',
        father: '',
      },
      { name: 'Indicadores', featureName: 'indicadores', father: '' },
      {
        name: 'Minhas Transações',
        featureName: 'minhas-transacoes',
        father: '',
      },
      {
        name: 'Cartão e Boleto',
        featureName: 'minhas-transacoes-cartao-boleto',
        father: 'minhas-transacoes',
      },
      {
        name: 'Links de Cobrança',
        featureName: 'minhas-transacoes-links-cobranca',
        father: 'minhas-transacoes',
      },
      {
        name: 'Antecipação de Recebíveis',
        featureName: 'minhas-transacoes-antecipacao-recebiveis',
        father: 'minhas-transacoes',
      },
      { name: 'Conta', featureName: 'conta', father: '' },
      {
        name: 'Saldo e Extrato',
        featureName: 'conta-saldo-extrato',
        father: 'conta',
      },
      {
        name: 'Transferências',
        featureName: 'conta-transferencias',
        father: 'conta',
      },
      {
        name: 'Pagamento de Contas',
        featureName: 'conta-pagamento-contas',
        father: 'conta',
      },
      {
        name: 'Próximos Lançamentos',
        featureName: 'conta-proximos-lancamentos',
        father: 'conta',
      },
      {
        name: 'Preferências',
        featureName: 'conta-preferencias',
        father: 'conta',
      },
      // PIX SECTION
      {
        name: 'Pix',
        featureName: 'pix',
        father: '',
      },
      {
        name: 'Minhas Chaves',
        featureName: 'pix-minhas-chaves',
        father: 'pix',
      },
      {
        name: 'Minhas Movimentações',
        featureName: 'pix-minhas-movimentacoes',
        father: 'pix',
      },
      {
        name: 'Splitter',
        featureName: 'splitter',
        father: '',
      },
      {
        name: 'Cadastros',
        featureName: 'splitter-cadastros',
        father: 'splitter',
      },
      {
        name: 'Transferências',
        featureName: 'splitter-transferencias',
        father: 'splitter',
      },
      {
        name: 'Clientes',
        featureName: 'clientes',
        father: '',
      },
      {
        name: 'Controle de Acesso',
        featureName: 'controle-acesso',
        father: '',
      },
      {
        name: 'Perfis de Usuários',
        featureName: 'controle-acesso-perfis-usuarios',
        father: 'controle-acesso',
      },
      {
        name: 'Usuários',
        featureName: 'controle-acesso-usuarios',
        father: 'controle-acesso',
      },
    ],
  }),

  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
  },

  created() {},

  beforeMount() {
    this.$bus.$emit('spinner-run');
    let accountApi = new AccountApi();
    accountApi.features.getList().then((x) => {
      this.listFeatures = x.data.listFeatures;
      this.beuatifyFeatures();
    });
    this.$bus.$emit('spinner-stop');
  },

  mounted() {},

  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      updateLayout: 'layout/updateLayout',
      fetchLayout: 'layout/fetchLayout',
    }),

    async save() {
      this.$bus.$emit('spinner-run');
      let accountApi = new AccountApi();
      let listFeatures = this.modelFeatures.map((ft) => {
        return {
          featureName: ft.father.featureName,
          enabled: ft.father.enabled,
        };
      });
      let childFeatures = [];
      this.modelFeatures.forEach((fft) => {
        fft.list.forEach((ft) => {
          childFeatures.push({
            featureName: ft.featureName,
            enabled: ft.enabled,
          });
        });
      });
      listFeatures = [...listFeatures, ...childFeatures];

      var result = await accountApi.features.update(listFeatures);

      if (result.status == 200) {
        const alert = new Alert();
        alert.title = 'Pronto';
        alert.message = 'Permissões alteradas!';
        this.$bus.$emit('alert-sucess', alert);
        this.$bus.$emit('spinner-stop');
        this.isDisabled = true;
      } else {
        const alert = new Alert();
        const modelError = new HiddenFeatureError(result.data);
        const error = modelError.getMessage();
        alert.title = 'Ops';
        alert.message = error;
        this.$bus.$emit('alert-warning', alert);
        this.$bus.$emit('spinner-stop');
        this.isDisabled = true;
      }
    },

    onChangeFeatureState(event, feature) {
      // update child if he is a child
      let fatherIndex = this.modelFeatures.findIndex(
        (ft) =>
          ft.list.findIndex((fc) => fc.featureName == feature.featureName) !==
          -1
      );
      let father = this.modelFeatures[fatherIndex];
      if (fatherIndex !== -1 && father) {
        father.list.forEach((ft) => {
          if (ft.featureName == feature.featureName) ft.enabled = event;
        });
        this.$set(this.modelFeatures, fatherIndex, father);
      }
      // update father if he is a father
      fatherIndex = this.modelFeatures.findIndex(
        (ft) => ft.father.featureName == feature.featureName
      );
      father = this.modelFeatures[fatherIndex];
      if (fatherIndex !== -1 && father) {
        father.father.enabled = event;
        this.$set(this.modelFeatures, fatherIndex, father);
      }
    },

    beuatifyFeatures() {
      let list = this.listKnownFeatures.map((ft) => {
        return {
          name: ft.name,
          featureName: ft.featureName,
          father: ft.father,
          info: `Define se o lojista pode acessar o seguinte item: ${ft.name}`,
          enabled:
            this.listFeatures.find((f) => ft.featureName == f.featureName) ==
            undefined,
        };
      });
      let listGroups = [];
      list.forEach((ft) => {
        if (ft.father === '') listGroups.push({ father: ft, list: [] });
      });
      listGroups.forEach((group) => {
        group.list = list.filter((ft) => ft.father == group.father.featureName);
      });
      this.modelFeatures = listGroups;
    },
  },
};
</script>

<style lang="scss">
.btn-iteraction {
  padding-top: 3%;
  padding-right: 3%;
}
.group-grid-toggle {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 1%;
  border: 1px solid;
  border-radius: inherit;
}
.card-toggle {
  padding: 10px;
}
.card-line {
  display: flex;
  padding: 3%;
}
.card-column {
  grid-column: 1/-1;
  padding: 1%;
  color: #ff7606;
  font-weight: bold;
}
hr {
  margin-bottom: 0px;
}
h6 {
  margin-left: 1rem;
}
</style>
