<template>
  <div class="content d-flex flex-column align-items-center settings">
    <em class="icon-check icon mt-6"></em>
    <span class="title">Transferências automáticas desligadas!</span>
    <span class="text-center"
      >A partir de agora você poderá retirar o dinheiro da sua conta quando
      quiser.</span
    >
    <WhiteLabel>
      <button
        class="btn btn-orange btn-pill pl-5 pr-5 align-self-center mt-6 mb-4"
        aria-label="Close"
        data-dismiss="modal"
        @click="exit()"
      >
        Concluir
      </button>
    </WhiteLabel>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalAutomaticTransfersOff',
  components: {
    WhiteLabel,
  },
  methods: {
    exit() {
      this.$emit('activeTransference', false);
      this.$bus.$emit('closePreferences');
    },
  },
};
</script>
<style lang="scss" scoped>
.settings {
  padding: 1rem;

  .icon {
    font-size: 4rem;
    padding: 1rem;
    padding-bottom: 4rem;
    color: #afdb26;
  }
  .title {
    font-size: 1.4rem;
    display: block;
    text-align: center;
  }
}
</style>
