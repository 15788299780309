<template>
  <div :class="{ 'toggle-disabled': isDisabled }">
    <div class="d-flex align-items-center">
      <label class="switch" :class="size">
        <input
          :disabled="isDisabled"
          type="checkbox"
          :id="label"
          :class="color"
          v-model="toggle"
          @change="updateChanges()"
        />
        <span class="slider round"></span>
      </label>
      <label
        :for="label"
        data-toggle="tooltip"
        data-html="true"
        data-placement="bottom"
        :title="toggleTitle"
        v-if="toggleTitle"
        >{{ label }}</label
      >
      <label :for="label" v-else>{{ label }}</label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppToggle',
  data() {
    return {
      toggle: false,
      style: {
        active: true,
        'toggle-disabled': true,
      },
    };
  },
  props: {
    label: {
      required: true,
      type: String,
    },
    size: {
      type: String,
      default: 'sm',
    },
    color: {
      type: String,
      default: 'primary',
    },
    value: {
      type: Boolean,
      default: false,
    },
    toggleTitle: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateChanges() {
      this.$emit('valueToggle', this.toggle);
    },
  },
  async mounted() {
    this.toggle = this.value;
    /* eslint-disable no-undef */
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
  watch: {
    value: function () {
      this.toggle = this.value;
    },
  },
};
</script>
<style>
.toggle-disabled {
  opacity: .4;
}
</style>
