<template>
  <div class="modal-transfer-base">
    <ModalBase
      :hasFooter="false"
      :title="title"
      :id="id"
      :btnBackgroundGray="btnBackgroundGray"
      :size="
        control === 1 || control === 2.1
          ? 'how-much-transfer'
          : control === 0
          ? 'preferences-account-size'
          : 'transference-size'
      "
    >
      <div slot="content" v-if="control === 0">
        <SplitterModalSelectDestination
          :changeControl="changeControl"
          :viewControl="control"
        />
      </div>

      <div slot="content" v-if="control >= 1 && control < 2">
        <SplitterModalTransference
          :changeControl="changeControl"
          :viewControl="control"
        />
      </div>

      <div slot="content" v-if="control >= 2">
        <SplitterModalTED
          :changeControl="changeControl"
          :viewControl="control"
        />
      </div>
    </ModalBase>
  </div>
</template>

<script>
import SplitterModalSelectDestination from '@/components/account/transference/SplitterModalSelectDestination';
import SplitterModalTransference from '@/components/account/transference/SplitterModalTransference';
import SplitterModalTED from '@/components/account/transference/SplitterModalTED';
import ModalBase from '@/components/shared/ModalBase';

export default {
  name: 'AppModalTransferBase',
  components: {
    ModalBase,
    SplitterModalTED,
    SplitterModalTransference,
    SplitterModalSelectDestination,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    balance: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      control: 0,
      title: 'Transferência partindo do Splitter',
      controls: {
        0: 'Transferência partindo do Splitter',
        1: 'Selecione o splitter',
        1.1: 'Valor',
        1.2: 'Confirmar dados',
        1.3: 'Digite sua senha para confirmar',
        1.4: 'Transferência efetuada',
        2: 'Selecione o Splitter',
        2.1: 'Quem será o favorecido?',
        2.2: 'Dados bancários',
        2.3: 'Valor',
        2.4: 'Confirmar dados',
        2.5: 'Código de autenticação',
        2.6: 'Informe o código recebido',
        2.7: 'Transferência efetuada',
      },
      typeTransfer: {
        otherAccount: 0,
        ownAccount: 1,
      },
      value: 0,
      splitter: {},
      btnBackgroundGray: false,
    };
  },
  mounted() {
    this.$bus.$on('cleanInput', (result) => {
      this.changeControl(0);
      this.btnBackgroundGray = false;
    });
  },
  methods: {
    changeControl(number) {
      this.control = number;
      this.title = this.controls[number];

      if (this.control === 2.4 || this.control === 1.3) {
        this.btnBackgroundGray = true;
      } else {
        this.btnBackgroundGray = false;
      }
    },
    setValue(val, splitter, control) {
      this.value = val;
      this.splitter = splitter;
      this.changeControl(control);
    },
    updateSplitter(splitter, control) {
      this.splitter = splitter;
      this.changeControl(control);
    },
  },
};
</script>

<style lang="scss">
.modal-transfer-base {
  .content-modal-transfer .transfer-other-account {
    background-color: white !important;
    padding-left: 0rem;
  }
}
</style>
