import { render, staticRenderFns } from "./ModalAutomaticTransfersOff.vue?vue&type=template&id=55fa790c&scoped=true"
import script from "./ModalAutomaticTransfersOff.vue?vue&type=script&lang=js"
export * from "./ModalAutomaticTransfersOff.vue?vue&type=script&lang=js"
import style0 from "./ModalAutomaticTransfersOff.vue?vue&type=style&index=0&id=55fa790c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55fa790c",
  null
  
)

export default component.exports