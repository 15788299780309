<template>
  <div>
    <div class="preferences">
      <em class="icon transfer-programmed gray-2 ml-3 mr-1" />
      <a @click="openModal" class="btn-preferences">
        <div class="conteudo">
          <span class="block">Transferencias automáticas</span>
          <span class="value">{{ getSettingsFormatting }}</span>
        </div>
        <div class="icon blue-arrow">
          <AppModalPreferencesBase
            id="modalPreferencesBase"
            :settings="settings"
          />
          <em class="icon-arrow-right"></em>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import AppModalPreferencesBase from '@/components/account/preferences/ModalPreferencesBase';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

export default {
  name: 'AppPreferences',
  components: {
    AppModalPreferencesBase,
  },
  data() {
    return {
      settings: null,
      automaticDays: null,
      modalPreferencesBase: 'modalPreferencesBase',
    };
  },
  async mounted() {
    await this.getPreferencesAccount();

    this.$bus.$on('closePreferences', (result) => {
      this.getPreferencesAccount();
    });
  },
  methods: {
    async getPreferencesAccount() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      this.settings = await api.getSettings();
      this.$bus.$emit('spinner-stop');
    },
    openModal() {
      $('#modalPreferencesBase').modal('show');
    },
  },
  computed: {
    getSettingsFormatting() {
      let daysFormatting = '';
      if (this.settings) {
        if (this.settings.isMonday) daysFormatting = 'Seg, ';
        if (this.settings.isTuesday) daysFormatting += 'Ter, ';
        if (this.settings.isWednesday) daysFormatting += 'Qua, ';
        if (this.settings.isThursday) daysFormatting += 'Qui, ';
        if (this.settings.isFriday) daysFormatting += 'Sex,';

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        } else {
          const last = daysFormatting.lastIndexOf(',');
          daysFormatting = daysFormatting.substring(last, '');
        }
      }

      return daysFormatting;
    },
  },
};
</script>
