<template>
  <div class="ted-container">
    <section v-if="viewControl === 1">
      <div class="form-group col-12 col-w-full mb-4">
        <div class="form-group col-12 col-w-full mb-4">
          <input
            type="text"
            class="form-control mb-1"
            maxlength="120"
            placeholder="Nome do splitter"
            v-model="splitterFilter.name"
            @keyup.enter="getSplitters"
          />
        </div>
        <div class="form-group col-12 col-w-full mb-4">
          <input
            type="text"
            class="form-control mb-1"
            maxlength="18"
            placeholder="CPF/CNPJ"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="splitterFilter.taxDocument"
            @keyup.enter="getSplitters"
          />
          <small class="text-danger" v-if="errorMessages.filterTaxDocument"
            >Documento inválido</small
          >
        </div>
        <WhiteLabel>
          <button @click="getSplitters" class="btn btn-block btn-pill mb-3">
            Pesquisar
          </button>
        </WhiteLabel>
      </div>
      <div
        class="form-group col-12 col-w-full"
        v-if="destinationsList.length > 0"
      >
        <ul class="destinations-list mb-4">
          <li
            v-for="destinationSplitter in destinationsList"
            :key="destinationSplitter.id"
          >
            <div
              class="user-favored"
              @click="
                selectDestination(
                  destinationSplitter.id,
                  destinationSplitter.name
                )
              "
            >
              <p>
                <strong>{{ destinationSplitter.name }}</strong>
                <br />
                {{ destinationSplitter.bankAccount.taxDocument }}
              </p>
              <p class="mb-0 bank-info">
                <strong>Banco:</strong>
                {{ destinationSplitter.bankAccount.bankNumber }} |
                <strong>Agência:</strong>
                {{ destinationSplitter.bankAccount.bankBranchNumber }} |
                <strong
                  >C{{
                    getBankTypeInitial(destinationSplitter.bankAccount.type)
                  }}:</strong
                >
                {{ destinationSplitter.bankAccount.accountNumber }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div
        class="form-group col-12 col-w-full mb-4"
        v-if="destinationsList.length === 0"
      >
        <h4>Você ainda não tem splitters cadastrados.</h4>
      </div>
      <WhiteLabel>
        <button
          @click="changeControl(0)"
          class="btn btn-block btn-outline-orange mb-3"
        >
          Voltar
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 1.1">
      <h4 class="form-group col-12 mb-4">Qual valor você deseja transferir?</h4>
      <div class="form-group col-12 col-w-full mb-4">
        <money
          v-model="amount"
          class="input-value input-money"
          :decimal="','"
          :thousands="'.'"
          :precision="2"
        ></money>
        <small class="text-danger" v-if="errorMessages.minimumValueToTransfer"
          >O valor mínimo é de R${{ getMinimumValue }}</small
        >

        <small class="text-danger" v-if="errorMessages.balance"
          >Saldo insuficiente.</small
        >
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-8 mb-4">Disponivel para transferência</div>
        <div class="form-group col-4 mb-4">
          <strong>R${{ getBalanceValue }}</strong>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-8 mb-4">Taxa de transferência</div>
        <div class="form-group col-4 mb-4">
          <strong>R${{ getTransferFeeValue }}</strong>
        </div>
      </div>
      <WhiteLabel class="mt-lg">
        <button @click="handleReview" class="btn btn-block btn-orange mb-3">
          Continuar
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 1.2">
      <div class="d-flex form-group">
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor</label>
          <label class="price-label">R${{ getAmountValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-10 mb-4">
          <label class="small-label">Taxa de transferência</label>
          <label class="small-price-label">R${{ getTransferFeeValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor debitado na sua conta</label>
          <label class="small-price-label">
            <strong>R${{ getAmountAndFeeValue }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-10">
          <label class="small-label">Destino</label>
          <label class="small-price-label">
            <strong>{{ destinationName }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group mt-4">
        <div class="form-group col-12">
          <div class="d-flow">
            <h4>Deseja inserir alguma observação?</h4>
            <textarea
              class="input-area"
              v-model="note"
              maxlength="254"
            ></textarea>
          </div>
        </div>
      </div>

      <WhiteLabel class="mt-lg">
        <button @click="goToStep(1.3)" class="btn btn-block btn-orange mb-3">
          Continuar
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 1.3">
      <input
        type="password"
        class="form-control mb-4"
        placeholder="Insira sua senha"
        maxlength="100"
        v-model="password"
      />
      <input
        type="text"
        class="form-control mb-4"
        placeholder="Digite os 4 primeiros números do CPF/CNPJ"
        maxlength="4"
        v-mask="'####'"
        v-model="document"
      />
      <WhiteLabel>
        <button
          @click="handleTransfer"
          class="btn btn-block btn-orange mb-3"
          :disabled="password === '' || document === ''"
        >
          Efetuar transferência
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 1.4">
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4">
          <i class="icon icon-small transfer gray"></i>
        </div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor</label>
          <label class="price-label">R${{ getAmountValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-10 mb-4">
          <label class="small-label">Taxa de transferência</label>
          <label class="small-price-label">R${{ getTransferFeeValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor debitado na sua conta</label>
          <label class="small-price-label">
            <strong>R${{ getAmountAndFeeValue }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-10">
          <label class="small-label">Destino</label>
          <label class="small-price-label">
            <strong>{{ destinationName }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group" v-if="note.length">
        <div class="form-group col-10">
          <label class="small-label">Observação</label>
          <label class="small-price-label">
            <strong>{{ note }}</strong>
          </label>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Money } from 'v-money';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Spinner from '@/components/theme/Spinner';
import { validateCpfCnpj } from '@/validations/cpfCnpj';
import { required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import SplitterApi from '@/services/v2/SplitterApi';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import TwoFactorAuthError from '@/errors/TwoFactorAuthError';
import CashoutError from '@/errors/CashoutError';

export default {
  name: 'ModalTED',

  components: {
    WhiteLabel,
    Money,
  },

  props: {
    viewControl: {
      type: Number,
      default: 0,
    },
    changeControl: {
      type: Function,
      default: () => 1,
    },
  },

  data: () => ({
    showRegisteredDestination: false,
    destinationsList: [],
    destinationsCount: 0,
    destinationId: 0,
    destinationName: '',
    document: '',
    amount: 0,
    note: '',
    minimumValueToTransfer: 30,
    balance: 0,
    transferFee: 0,
    password: '',
    success: false,
    token: null,
    splitterFilter: {
      name: '',
      taxDocument: '',
    },
    errorMessages: {
      holderName: false,
      holderTaxDocument: false,
      bankNumber: false,
      bankAccountNumber: false,
      bankBranchNumber: false,
      bankAccountVariation: null,
      bankAccountType: false,
      amount: false,
      balance: false,
      minimumValueToTransfer: false,
      password: false,
      filterTaxDocument: false,
    },
  }),

  async mounted() {
    this.getSplitters();
  },

  methods: {
    async getSplitters() {
      this.$bus.$emit('spinner-run');

      this.errorMessages.filterTaxDocument =
        this.splitterFilter.taxDocument != null &&
        !validateCpfCnpj(this.splitterFilter.taxDocument);

      if (this.errorMessages.filterTaxDocument) {
        this.$bus.$emit('spinner-stop');

        return;
      }

      const api = new SplitterApi();
      const result = await api.getAll({
        name: this.splitterFilter.name != '' ? this.splitterFilter.name : null,
        taxDocument:
          this.splitterFilter.taxDocument != ''
            ? this.splitterFilter.taxDocument
            : null,
        length: 15,
      });

      this.noSplittersFound = result.count === 0;
      this.destinationsList = result.splitters;
      this.$bus.$emit('spinner-stop');
    },
    handleReview() {
      this.errorMessages.minimumValueToTransfer =
        this.amount < this.minimumValueToTransfer;

      this.errorMessages.balance = this.amount > this.balance;

      if (
        this.errorMessages.minimumValueToTransfer ||
        this.errorMessages.balance
      ) {
        return;
      }

      this.goToStep(1.2);
    },
    handleChangeSaveDestination() {
      this.saveNewDestination = !this.saveNewDestination;
    },
    async selectDestination(id, name) {
      this.destinationId = id;
      this.destinationName = name;

      await this.getBalance();
      await this.getMinimumValueToTransfer();
      this.showRegisteredDestination = false;
      this.goToStep(1.1);
    },
    goToStep(step) {
      this.changeControl(step);
    },
    async getBalance() {
      const api = new PaymentAccountsApi();
      const result = await api.getSplitterBalance(this.destinationId);

      if (result.balanceList[0].availableBalance) {
        this.transferFee = result.balanceList[0].transferFee || 0;
        this.balance = result.balanceList[0].availableBalance || 0;
      }
    },
    async getMinimumValueToTransfer() {
      const api = new PaymentAccountsApi();
      const result = await api.getMinimumValueToTransfer();

      this.minimumValueToTransfer =
        result.amount || this.minimumValueToTransfer;
    },
    async handleTransfer() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      const accountApi = new AccountApi();

      accountApi.do2FA(this.document, this.password, null).then((response) => {
        if (response.data.error) {
          const modelError = new TwoFactorAuthError(response.data);
          this.$alert.error('Ops', modelError.getMessage());
          this.$bus.$emit('spinner-stop');
        } else {
          this.token = response.data.token || null;

          api
            .createSplitterCashoutRequest(
              this.amount,
              this.destinationId,
              this.note,
              this.token
            )
            .then((cashoutResponse) => {
              if (cashoutResponse.data.error) {
                const cachoutError = new CashoutError(cashoutResponse.data);
                this.$alert.error('Ops', modelError.getMessage());
                this.$bus.$emit('spinner-stop');

                return;
              }

              if (cashoutResponse.data.id) {
                this.success = true;
                this.$bus.$emit('spinner-stop');
                this.goToStep(1.4);
              }

              if (this.saveNewDestination) {
                const newDestinationResult = api.createDestination(
                  this.destination
                );

                this.$bus.$emit('spinner-stop');
              }
            })
            .catch((cashoutError) => {
              this.$alert.error(
                'Ops',
                'Não foi possível realizar a transferência.'
              );
              this.$bus.$emit('spinner-stop');
            });
        }
      });
    },
    getBankTypeInitial(bankAccountType) {
      return bankAccountType.toLowerCase().substring(0, 1);
    },
  },

  computed: {
    getBalanceValue() {
      return this.balance.toFixed(2).replace('.', ',');
    },
    getAmountAndFeeValue() {
      const value = this.amount + this.transferFee;

      return value.toFixed(2).replace('.', ',');
    },
    getAmountValue() {
      return this.amount.toFixed(2).replace('.', ',');
    },
    getTransferFeeValue() {
      return this.transferFee.toFixed(2).replace('.', ',');
    },
    getMinimumValue() {
      return this.minimumValueToTransfer.toFixed(2).replace('.', ',');
    },
  },
};
</script>

<style lang="scss" scoped>
.ted-container {
  padding: 1rem;
}
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}
.input-area {
  width: 100%;
  border: solid thin #eeeef1;
  border-radius: 6px;
}
.mt-lg {
  margin-top: 80px;
}
.small-label {
  display: block;
  text-transform: uppercase;
  color: #7d8287;
  font-size: 12px;
}
.price-label {
  display: block;
  font-size: 30px;
  color: #7d8287;
}
.small-price-label {
  display: block;
  color: #7d8287;

  &--inline {
    display: inline;
  }
}
.icon-small {
  width: 20px;
  height: 20px;
}
label .checkbox-badge {
  margin-top: 5px;
}
.destinations-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 10px;
    margin: 0 0 20px 0;
    background: #f2f2f2;
    border: solid thin #dbdbdb;
    cursor: pointer;
  }
}
.bank-info {
  font-size: 11pt;
}
</style>
