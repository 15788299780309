<template>
  <div>
    <AppFormFilterUpcomingReleases @filter="newRequest($event)" />
    <div class="d-flex flex-column balance">
      <div class="d-flex justify-content-between align-items-end">
        <WhiteLabel v-if="filter">
          <a class="pointer" @click="showBalance = !showBalance">
            <span class="text-uppercase label-16 font mr-1">
              Saldo a receber
            </span>
            <em class="icon-blocked icon-eye large-icons" />
          </a>
        </WhiteLabel>
        <AppModalBalanceTypes id="modal-balance-types" />
        <a data-toggle="modal" data-target="#modal-balance-types">
          <em class="icon help-icon gray" />
        </a>
      </div>
      <div class="inline">
        <span class="R">R$</span>
        <span v-show="showBalance" class="value-balance">{{
          getBalance.futureBalance
            | currency('', 2, {
              decimalSeparator: ',',
              thousandsSeparator: '.',
            })
        }}</span>
        <span v-show="!showBalance" class="ml-1 value-balance font">
          .....
        </span>
      </div>
      <span
        v-if="getBalance.anticipatedBalance > 0"
        v-show="showBalance"
        class="font label-16 pt-3"
      >
        {{
          getBalance.anticipatedBalance
            | currency('R$ ', 2, {
              decimalSeparator: ',',
              thousandsSeparator: '.',
            })
        }}
        antecipáveis
      </span>
      <span
        v-if="getBalance.anticipatedBalance > 0"
        v-show="!showBalance"
        class="value-balance font"
      >
        R$ ..... antecipáveis
      </span>
      <span></span>
    </div>
    <div
      v-if="recentReleases && recentReleases.count > 0"
      class="d-flex flex-column"
    >
      <div class="d-flex flex-column recentReleases">
        <span class="font-title">Lançamentos Futuros</span>
        <AppHistoric
          v-if="renderHistoricComponent"
          :historic="recentReleases"
          view="upcoming"
          :detailsData="filter.detailRelease"
        />
        <WhiteLabel class="m-3 mb-6 align-self-center">
          <button
            :disabled="allLoaded"
            type="button"
            class="btn btn-outline btn-load"
            @click="loadMore()"
          >
            {{ allLoaded ? 'Todos itens carregados' : 'Carregar mais' }}
          </button>
        </WhiteLabel>
      </div>
    </div>
    <div v-else class="pt-5 text-center">
      <span class="lancamentos" v-if="filter">
        Lançamentos - {{ filter.params.startDate | moment('DD/MM/YYYY') }} até
        {{ filter.params.endDate | moment('DD/MM/YYYY') }}
      </span>
      <AppNotFound message="Sem lançamentos previstos no período informado" />
    </div>
  </div>
</template>
<script>
import AppModalBalanceTypes from '@/components/shared/modal-balance-types/ModalBalanceTypes';
import AppHistoric from '@/components/shared/Historic';
import AppFormFilterUpcomingReleases from '@/components/account/releases/FormFilterUpcomingReleases';
import AppNotFound from '@/components/shared/NotFound';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import PaymentsApi from '@/services/PaymentsApi';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppUpcomingReleases',
  components: {
    AppModalBalanceTypes,
    AppHistoric,
    AppFormFilterUpcomingReleases,
    AppNotFound,
    WhiteLabel,
  },
  data() {
    return {
      filter: null,
      balance: 0,
      recentReleases: null,
      allLoaded: false,
      renderHistoricComponent: true,
      showBalance: false,
    };
  },
  async created() {
    this.$bus.$emit('spinner-run');
    await this.getBalanceAccount();
    this.$bus.$emit('spinner-stop');
  },
  methods: {
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },
    async newRequest(filter) {
      this.filter = filter;
      this.filter.params.index = 0;
      this.allLoaded = false;

      await this.getReleasesAccount(filter);

      // zerando componente Historic
      this.renderHistoricComponent = false;
      this.$nextTick(() => {
        this.renderHistoricComponent = true;
      });
    },
    async getReleasesAccount(filter) {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      const newValue = await api.getReleases(this.filter.params);
      this.allLoaded = newValue.count == 0;
      if (this.filter.params.index === 0) {
        this.recentReleases = newValue;
      } else {
        this.concatReleases(newValue);
      }
      this.$bus.$emit('spinner-stop');
    },
    concatReleases(newRelease) {
      if (this.recentReleases) {
        newRelease.days.forEach((newDay) => {
          const day = this.recentReleases.days.find((x) => x.day == newDay.day);
          if (day != undefined) {
            newDay.transactions.forEach((newTransaction) => {
              const transaction = day.transactions.find(
                (x) => x.type == newTransaction.type
              );
              if (transaction != undefined) {
                transaction.amount += newTransaction.amount;
                transaction.count += newTransaction.count;
              } else {
                day.transactions = day.transactions.concat(newDay.transactions);
              }
            });
            day.amount += newDay.amount;
          } else {
            this.recentReleases.days = this.recentReleases.days.concat(newDay);
          }
        });
        this.recentReleases.count = this.recentReleases.days.length;
      } else {
        this.recentReleases = newRelease;
      }
    },

    async loadMore() {
      this.filter.params.index += this.filter.params.length;
      await this.getReleasesAccount(this.filter);
    },
  },
  computed: {
    getBalance() {
      return this.balance;
    },
    isAndroid: function () {
      return navigator.userAgent.match(/Android/i);
    },
    isIOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    isMobile: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
    },
  },
};
</script>
