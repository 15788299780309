<template>
  <div class="color-button">
    <StyledButton
      type="button"
      @click="handleColorChoose"
      :color="color"
    ></StyledButton>
    <label @click="isSelected = !isSelected">{{ label }}</label>
    <template v-if="isSelected == true">
      <chrome-picker v-model="colors" @input="updateColor" />
    </template>
  </div>
</template>
<script>
import styled from 'vue-styled-components';

const buttonProps = {
  color: String,
};

const StyledButton = styled('button', buttonProps)`
  background-color: ${(props) => props.color};
`;

export default {
  name: 'ColorButton',
  components: {
    StyledButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      default: () => 1,
    },
    disabled: Boolean,
  },
  data() {
    return {
      colors: '',
      isSelected: false,
      buttonStyle: {
        background: this.color || 'black',
      },
    };
  },
  methods: {
    updateColor(newColor) {
      this.buttonStyle.background = newColor.hex;

      // eslint-disable-next-line valid-typeof
      if (typeof this.onChange !== undefined) {
        this.onChange(newColor.hex);
      }
    },
    close() {
      this.isSelected = false;
    },
    open() {
      this.isSelected = true;
    },
    handleColorChoose() {
      if (this.disabled) return;

      this.isSelected = !this.isSelected;
    },
  },
};
</script>
<style>
.color-button {
  display: inline-block;
  margin-right: 15px;
}
.color-button button {
  padding: 15px;
  border-radius: 50%;
  outline: none;
}
.color-button label {
  margin-left: 15px;
  vertical-align: bottom;
  cursor: pointer;
}
.color-button .vc-chrome {
  position: absolute;
  z-index: 999;
}
</style>
