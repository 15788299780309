var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.historic.days),function(period,indexPeriod){return _c('section',{key:indexPeriod},[_c('div',{staticClass:"header-release"},[(period.day)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("moment")(period.day,'ddd, DD/MM/YYYY')))]):_vm._e(),_c('div',[_c('span',{class:[period.amount < 0 ? 'text-red' : 'text-color-green']},[_vm._v(" "+_vm._s(_vm._f("currency")(period.amount,'R$ ', 2, { decimalSeparator: ',', thousandsSeparator: '.', }))+" ")])])]),_c('div',{staticClass:"content-release"},_vm._l((period.transactions),function(release,indexRelease){return _c('section',{key:indexRelease,staticClass:"pt-2"},[_c('div',{staticClass:"header-collapse",attrs:{"data-toggle":!_vm.detailsData ? 'collapse' : false,"href":("#" + indexPeriod + indexRelease),"role":"button","aria-expanded":"false","aria-controls":("" + indexPeriod + indexRelease)},on:{"click":function($event){!_vm.detailsData
              ? _vm.getDetails(period, indexPeriod, indexRelease, release.type)
              : null}}},[_c('div',{staticClass:"types"},[_c('AppIconHistoric',{attrs:{"type":release.type,"count":release.count}})],1),_c('div',{staticClass:"value-release inline-flex"},[_c('span',{class:[
                release.amount < 0 || release.type === 'canceled'
                  ? 'text-red'
                  : 'text-color-green' ]},[_vm._v(_vm._s(release.type === 'canceled' ? '-' : '')+_vm._s(_vm._f("currency")(release.amount,'R$ ', 2, { decimalSeparator: ',', thousandsSeparator: '.', })))]),(!_vm.detailsData)?_c('em',{staticClass:"icon-arrow-down",attrs:{"id":("icon-" + indexPeriod + indexRelease)}}):_vm._e()])]),_c('div',{attrs:{"id":("" + indexPeriod + indexRelease)}},[_c('div',_vm._l((_vm.getIndex(
                indexPeriod,
                indexRelease
              )),function(operation,indice){return _c('div',{key:indice,staticClass:"details-release"},[_c('div',[_c('a',{staticClass:"btn-transference",attrs:{"data-toggle":'modal',"data-target":'#' + _vm.modalreleasedetails},on:{"click":function($event){return _vm.setTransactionOpened(operation)}}},[_c('div',{staticClass:"line1"},[_c('span',[_vm._v(_vm._s(_vm.description(operation, release.type)))]),_c('span',{staticClass:"value",class:[
                        operation.amount < 0 || operation.type === 'canceled'
                          ? 'text-red'
                          : 'text-color-green' ]},[_vm._v(_vm._s(operation.type === 'canceled' ? '-' : '')+_vm._s(_vm._f("currency")(operation.amount,'R$ ', 2, { decimalSeparator: ',', thousandsSeparator: '.', })))])]),_c('div',{staticClass:"line2"},[_c('span'),_c('span',[_vm._v(_vm._s(operation.datetime))])])])]),(
                  _vm.getLastItem(indexPeriod, indexRelease) === operation &&
                  _vm.showMore(indexPeriod, indexRelease)
                )?_c('div',{staticClass:"line2"},[_c('button',{staticClass:"btn btn-orange btn-load",attrs:{"type":"submit"},on:{"click":function($event){return _vm.getDetails(
                      period,
                      indexPeriod,
                      indexRelease,
                      release.type
                    )}}},[_vm._v(" Carregar mais ")])]):_vm._e()])}),0)])])}),0)])}),_c('AppModalReleaseDetails',{attrs:{"id":_vm.modalreleasedetails,"transaction":_vm.transactionOpened,"origin":_vm.view},on:{"open-transaction":function($event){return _vm.openOriginalPixDebitTransaction($event)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }