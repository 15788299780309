import moment from 'moment';

export default class UpcomingReleasesFilters {
  startDate = moment().format('YYYY-MM-DD');
  endDate = moment()
    .add(30, 'days')
    .format('YYYY-MM-DD');
  index = 0;
  length = 50;
}
