<template>
  <div>
    <div class="header-box pt-4 pb-4 mb-4">
      <div class="row mx-0 justify-content-center">
        <div class="align-self-center text-center mb-0">
          <img
            :src="logo.display"
            class="top-logo mb-2"
            v-if="logo.display !== '' && !cropping"
          />
          <div
            class="top-logo mb-2 mr-auto ml-auto"
            v-if="logo.display === '' || cropping"
          >
            <h1 class="mt-3">
              <i
                class
                :class="
                  preset.holder.company === null
                    ? 'icon-user'
                    : 'icon-briefcase'
                "
              ></i>
            </h1>
          </div>
          <h3 class="font-weight-light mb-2">
            {{ preset.account.commercialName }}
          </h3>
          <h6
            class="font-weight-light mb-0"
            v-if="preset.holder.company !== null"
          >
            {{ preset.holder.company.fullName }}
          </h6>
          <h6
            class="font-weight-light mb-0"
            v-if="preset.holder.company === null"
          >
            {{ preset.holder.fullName }}
          </h6>
        </div>
      </div>
    </div>
    <div class="container">
      <form action="#">
        <div class="d-flex mb-3">
          <div class="ml-auto">
            <WhiteLabel>
              <a href="#" @click="$router.go(-1)" class="pull-right mt-2 mr-4">
                <i class="icon-arrow-left-circle"></i> Voltar
              </a>
            </WhiteLabel>
          </div>
          <div>
            <WhiteLabel>
              <button
                class="btn btn-orange"
                id="action"
                @click.prevent="isDisabled = !isDisabled"
                v-if="isDisabled && this.whoAmI.accessedByHolder"
              >
                <i class="icon-pencil"></i>
                Editar campos
              </button>
            </WhiteLabel>
          </div>
          <div>
            <WhiteLabel>
              <button
                class="btn btn-orange"
                id="action"
                @click.prevent="save"
                v-if="!isDisabled"
                :disabled="$v.$invalid"
              >
                Salvar alterações
              </button>
            </WhiteLabel>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-w-full">
            <div class="card">
              <div class="card-content">
                <h3 class="font-weight-light ml-3">Comunicação com clientes</h3>
                <hr />
                <div class="form-row mb-4">
                  <div class="col-6">
                    <div class="col-12 col-w-full">
                      <WhiteLabel>
                        <h4 class="font-weight-light text-orange">Seu logo</h4>
                      </WhiteLabel>
                      <div class="row justify-content-center">
                        <div class="col-6" align="center">
                          <div
                            class="
                              no-logo
                              rounded
                              mb-4
                              row
                              justify-content-center
                            "
                            v-if="logo.display === '' && !cropping"
                          >
                            <div class="d-flex" v-if="isDisabledLogo">
                              <h3 class="m-auto">
                                <i class="icon-picture text-muted"></i>
                              </h3>
                            </div>
                            <div class="d-flex" v-if="!isDisabledLogo">
                              <label class="m-auto text-center first-logo">
                                <h3 class="mb-1">
                                  <WhiteLabel>
                                    <i
                                      class="icon-cloud-upload text-orange"
                                    ></i>
                                  </WhiteLabel>
                                </h3>
                                <h5 class="mb-0 text-orange">ENVIAR IMAGEM</h5>
                                <small class="mb-0 font-weight-light"
                                  >JPG, PNG ou GIF de até 200kb</small
                                >
                                <input
                                  type="file"
                                  id="file"
                                  accept="image/*"
                                  ref="front"
                                  @change="changeLogo()"
                                  :disabled="isDisabledLogo"
                                />
                              </label>
                            </div>
                          </div>
                          <img
                            :src="logo.display"
                            class="rounded logo mb-2"
                            v-if="logo.display !== '' && !cropping"
                          />
                          <div class="logo-cropper" v-if="cropping">
                            <vue-cropper
                              ref="cropper"
                              :guides="true"
                              :aspectRatio="1"
                              :view-mode="0"
                              drag-mode="crop"
                              :background="true"
                              :src="logo.display"
                              alt="Source Image"
                              :img-style="{ width: '200px', height: '200px' }"
                            ></vue-cropper>
                          </div>

                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div
                          class="text-center"
                          :class="cropping ? 'col-5' : 'col-3'"
                        >
                          <big>
                            <i
                              class="icon-action-undo mr-2"
                              @click.prevent="undoLogoChange()"
                              v-show="cropping || logo.changedLogo"
                            ></i>
                          </big>
                          <a
                            href="#"
                            class="mr-2 text-orange"
                            @click.prevent="cropImage"
                            v-show="cropping"
                          >
                            <big>
                              <i class="icon-crop text-orange"></i>
                            </big>
                            Recortar
                          </a>
                          <label v-if="logo.display !== '' && !cropping">
                            <big>
                              <WhiteLabel v-if="!isDisabledLogo">
                                <i
                                  class="icon-cloud-upload mr-2"
                                  :class="!isDisabledLogo ? 'text-orange' : ''"
                                ></i>
                              </WhiteLabel>
                              <i
                                v-if="isDisabledLogo"
                                class="icon-cloud-upload mr-2"
                              ></i>
                            </big>
                            <input
                              type="file"
                              id="file"
                              accept="image/*"
                              ref="front"
                              @change="changeLogo()"
                              :disabled="isDisabledLogo"
                            />
                          </label>
                          <big>
                            <i
                              class="icon-close"
                              v-if="
                                logo.display !== '' && !cropping && isDisabledLogo
                              "
                            ></i>
                          </big>
                          <big>
                            <i
                              class="icon-close text-danger"
                              @click.prevent="deleteLogo"
                              v-if="
                                logo.display !== '' && !cropping && !isDisabledLogo
                              "
                            ></i>
                          </big>
                        </div>
                      </div>

                      <div class="row justify-content-center mb-2">
                        <div>
                          <WhiteLabel>
                            <button class="btn btn-orange" id="action" @click.prevent="isDisabledLogo = !isDisabledLogo" v-if="isDisabledLogo && this.whoAmI.accessedByHolder">
                              <i class="icon-pencil"></i>
                              Editar
                            </button>
                          </WhiteLabel>
                        </div>
                        <div>
                          <WhiteLabel>
                            <button class="btn btn-orange" id="action" @click.prevent="updateLogo" v-if="!isDisabledLogo" :disabled="$v.$invalid">
                              Salvar
                            </button>
                          </WhiteLabel>
                        </div>
                      </div>
                      <small class="text-muted" v-if="!imageUploadError"
                        >Envie uma imagem que contenha a logo de sua empresa
                        preferencialmente com fundo branco ou transparente para
                        melhor aplicação em documentos</small
                      >
                      <p
                        class="text-danger text-center"
                        v-if="imageUploadError"
                      >
                        A imagem que você enviou é muito pequena.
                        <br />Tente enviar outra de pelo menos 200px de largura
                        e altura.
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="col-12 col-w-full">
                      <WhiteLabel>
                        <h4 class="font-weight-light text-orange">
                          Identificação em faturas e lembretes de pagamento
                        </h4>
                      </WhiteLabel>
                    </div>
                    <div class="form-group col-12 col-w-full mb-4">
                      <label>Nome comercial</label>
                      <input
                        class="form-control"
                        type="text"
                        :readonly="isDisabled"
                        maxlength="250"
                        @input="$v.$touch()"
                        v-model="preset.account.commercialName"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          preset.account.comercialName === null ||
                          preset.account.comercialName.length === 0
                        "
                        >Se deixar este campo em branco, ele será substituído
                        pelo nome completo do titular.</small
                      >
                      <small class="text-muted" v-else
                        >Se deixar este campo em branco, ele será substituído
                        pelo nome completo do titular.</small
                      >
                    </div>

                    <div class="form-group col-12 col-w-full">
                      <label>Identificador para faturas de cartão</label>
                      <div class="col-12 col-w-full col-md-6 p-0">
                        <input
                          class="form-control"
                          type="text"
                          v-model="preset.account.softDescriptor"
                          :readonly="isDisabled"
                          maxlength="13"
                          @input="$v.$touch()"
                        />
                      </div>
                      <small
                        class="text-muted"
                        v-if="!$v.preset.account.softDescriptor.$error"
                        >Permitido somente letras.</small
                      >
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.account.softDescriptor.required &&
                          $v.preset.account.softDescriptor.$dirty
                        "
                        >Campo obrigatório</small
                      >
                      <small
                        class="text-danger"
                        v-if="!$v.preset.account.softDescriptor.softDescriptor"
                        >Permitido somente letras.</small
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="form-row d-flex mb-3 client-comunication-box rounded"
                >
                  <div class="d-flex text-center">
                    <span class="safe-notification-icon image"></span>
                  </div>
                  <div class="col-11 ml-2 d-flex">
                    <p class="font-weight-light m-auto">
                      Usar logotipo, nome comercial e softdescriptor que
                      identifiquem bem seu negócio ajuda seus clientes a
                      reconhecerem todas as transações que você realizou para
                      eles, evitando contestações e suspeitas de fraude.
                    </p>
                  </div>
                </div>
                <hr :class="logo.display !== '' && !cropping ? 'mb-3' : ''" />
                <div
                  class="form-row justify-content-center"
                  v-if="logo.display !== '' && !cropping"
                >
                  <div class="col-6 mb-2 text-center">
                    <WhiteLabel>
                      <a
                        href="#"
                        class="mb-4"
                        @click.prevent="previewLogo"
                        :disabled="isDisabled"
                        >Visualizar email de notificação</a
                      >
                    </WhiteLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-w-full col-lg-6">
            <div
              class="card"
              v-if="!isOriginPartner || (isAccountHolder && !isAccountPartner)"
            >
              <h3 class="font-weight-light ml-3">Dados Cadastrais</h3>
              <hr />
              <div class="card-content">
                <div class="form-row">
                  <div class="col-12 col-w-full">
                    <WhiteLabel>
                      <h4 class="font-weight-light text-orange">
                        Dados do Titular
                      </h4>
                    </WhiteLabel>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12 col-w-full col-md-7 mb-3">
                    <label>E-mail:</label>
                    <input
                      class="form-control"
                      type="email"
                      :readonly="isDisabled"
                      @input="$v.$touch()"
                      v-model="preset.holder.email"
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-md-5 mb-3">
                    <label>CPF:</label>
                    <p class="mb-0">{{ preset.holder.taxDocument }}</p>
                  </div>
                </div>
                <div class="form-row mb-5">
                  <div class="form-group col-12 col-w-full col-md-7">
                    <label>Celular:</label>
                    <input
                      class="form-control"
                      type="telefone"
                      :readonly="isDisabled"
                      v-mask="'(##) #####-####'"
                      @input="$v.$touch()"
                      v-model="preset.holder.mobile"
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-md-5">
                    <label>Telefone Fixo:</label>
                    <input
                      class="form-control"
                      type="telefone"
                      :readonly="isDisabled"
                      v-mask="'(##) ####-####'"
                      @input="$v.$touch()"
                      v-model="preset.holder.phone"
                    />
                  </div>
                </div>
                <WhiteLabel>
                  <h4
                    class="font-weight-light text-orange mt-4"
                    v-if="preset.holder.company !== null"
                  >
                    Dados da empresa
                  </h4>
                </WhiteLabel>
                <div
                  class="form-row mb-4"
                  v-if="preset.holder.company !== null"
                >
                  <div class="form-group col-12 col-w-full col-md-7 mb-3">
                    <label>Nome da empresa:</label>
                    <p class="mb-0">{{ preset.holder.company.fullName }}</p>
                  </div>
                  <div class="form-group col-12 col-w-full col-md-5 mb-3">
                    <label>CNPJ:</label>
                    <p class="mb-0">{{ preset.holder.company.taxDocument }}</p>
                  </div>
                </div>
                <div
                  v-if="
                    !isOriginPartner || (isAccountHolder && !isAccountPartner)
                  "
                >
                  <div class="form-row mb-4">
                    <div class="col-12 col-w-full">
                      <WhiteLabel>
                        <h4 class="font-weight-light text-orange">Endereço</h4>
                      </WhiteLabel>
                    </div>
                    <div class="form-group col-6">
                      <label>CEP:</label>
                      <input
                        class="form-control"
                        type="text"
                        v-mask="'#####-###'"
                        @change="searchZipCode"
                        v-model="preset.address.zipCode"
                        :readonly="isDisabled"
                        @input="$v.$touch()"
                        maxlength="9"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.address.zipCode.required &&
                          $v.preset.address.zipCode.$dirty
                        "
                        >Campo obrigatório</small
                      >
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-10
                        mb-4 mb-md-0
                      "
                    >
                      <label>Rua/Avenida/Logradouro:</label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="preset.address.line1"
                        :readonly="isDisabled"
                        @input="$v.$touch()"
                        maxlength="150"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.address.line1.required &&
                          $v.preset.address.line1.$dirty
                        "
                        >Campo obrigatório</small
                      >
                      <small
                        class="text-danger"
                        v-if="!$v.preset.address.line1.address"
                        >O campo está inválido</small
                      >
                    </div>
                    <div class="form-group col-4 col-md-2">
                      <label>Número:</label>
                      <div class="col-12 col-w-full p-0">
                        <input
                          class="form-control text-center"
                          type="text"
                          v-model="preset.address.streetNumber"
                          :readonly="isDisabled"
                          @input="$v.$touch()"
                          maxlength="20"
                        />
                        <small
                          class="text-danger"
                          v-if="
                            !$v.preset.address.streetNumber.required &&
                            $v.preset.address.streetNumber.$dirty
                          "
                          >Campo obrigatório</small
                        >
                        <small
                          class="text-danger"
                          v-if="!$v.preset.address.streetNumber.alphaNumeric"
                          >O campo está inválido</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div
                      class="form-group col-12 col-w-full col-md-7 mb-4 mb-md-0"
                    >
                      <label>Bairro:</label>
                      <div class="col-12 col-w-full p-0">
                        <input
                          class="form-control"
                          type="text"
                          v-model="preset.address.district"
                          :readonly="isDisabled"
                          @input="$v.$touch()"
                          maxlength="50"
                        />
                        <small
                          class="text-danger"
                          v-if="
                            !$v.preset.address.district.required &&
                            $v.preset.address.district.$dirty
                          "
                          >Campo obrigatório</small
                        >
                        <small
                          class="text-danger"
                          v-if="!$v.preset.address.district.district"
                          >O campo está inválido</small
                        >
                      </div>
                    </div>
                    <div class="form-group col-12 col-w-full col-md-5">
                      <label>Complemento:</label>
                      <div class="col-12 col-w-full p-0">
                        <input
                          class="form-control"
                          type="text"
                          v-model="preset.address.line2"
                          :readonly="isDisabled"
                          maxlength="80"
                          @input="$v.$touch()"
                        />
                        <small
                          class="text-danger"
                          v-if="!$v.preset.address.line2.alphaNumeric"
                          >O campo está inválido</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    !isOriginPartner || (isAccountHolder && !isAccountPartner)
                  "
                >
                  <div class="form-row mb-4">
                    <div class="col-12 col-w-full">
                      <WhiteLabel>
                        <h4 class="font-weight-light text-orange">Contatos</h4>
                      </WhiteLabel>
                    </div>
                    <div class="form-group col-12">
                      <label>Site:</label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="preset.holder.contact.site"
                        :readonly="isDisabled"
                        @input="$v.$touch()"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          $v.preset.holder.contact.site.$invalid &&
                          $v.preset.holder.contact.site.$dirty
                        "
                        >Site inválido</small
                      >
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-10
                        mb-4 mb-md-0
                      "
                    >
                      <label>Endereço:</label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="preset.holder.contact.address"
                        :readonly="isDisabled"
                        @input="$v.$touch()"
                        maxlength="300"
                      />
                    </div>
                    <div class="form-group col-4 col-md-2">
                      <label>Ramal:</label>
                      <div class="col-12 col-w-full p-0">
                        <input
                          class="form-control text-center"
                          type="text"
                          v-model="preset.holder.contact.phoneExtension"
                          :readonly="isDisabled"
                          @input="$v.$touch()"
                          maxlength="4"
                        />
                        <small
                          class="text-danger"
                          v-if="
                            $v.preset.holder.contact.phoneExtension.$invalid &&
                            $v.preset.holder.contact.phoneExtension.$dirty
                          "
                          >Somente números</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div
                      class="form-group col-12 col-w-full col-md-7 mb-4 mb-md-0"
                    >
                      <label>Celular:</label>
                      <input
                        class="form-control"
                        type="telefone"
                        :readonly="isDisabled"
                        v-mask="'(##) #####-####'"
                        @input="$v.$touch()"
                        v-model="preset.holder.contact.mobile"
                      />
                    </div>
                    <div class="form-group col-12 col-w-full col-md-5">
                      <label>Telefone Fixo:</label>
                      <input
                        class="form-control"
                        type="telefone"
                        :readonly="isDisabled"
                        v-mask="'(##) ####-####'"
                        @input="$v.$touch()"
                        v-model="preset.holder.contact.phone"
                      />
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div
                      class="form-group col-12 col-w-full col-md-7 mb-4 mb-md-0"
                    >
                      <label>Email:</label>
                      <input
                        class="form-control"
                        type="text"
                        :readonly="isDisabled"
                        @input="$v.$touch()"
                        v-model="preset.holder.contact.email"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.holder.contact.email.required &&
                          $v.preset.holder.contact.email.$dirty
                        "
                        >Campo obrigatório</small
                      >
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.holder.contact.email.email &&
                          $v.preset.holder.contact.email.$dirty
                        "
                        >Email inválido</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-w-full col-lg-6">
            <div
              class="card"
              v-if="
                this.isAccountPartner ||
                (this.isAccountHolder && !this.isOriginPartner)
              "
            >
              <h3 class="font-weight-light ml-3">Plano de recebimento</h3>
              <hr />
              <div class="card-content">
                <div class="form-row">
                  <div class="col-12 col-w-full">
                    <WhiteLabel>
                      <h4 class="font-weight-light text-orange">
                        Transações no cartão de crédito:
                      </h4>
                    </WhiteLabel>
                  </div>
                </div>
                <div class="form-group mb-4">
                  <label
                    class="radio-selector mb-2 w-100 d-flex"
                    v-if="
                      preset.account.transferPlan.oldPlan && oldPlanType === '1'
                    "
                  >
                    <WhiteLabel>
                      <input
                        type="radio"
                        name=" "
                        v-model="plan"
                        value
                        :disabled="isDisabled"
                      />

                      <div class="checkbox-badge mr-3"></div>
                    </WhiteLabel>
                    <div class="d-flow">
                      <h4 class="mb-0">
                        Sem antecipação automática (descontinuado)
                      </h4>
                      <small>
                        Receba a 1ª parcela em 32 dias e as demais a cada 30
                        dias.
                        <br />Você também pode solicitar antecipação de
                        transações quando necessário.
                      </small>
                      <br />
                    </div>
                  </label>
                  <label
                    class="radio-selector mb-2 w-100 d-flex"
                    v-if="
                      preset.account.transferPlan.oldPlan && oldPlanType === '2'
                    "
                  >
                    <input
                      type="radio"
                      name=" "
                      v-model="plan"
                      value
                      :disabled="isDisabled"
                    />
                    <div class="checkbox-badge mr-3"></div>
                    <div class="d-flow">
                      <h4 class="mb-0">
                        Antecipação automática - sexta-feira (descontinuado)
                      </h4>
                      <small
                        >Receba todas as sextas as transações realizadas até a
                        quarta-feira da mesma semana</small
                      >
                      <br />
                    </div>
                  </label>
                  <label class="radio-selector mb-2 w-100 d-flex">
                    <WhiteLabel>
                      <input
                        type="radio"
                        name=" "
                        v-model="plan"
                        value="1"
                        :disabled="isDisabled"
                      />
                      <div
                        class="checkbox-badge mr-3"
                        :disabled="isDisabled"
                      ></div>
                    </WhiteLabel>
                    <div class="d-flow">
                      <h4 class="mb-0">Sem antecipação automática</h4>
                      <small>
                        Receba a 1ª parcela em 32 dias e as demais a cada 30
                        dias.
                        <br />Você também pode solicitar antecipação de
                        transações quando necessário.
                      </small>
                      <br />
                    </div>
                  </label>
                  <label class="radio-selector mb-2 w-100 d-flex">
                    <WhiteLabel>
                      <input
                        type="radio"
                        name=" "
                        v-model="plan"
                        value="3"
                        :disabled="isDisabled"
                      />
                      <div
                        class="checkbox-badge mr-3"
                        :disabled="isDisabled"
                      ></div>
                    </WhiteLabel>
                    <div class="d-flow">
                      <h4 class="mb-0">Antecipação automática em 32 dias</h4>
                      <small>Receba as transações após 32 dias.</small>
                      <br />
                    </div>
                  </label>
                  <div
                    class="small-paragraph text-muted mb-4"
                    v-if="preset.account.transferPlan.oldPlan"
                  >
                    <div class="mb-2">
                      <small class="text-danger"
                        >IMPORTANTE: Seu plano atual foi descontinuado, caso
                        você troque para outro não será mais possível
                        retornar.</small
                      >
                      <br />
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-w-full">
                    <WhiteLabel>
                      <h4 class="font-weight-light text-orange">
                        Transações no cartão de débito:
                      </h4>
                    </WhiteLabel>
                  </div>
                </div>
                <div class="form-group mb-4">
                  <p class="mb-0">
                    Receber em 2 dias corridos após o pagamento
                  </p>
                </div>
                <div class="form-row">
                  <div class="col-12 col-w-full">
                    <WhiteLabel>
                      <h4 class="font-weight-light text-orange">
                        Transações no boleto:
                      </h4>
                    </WhiteLabel>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <p class="mb-0">Receber em 2 dias úteis após o pagamento</p>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="d-flex">
                <h3 class="font-weight-light ml-3 pull-left">
                  Dados bancários
                </h3>
                <div class="pull-right ml-auto mr-3">
                  <label class="switch switch-danger switch-to-success">
                    <input
                      type="checkbox"
                      @click="disableBankAccount"
                      :checked="bankAccountEnabled"
                    />
                    <span class="switch-slider"></span>
                  </label>
                </div>
              </div>
              <hr />
              <div class="card-content">
                <div v-if="bankAccountEnabled">
                  <div v-if="!newBankAccount && isDisabled">
                    <div class="form-group">
                      <p>
                        {{ preset.bankAccount.holderFullName }} |
                        {{ preset.bankAccount.holderTaxDocument }}
                      </p>
                    </div>
                  </div>
                  <div v-if="newBankAccount || !isDisabled">
                    <div
                      class="form-group"
                      v-if="preset.holder.company !== null"
                    >
                      <div class="form-row mb-4">
                        <div class="col-12 col-w-full">
                          <label
                            >Quem é o titular da conta que irá receber os
                            repasses?</label
                          >
                          <select
                            name="document-type"
                            class="form-control"
                            :disabled="isDisabled"
                            v-model="preset.bankAccount.holderTaxDocument"
                          >
                            <option
                              v-if="bankAccountOwnerTaxDocument !== ''"
                              :value="bankAccountOwnerTaxDocument"
                              :disabled="changedBankAccount"
                            >
                              {{ bankAccountOwnerName }} |
                              {{ bankAccountOwnerTaxDocument }}
                            </option>
                            <option :value="preset.holder.taxDocument">
                              {{ preset.holder.fullName }} |
                              {{ preset.holder.taxDocument }}
                            </option>
                            <option
                              v-if="preset.holder.company !== null"
                              :value="preset.holder.company.taxDocument"
                            >
                              {{ preset.holder.company.fullName }} |
                              {{ preset.holder.company.taxDocument }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div class="form-group col-8">
                      <label>Banco:</label>
                      <div class="col-12 col-w-full p-0">
                        <select
                          class="form-control"
                          :disabled="
                            isDisabled ||
                            (!changedBankAccount && differentBankAccountOwner)
                          "
                          v-model="preset.bankAccount.bankNumber"
                        >
                          <option
                            v-for="bank in listBanks.banks"
                            :key="bank.number"
                            :value="bank.number"
                          >
                            {{ bank.number }} - {{ bank.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-4">
                      <label>Tipo:</label>
                      <div class="col-12 col-w-full p-0">
                        <select
                          class="form-control"
                          :disabled="
                            isDisabled ||
                            (!changedBankAccount && differentBankAccountOwner)
                          "
                          v-model="preset.bankAccount.type"
                        >
                          <option value="corrente">Conta Pagamento</option>
                          <option value="corrente">Corrente</option>
                          <option value="poupanca">Poupança</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row mb-4">
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-5 col-lg-5 col-xl-5 col-lg-5
                        mb-4 mb-md-0
                      "
                    >
                      <label>Agência:</label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="preset.bankAccount.bankBranchNumber"
                        :readonly="
                          isDisabled ||
                          (!changedBankAccount && differentBankAccountOwner)
                        "
                        maxlength="20"
                        v-mask="['####', '####-X']"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.bankAccount.bankBranchNumber.required &&
                          $v.preset.bankAccount.bankBranchNumber.$dirty
                        "
                        >Campo obrigatório</small
                      >
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.bankAccount.bankBranchNumber.bankBranch
                        "
                        >O campo está inválido</small
                      >
                    </div>
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-5 col-lg-5 col-xl-5
                        mb-4 mb-md-0
                      "
                    >
                      <label>Nº da conta:</label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="preset.bankAccount.accountNumber"
                        :readonly="
                          isDisabled ||
                          (!changedBankAccount && differentBankAccountOwner)
                        "
                        maxlength="30"
                        v-mask="[
                          '#############-X',
                          '############-X',
                          '###########-X',
                          '##########-X',
                          '#########-X',
                          '########-X',
                          '#######-X',
                          '######-X',
                          '#####-X',
                        ]"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.bankAccount.accountNumber.required &&
                          $v.preset.bankAccount.accountNumber.$dirty
                        "
                        >Campo obrigatório</small
                      >
                      <small
                        class="text-danger"
                        v-if="
                          !$v.preset.bankAccount.accountNumber.bankAccountNumber
                        "
                        >O campo está inválido</small
                      >
                    </div>
                    <div class="form-group col-6 col-md-2 col-lg-2">
                      <label>Variação:</label>
                      <input
                        class="form-control text-center"
                        type="text"
                        v-model="preset.bankAccount.variation"
                        :readonly="
                          isDisabled ||
                          (!changedBankAccount && differentBankAccountOwner)
                        "
                        maxlength="8"
                      />
                      <small
                        class="text-danger"
                        v-if="!$v.preset.bankAccount.variation.onlyNumbers"
                        >Permitido somente números</small
                      >
                    </div>
                    <div class="col-12 col-w-full mt-1 small-paragraph">
                      <small
                        class="text-muted"
                        v-if="
                          (preset.bankAccount.variation === null ||
                            preset.bankAccount.variation === '') &&
                          preset.bankAccount.type === 'poupanca'
                        "
                        >Caso não possua número de variação, pode deixar em
                        branco, ok?</small
                      >
                    </div>
                    <div
                      class="col-12 col-w-full mt-3 small-paragraph"
                      v-if="differentBankAccountOwner"
                    >
                      <small class="text-danger mb-2"
                        >IMPORTANTE: para sua segurança só é permitido receber
                        repasses na conta bancária do titular, da empresa ou de
                        um representante legal.</small
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h5>Dados bancários não cadastrados</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert2';
import HolderError from '@/errors/HolderError';
import AccountApi from '@/services/AccountApi';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import ZipCodeApi from '@/services/ZipCodeApi';
import { Presets, Address, PresetParams } from '@/models/Presets';
import { address, district } from '@/validations/address';
import { alphaNumeric } from '@/validations/alphaNumeric';
import { bankAccountNumber } from '@/validations/bankAccountNumber';
import { bankBranch } from '@/validations/bankBranch';
import { onlyLetters } from '@/validations/onlyLetters';
import { onlyNumbers } from '@/validations/onlyNumbers';
import { softDescriptor } from '@/validations/softDescriptor';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import Alert from '@/models/Alert';
import VueCropper from 'vue-cropperjs';
import { url } from '@/validations/url';
import WhiteLabel from '@/components/shared/WhiteLabel';
import TwoFactorAuthError from '@/errors/TwoFactorAuthError';
import AuthCodeError from '@/errors/AuthCodeError';

export default {
  name: 'AppHolder',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.presets();
    await this.banks();
    this.mobile = this.preset.holder.mobile;
    this.email = this.preset.holder.email;
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      isDisabled: true,
      isDisabledLogo: true,
      preset: new Presets(),
      logo: {
        display: '',
        image: '',
        changedLogo: false,
      },
      cropping: false,
      imageUploadError: false,
      cropped: null,
      plan: '',
      oldPlanType: '',
      migrate: false,
      password: '',
      listBanks: [],
      bankAccountOwnerName: '',
      bankAccountOwnerTaxDocument: '',
      changedBankAccount: false,
      token: null,
      twoFactorAuthTaxDocument: null,
      twoFactorAuthPassword: null,
      twoFactorDocumentRegex: new RegExp('^[0-9]+$'),
      bankAccountEnabled: false,
      authCode: null,
      newBankAccount: false,
      authCodeRegex: new RegExp('^[0-9]+$'),
      mobile: '',
      email: '',
    };
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      loadBankAccount: 'account/loadBankAccount',
    }),
    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
      this.preset.address.cityCode = this.preset.address.city.code;
      this.bankAccountEnabled = this.preset.bankAccount != null;

      if (this.bankAccountEnabled) {
        if (this.differentBankAccountOwner) {
          this.bankAccountOwnerName = this.preset.bankAccount.holderFullName;
          this.bankAccountOwnerTaxDocument =
            this.preset.bankAccount.holderTaxDocument;
        }
      }

      if (this.preset.account.transferPlan.oldPlan) {
        if (this.preset.account.transferPlan.anticipated === false) {
          this.oldPlanType = '1';
        } else if (this.preset.account.transferPlan.anticipated === true) {
          this.oldPlanType = '2';
        }
      }
      if (!this.preset.account.transferPlan.oldPlan) {
        if (
          this.preset.account.transferPlan.anticipated === false &&
          this.preset.account.transferPlan.daysPinpad === 32
        ) {
          this.plan = '1';
        } else if (
          this.preset.account.transferPlan.anticipated === true &&
          this.preset.account.transferPlan.daysPinpad === 2
        ) {
          this.plan = '2';
        } else if (
          this.preset.account.transferPlan.anticipated === true &&
          this.preset.account.transferPlan.daysPinpad === 32
        ) {
          this.plan = '3';
        }
      }

      await api.findLogo().then((response) => {
        if (response.status === 422) {
        } else {
          this.logo.image = new File([response.data], {
            type: 'image/png',
          });
          this.logo.display = URL.createObjectURL(this.logo.image);
        }
      });
    },
    changeLogo() {
      var image = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        image.src = e.target.result;
        this.$refs.cropper.replace(e.target.result);
      };
      reader.readAsDataURL(this.$refs.front.files[0]);
      var imageFile = this.$refs.front.files[0];

      var _this = this;
      image.onload = function () {
        if (this.width < 200 || this.height < 200) {
          _this.logo.changedLogo = false;
          _this.cropping = false;
          _this.imageUploadError = true;
        } else {
          _this.imageUploadError = false;
          _this.cropping = true;
          _this.logo.changedLogo = true;
          _this.logo.display = image.src;
          _this.logo.image = imageFile;
        }
      };
      document.getElementById('file').value = '';
    },
    deleteLogo() {
      this.$alert.question(
        'Excluir logotipo',
        'Deseja realmente excluir seu logotipo?'
      );
      this.$alert.onConfirm = async () => {
        this.$bus.$emit('spinner-run');
        this.logo.display = '';
        this.logo.image = '';
        this.logo.changedLogo = true;
        this.$bus.$emit('spinner-stop');
      };
    },
    previewLogo() {
      var previewData = new Alert();
      previewData.image = this.logo.display;
      previewData.name =
        this.preset.account.commercialName !== null
          ? this.preset.account.commercialName
          : this.preset.holder.company !== null
          ? this.preset.holder.company.fullName
          : this.preset.holder.fullName;
      previewData.address = `${this.preset.address.line1} ${this.preset.address.streetNumber}`;
      previewData.district = this.preset.address.district;
      previewData.city = this.preset.address.city.name;
      previewData.uf = this.preset.address.city.state;
      previewData.phone = this.preset.holder.phone;
      previewData.taxDocument =
        this.preset.holder.company !== null
          ? this.preset.holder.company.taxDocument
          : this.preset.holder.taxDocument;

      if (this.preset.holder.company === null) {
        this.$bus.$emit('alert-preview-logo-natural-person', previewData);
      } else {
        this.$bus.$emit('alert-preview-logo-juristic-person', previewData);
      }
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const api = new AccountApi();

      if (this.cropping) {
        var alert = new Alert();
        alert.title = 'Ops';
        alert.message = 'Conclua a edição do seu logo antes de salvar';
        this.$bus.$emit('alert-warning', alert);
      } else {
        var authCodeModel = {};

        swal({
          title: 'Enviaremos um código para autenticação',
          input: 'select',
          inputOptions: {
            email: `Email : ${this.email}`,
            mobile: `Celular : ${this.mobile}`,
          },
          inputPlaceholder: 'Selecione por onde você deseja receber',
          showCancelButton: true,
          preConfirm: (value) => {
            if (value == '') {
              swal.showValidationError('Você deve selecionar uma opção!');
            }
          },
          allowOutsideClick: () => !swal.isLoading(),
        }).then(async (res) => {
          if (
            res.dismiss &&
            (res.dismiss === 'close' ||
              res.dismiss === 'cancel' ||
              res.dismiss === 'overlay')
          ) {
            !swal.isLoading();

            return;
          }

          this.$bus.$emit('spinner-run');

          if (res.value == 'mobile') {
            authCodeModel = {
              mobile: true,
            };
          } else {
            authCodeModel = {
              mobile: false,
            };
          }

          var authCodeResponse = await api.sendAuthCodeByType(
            authCodeModel,
            'update-presets'
          );

          if (authCodeResponse.status != 204) {
            this.$alert.error('Ops, erro durante envio de código');

            this.$bus.$emit('spinner-stop');

            return;
          }

          this.$bus.$emit('spinner-stop');

          swal({
            title: 'Concluir alteração de dados',
            html: '<input type="text" maxlength="6" id="swal-input-authCode" class="form-control text-center" placeholder="Informe o código recebido">',
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            showCloseButton: true,
            preConfirm: (twoFactorAuthentication) => {
              this.authCode = document.getElementById(
                'swal-input-authCode'
              ).value;

              if (this.authCode == '') {
                swal.showValidationError('Campo obrigatório!');

                return;
              }

              var match = this.authCodeRegex.test(this.authCode);

              if (!match) {
                swal.showValidationError('Digite apenas números!');
              }
            },
            allowOutsideClick: () => !swal.isLoading(),
          }).then(async (res) => {
            if (
              res.dismiss &&
              (res.dismiss === 'close' ||
                res.dismiss === 'cancel' ||
                res.dismiss === 'overlay')
            ) {
              !swal.isLoading();

              return;
            }
            if (this.preset.bankAccount != null) {
              if (this.preset.bankAccount.variation == '') {
                this.preset.bankAccount.variation = null;
              }
            }

            this.$bus.$emit('spinner-run');
            var response;
            var errorInLogo = false;
            if (this.logo.changedLogo) {
              if (this.logo.display !== '') {
                let logoPayload = new FormData();
                logoPayload.append('name', 'image');
                logoPayload.append('logo', this.logo.image);
                response = await api.sendLogo(logoPayload, this.token);
                errorInLogo = response.data.error;
                if (!errorInLogo) {
                  this.logo.changedLogo = false;
                }
              } else {
                let logoPayload = new FormData();
                logoPayload.append('password', this.password);
                response = await api.deleteLogo(logoPayload, this.token);
                errorInLogo = response.data.error;
                if (!errorInLogo) {
                  this.logo.changedLogo = false;
                }
              }
            }

            const payload = new PresetParams(this.preset, this.migrate);

            if (!errorInLogo) {
              await api
                .savePreset(payload, this.authCode)
                .then(async (result) => {
                  if (result.status != 204) {
                    var alert = new Alert();
                    alert.title = 'Ops';
                    alert.confirmButtonText = 'Sair';

                    if (result.status == 422) {
                      const modelError = new HolderError(result.data);
                      const error = modelError.getMessage();
                      alert.error = error;
                    } else if (result.status == 400) {
                      alert.error = `Dados inválidos - ${result.data.errors[0]}`;
                    }

                    this.password = '';
                    this.$bus.$emit('spinner-stop');
                    this.$bus.$emit('alert-error', alert);
                  } else {
                    this.password = '';
                    await this.presets();
                    await this.askWhoAmI();
                    await this.loadBankAccount();
                    this.mobile = this.preset.holder.mobile;
                    this.email = this.preset.holder.email;
                    this.$bus.$emit('spinner-stop');
                    this.isDisabled = true;
                    this.$alert.info(
                      'Pronto',
                      'Alterações salvas com sucesso!'
                    );
                  }
                });
            } else {
              var alert = new Alert();
              alert.title = 'Ops';
              const modelError = new HolderError(response.data);
              const error = modelError.getMessage();
              alert.error = error;
              alert.confirmButtonText = 'Sair';
              this.password = '';
              this.$bus.$emit('spinner-stop');
              this.$bus.$emit('alert-error', alert);
            }
          });
        });
      }

      this.newBankAccount = false;
    },
    async banks() {
      const api = new AccountApi();
      this.listBanks = await api.banks();
    },
    async searchZipCode() {
      const zipCodeWithoutMask = this.preset.address.zipCode.replace('-', '');
      if (zipCodeWithoutMask.length === 8) {
        const api = new ZipCodeApi();
        this.$bus.$emit('spinner-run');
        const result = await api.searchByZipCode(zipCodeWithoutMask);
        if (result.erro) {
          this.$bus.$emit('spinner-stop');
          return;
        }
        this.preset.address.cityCode = result.ibge;
        this.preset.address.district = result.bairro;
        this.preset.address.line1 = result.logradouro;
        this.preset.address.line2 = result.complemento;
        this.preset.address.streetNumber = '';
      } else {
        this.preset.address = new Address();
      }
      this.$bus.$emit('spinner-stop');
    },
    cropImage() {
      this.logo.display = this.$refs.cropper.getCroppedCanvas().toDataURL();
      const block = this.logo.display.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];
      this.logo.image = this.b64toBlob(realData, contentType);
      this.cropping = false;
    },
    result(output) {
      this.logo.display = output;
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    undoLogoChange() {
      this.$alert.question(
        'Desfazer alterações',
        'Deseja realmente desfazer alterações em seu logotipo?'
      );
      this.$alert.onConfirm = async () => {
        const api = new AccountApi();
        this.$bus.$emit('spinner-run');
        await api.findLogo().then((response) => {
          if (response.status === 422) {
            this.logo.display = '';
            this.logo.image = '';
            this.logo.changedLogo = false;
          } else {
            this.logo.image = new File([response.data], {
              type: 'image/png',
            });
            this.logo.display = URL.createObjectURL(this.logo.image);
            this.logo.changedLogo = false;
          }
        });
        if (this.cropping) {
          this.cropping = false;
        }
        this.$bus.$emit('spinner-stop');
      };
    },
    async updateLogo() {
      const api = new AccountApi();

      if (this.cropping) {
        var alert = new Alert();
        alert.title = 'Ops';
        alert.message = 'Conclua a edição do seu logo antes de salvar';
        this.$bus.$emit('alert-warning', alert);
      } else {
        var authCodeModel = {};

        swal({
          title: 'Enviaremos um código para autenticação',
          input: 'select',
          inputOptions: {
            email: `Email : ${this.email}`,
            mobile: `Celular : ${this.mobile}`,
          },
          inputPlaceholder: 'Selecione por onde você deseja receber',
          showCancelButton: true,
          preConfirm: (value) => {
            if (value == '') {
              swal.showValidationError('Você deve selecionar uma opção!');
            }
          },
          allowOutsideClick: () => !swal.isLoading(),
        }).then(async (res) => {
          if (
            res.dismiss &&
            (res.dismiss === 'close' ||
              res.dismiss === 'cancel' ||
              res.dismiss === 'overlay')
          ) {
            !swal.isLoading();

            return;
          }

          this.$bus.$emit('spinner-run');

          if (res.value == 'mobile') {
            authCodeModel = {
              mobile: true,
            };
          } else {
            authCodeModel = {
              mobile: false,
            };
          }

          var authCodeResponse = await api.sendAuthCodeByType(
            authCodeModel,
            'update-presets'
          );

          if (authCodeResponse.status != 204) {
            this.$alert.error('Ops, erro durante envio de código');

            this.$bus.$emit('spinner-stop');

            return;
          }

          this.$bus.$emit('spinner-stop');

          swal({
            title: 'Concluir alteração de dados',
            html: '<input type="text" maxlength="6" id="swal-input-authCode" class="form-control text-center" placeholder="Informe o código recebido">',
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            showCloseButton: true,
            preConfirm: (twoFactorAuthentication) => {
              this.authCode = document.getElementById(
                'swal-input-authCode'
              ).value;

              if (this.authCode == '') {
                swal.showValidationError('Campo obrigatório!');

                return;
              }

              var match = this.authCodeRegex.test(this.authCode);

              if (!match) {
                swal.showValidationError('Digite apenas números!');
              }
            },
            allowOutsideClick: () => !swal.isLoading(),
          }).then(async (res) => {
            if (
              res.dismiss &&
              (res.dismiss === 'close' ||
                res.dismiss === 'cancel' ||
                res.dismiss === 'overlay')
            ) {
              !swal.isLoading();

              return;
            }
            if (this.preset.bankAccount != null) {
              if (this.preset.bankAccount.variation == '') {
                this.preset.bankAccount.variation = null;
              }
            }

            this.$bus.$emit('spinner-run');
            var response;
            var errorInLogo = false;
            if (this.logo.changedLogo) {
              if (this.logo.display !== '') {
                let logoPayload = new FormData();
                logoPayload.append('name', 'image');
                logoPayload.append('logo', this.logo.image);
                response = await api.sendLogo(logoPayload, this.authCode);
                errorInLogo = response.data.error;
                if (!errorInLogo) {
                  this.logo.changedLogo = false;
                }
              } else {
                let logoPayload = new FormData();
                logoPayload.append('password', this.password);
                response = await api.deleteLogo(logoPayload, this.authCode);
                errorInLogo = response.data.error;
                if (!errorInLogo) {
                  this.logo.changedLogo = false;
                }
              }
            }

            if (response.status != 204) {
              var alert = new Alert();
              alert.title = 'Ops';
              alert.confirmButtonText = 'Sair';

              if (response.status == 422) {
                const modelError = new HolderError(response.data);
                const error = modelError.getMessage();
                alert.error = error;
              } else if (response.status == 400) {
                alert.error = `Dados inválidos - ${response.data.errors[0]}`;
              }

              this.password = '';
              this.$bus.$emit('spinner-stop');
              this.$bus.$emit('alert-error', alert);
            } else {
              this.password = '';
              await this.presets();
              await this.askWhoAmI();
              await this.loadBankAccount();
              this.mobile = this.preset.holder.mobile;
              this.email = this.preset.holder.email;
              this.$bus.$emit('spinner-stop');
              this.isDisabledLogo = true;
              this.$alert.info(
                'Pronto',
                'Alteração salva com sucesso!'
              );
            }
          });
        });
      }
    },
    async disableBankAccount() {
      if (this.bankAccountEnabled) {
        const accountApi = new AccountApi();
        var authCodeModel = {};
        var authCodeType = 'delete-bank-account';

        var email = this.preset.holder.email;
        var mobile = this.preset.holder.mobile;

        if (this.preset.bankAccount.holderTaxDocument != null) {
          swal({
            title: 'Enviaremos um código para autenticação',
            input: 'select',
            inputOptions: {
              email: 'Email: ' + email,
              mobile: 'Celular : ' + mobile,
            },
            inputPlaceholder: 'Selecione por onde você deseja receber',
            preConfirm: (value) => {
              if (value == '') {
                swal.showValidationError('Você deve selecionar uma opção!');
              }
            },
            showCancelButton: true,
            allowOutsideClick: () => !swal.isLoading(),
          }).then(async (res) => {
            if (
              res.dismiss &&
              (res.dismiss === 'close' ||
                res.dismiss === 'cancel' ||
                res.dismiss === 'overlay')
            ) {
              this.bankAccountEnabled = true;

              !swal.isLoading();

              return;
            }

            this.$bus.$emit('spinner-run');

            if (res.value === 'mobile') {
              authCodeModel = {
                mobile: true,
              };
            } else {
              authCodeModel = {
                mobile: false,
              };
            }

            var authCodeResponse = await accountApi.sendAuthCodeByType(
              authCodeModel,
              authCodeType
            );

            if (authCodeResponse.status != 204) {
              const modelError = new TwoFactorAuthError(response.data);
              this.$alert.error(
                'Ops, erro durante autenticação',
                modelError.getMessage()
              );

              this.$bus.$emit('spinner-stop');

              return;
            }

            this.$bus.$emit('spinner-stop');

            swal({
              title: 'Deseja apagar seus dados bancários?',
              html: '<input type="text" maxlength="6" id="swal-input-authCode" class="form-control text-center" placeholder="Informe o código recebido">',
              confirmButtonText: 'Confirmar',
              showLoaderOnConfirm: true,
              showCloseButton: true,
              preConfirm: async (authCodeAuthentication) => {
                this.authCode = document.getElementById(
                  'swal-input-authCode'
                ).value;

                if (this.authCode == '') {
                  swal.showValidationError('Ambos os campos são obrigatórios!');

                  return;
                }

                var match = this.twoFactorDocumentRegex.test(this.authCode);

                if (!match) {
                  swal.showValidationError('Digite apenas números!');

                  return;
                }

                this.$bus.$emit('spinner-run');
                var response = await accountApi.deleteBankAccount(
                  this.authCode
                );

                if (
                  response.status == 422 &&
                  response.data.error == 'AUTH_CODE_INVALID'
                ) {
                  swal.showValidationError('Código informado inválido');

                  this.$bus.$emit('spinner-stop');
                }
              },
              allowOutsideClick: () => !swal.isLoading(),
            }).then(async (res) => {
              if (
                res.dismiss &&
                (res.dismiss === 'close' ||
                  res.dismiss === 'cancel' ||
                  res.dismiss === 'overlay')
              ) {
                this.bankAccountEnabled = true;

                !swal.isLoading();
              } else {
                this.$bus.$emit('spinner-stop');

                this.preset.bankAccount = null;

                await this.loadBankAccount();

                this.$alert.info('Pronto', 'Informações bancárias deletadas!');
              }
            });
          });
        }
      } else {
        this.preset.bankAccount = {
          isJuristic: false,
          bankNumber: null,
          bankName: null,
          accountNumber: null,
          bankBranchNumber: null,
          variation: null,
          type: null,
          holderFullName: null,
          holderTaxDocument: null,
        };

        this.newBankAccount = true;
      }

      this.bankAccountEnabled = !this.bankAccountEnabled;
    },
  },
  agencyTokens: {
    X: {
      pattern: /[0-9xX]/,
      transform: (v) => v.toLocaleUpperCase(),
    },
  },
  validations: {
    preset: {
      account: {
        softDescriptor: {
          required,
          softDescriptor,
        },
      },
      address: {
        zipCode: {
          required,
        },
        line1: {
          required,
          address,
        },
        streetNumber: {
          required,
          alphaNumeric,
        },
        line2: {
          alphaNumeric,
        },
        district: {
          required,
          district,
        },
      },
      bankAccount: {
        bankBranchNumber: {
          required: requiredIf(function () {
            return this.bankAccountEnabled;
          }),
          bankBranch,
        },
        accountNumber: {
          required: requiredIf(function () {
            return this.bankAccountEnabled;
          }),
          bankAccountNumber,
        },
        variation: {
          onlyNumbers,
        },
      },
      holder: {
        email: { email, required },
        contact: {
          phoneExtension: { onlyNumbers },
          email: { email, required },
          site: { url },
        },
      },
    },
  },
  directives: {
    mask,
  },
  watch: {
    preset: function (val) {
      if (val.bankAccount != null) {
        this.preset.bankAccount.type = val.bankAccount.type.toLowerCase();
      }
    },
    'preset.account.softDescriptor': function () {
      this.preset.account.softDescriptor =
        this.preset.account.softDescriptor.toUpperCase();
      this.preset.account.softDescriptor =
        this.preset.account.softDescriptor.replace(/[^a-zA-Z]/g, '');
    },
    'preset.bankAccount.holderTaxDocument': function () {
      if (this.preset.bankAccount != null) {
        if (
          this.bankAccountOwnerTaxDocument !== '' &&
          this.preset.bankAccount.holderTaxDocument !==
            this.bankAccountOwnerTaxDocument &&
          this.changedBankAccount == false
        ) {
          this.$alert.question(
            'Novas regras para dados bancários!',
            'Caso você continue essa alteração não será mais possível informar uma conta bancária de terceiros. Deseja continuar mesmo assim?'
          );
          this.$alert.onConfirm = async () => {
            this.changedBankAccount = true;
          };
        }

        if (
          this.preset.bankAccount.holderTaxDocument != null &&
          this.preset.bankAccount.holderTaxDocument.length > 14
        ) {
          this.preset.bankAccount.isJuristic = true;
        } else {
          this.preset.bankAccount.isJuristic = false;
        }
      }
    },
    changedBankAccount: function () {
      if (this.changedBankAccount === true) {
        this.preset.bankAccount.bankNumber = '';
        this.preset.bankAccount.type = '';
        this.preset.bankAccount.bankBranchNumber = '';
        this.preset.bankAccount.accountNumber = '';
        this.preset.bankAccount.variation = null;
        this.preset.bankAccount.holderTaxDocument =
          this.preset.holder.taxDocument;
        this.preset.bankAccount.holderFullName = null;
      } else {
        this.preset.bankAccount.holderTaxDocument =
          this.bankAccountOwnerTaxDocument;
      }
    },
    plan: function () {
      if (this.plan === '1') {
        if (this.preset.account.transferPlan.oldPlan) {
          this.migrate = true;
        }
        this.preset.account.transferPlan.anticipated = false;
        this.preset.account.transferPlan.daysPinpad = 32;
        this.preset.account.transferPlan.daysOnline = 32;
      } else if (this.plan === '2') {
        if (this.preset.account.transferPlan.oldPlan) {
          this.migrate = true;
        }
        this.preset.account.transferPlan.anticipated = true;
        this.preset.account.transferPlan.daysPinpad = 2;
        this.preset.account.transferPlan.daysOnline = 2;
      } else if (this.plan === '3') {
        if (this.preset.account.transferPlan.oldPlan) {
          this.migrate = true;
        }
        this.preset.account.transferPlan.anticipated = true;
        this.preset.account.transferPlan.daysPinpad = 32;
        this.preset.account.transferPlan.daysOnline = 32;
      } else {
        this.migrate = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    type: function () {
      return this.preset.bankAccount.type;
    },
    differentBankAccountOwner() {
      if (!this.preset || this.preset.bankAccount == null) {
        return false;
      }

      return (
        this.preset.bankAccount.holderFullName !== null &&
        this.preset.bankAccount.holderFullName !==
          this.preset.holder.fullName &&
        this.preset.holder.company !== null &&
        this.preset.bankAccount.holderFullName !==
          this.preset.holder.company.fullName
      );
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByPartner;
    },
    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.isOriginPartner;
    },
  },
  components: {
    VueCropper,
    WhiteLabel,
  },
};
</script>
<style lang="scss">
#action {
  cursor: pointer;
}
.swal2-input {
  width: 50% !important;
}
.swal-wide {
  width: 750px !important;
}
</style>
