<template>
  <div class="content">
    <div class="infos">
      <div class="d-flex flex-column align-items-center header">
        <em class="icon info-icon blue mb-2"></em>
        <span class="title">Informações importantes</span>
      </div>
      <p>
        As transfências são agendadas somente para os dias úteis. Em caso de
        feriados, o agendamento será realizado no próximo dia útil.
      </p>
      <p>
        Geralmente o valor é creditado em um dia útil na conta de destino e a
        taxa de transferência será cobrada a cada transferência confirmada.
      </p>
      <p>
        O seu saldo será transferido se ele atingir o valor minimo de R$30,00 +
        taxa de transferência
      </p>
    </div>
    <WhiteLabel>
      <button
        class="
          btn btn-orange btn-gray btn-pill
          pl-5
          pr-5
          align-self-center
          mt-4
          mb-4
        "
        @click="activeTransference()"
      >
        Ativar transferências
      </button>
    </WhiteLabel>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalImportantInfo',
  components: {
    WhiteLabel,
  },
  methods: {
    activeTransference() {
      this.$emit('activeTransference', true);
    },
  },
};
</script>
