<template>
  <div>
    <div class="filter">
      <AppFormFilter
        :filters="newFilterScheme"
        :isMobile="isMobile"
        :actionButton="dowloadCsvButton"
        @updateFilter="getExtractAccountFormFilter($event)"
        @action-button-click="downloadExtract($event)"
      />
    </div>

    <div class="balance-account">
      <div class="left">
        <WhiteLabel>
          <a class="pointer" @click="showBalance = !showBalance">
            <span class="text-uppercase label-16 font mr-1">
              Saldo em conta
            </span>
            <em class="icon-blocked icon-eye large-icons" />
          </a>
        </WhiteLabel>
        <div class="inline">
          <span class="R font">R$</span>
          <span v-show="showBalance" class="ml-1 value-balance font">
            {{
              balance.currentBalance
                | currency('', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </span>
          <span v-show="!showBalance" class="ml-1 value-balance font">
            .....
          </span>
        </div>
      </div>
      <div v-if="balance.dueBalance > 0">
        <span class="text-uppercase label-16 font">Saldo Devedor</span>
        <div class="inline">
          <span class="R font">R$</span>
          <span v-show="showBalance" class="ml-1 value-balance font">
            {{
              balance.dueBalance
                | currency('-', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </span>
          <span v-show="!showBalance" class="ml-1 value-balance font">
            .....
          </span>
        </div>
      </div>
      <div class="right">
        <button
          class="btn btn-muted btn-gray btn-pill pb-1"
          @click="collapse = !collapse"
          v-if="addTransferPermission"
        >
          <WhiteLabel>
            <i class="fa 2x fa-plus icon-plus" v-if="!collapse"></i>
            <i class="fa 2x fa-minus icon-minus" v-if="collapse"></i>
          </WhiteLabel>
        </button>
      </div>
    </div>
    <div class="collapsedDetails" v-if="collapse">
      <div class="d-flex justify-content-around">
        <div class="to-receive">
          <span v-show="showBalance" class="balanceBlock block label-20 font">
            {{
              balance.futureBalance
                | currency('R$', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </span>
          <span v-show="!showBalance" class="balanceBlock block label-20 font">
            R$ .....
          </span>
          <span class="text-uppercase label-16 font pt-2">A Receber</span>
        </div>
        <div class="anticipate ml-6 pl-3">
          <span v-show="showBalance" class="balanceBlock block label-20 font">
            {{
              balance.anticipatedBalance
                | currency('R$', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </span>
          <span v-show="!showBalance" class="balanceBlock block label-20 font">
            R$ .....
          </span>
          <span class="text-uppercase label-16 font pt-2">Antecipáveis</span>
        </div>
      </div>
      <div class="list-options pt-3">
        <ListOptions :balance="balance" />
      </div>
    </div>
    <div class="releases">
      <div v-if="extract && extract.days.length > 0" class="d-flex flex-column">
        <div class="total" v-show="!collapse">
          <div class="entrada">
            <em class="icon money-input-icon gray mr-2" />
            <span class="label-20 font text-color-green">
              +
              {{
                extract.input
                  | currency('R$', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}
            </span>
          </div>
          <div class="exit">
            <em class="icon money-output-icon gray mr-2" />
            <span class="font label-20 red">
              {{
                extract.output
                  | currency('R$', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}
            </span>
          </div>
        </div>

        <div class="historic">
          <span class="font-title">
            Lançamentos -
            {{ filter.params.startDate | moment('DD/MM/YYYY') }} até
            {{ filter.params.endDate | moment('DD/MM/YYYY') }}
          </span>
          <AppHistoric
            :historic="extract"
            view="extract"
            :detailsData="filter.detailExtract"
          />
        </div>

        <button
          v-if="this.isMobile && extract.count > extract.days.length"
          type="button"
          class="btn btn-outline-orange-more mb-6 align-self-center"
          @click="getMoreReleases()"
        >
          Carregar mais
        </button>
        <div v-else class="row justify-content-center mx-0">
          <app-paginator
            :count="extract.count"
            :perPage="perPage"
            @exchange="turnPage"
          />
        </div>
      </div>
      <div v-else>
        <AppNotFound message="Nenhuma movimentação no período" />
      </div>
    </div>
  </div>
</template>

<script>
import ListOptions from '@/components/dashboard/list-option/ListOption';
import AppHistoric from '@/components/shared/Historic';
import AppFormFilter from '@/components/shared/FormFilter';
import AppNotFound from '@/components/shared/NotFound';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AppPaginator from '@/components/theme/Paginator';
import { mapGetters } from 'vuex';

export default {
  name: 'AppBalanceExtract',

  components: {
    ListOptions,
    AppHistoric,
    AppFormFilter,
    AppNotFound,
    AppPaginator,
    WhiteLabel,
  },

  data: () => ({
    filter: null,
    newFilterScheme: {
      movementCategory: {
        title: 'Tipo da transação',
        type: 'select',
        options: [
          { value: null, label: 'Todos os lançamentos' },
          { value: 'ted', label: 'TED' },
          { value: 'pix', label: 'Pix' },
          { value: 'bill-payment', label: 'Pagamento de Contas' },
          { value: 'other', label: 'Outros' },
        ],
        value: { value: null, label: 'Todos os lançamentos' },
      },
      movementType: {
        title: 'Natureza da transação',
        type: 'select',
        options: [
          { value: null, label: 'Entradas e saídas' },
          { value: 'input', label: 'Entradas' },
          { value: 'output', label: 'Saídas' },
        ],
        value: { value: null, label: 'Entradas e saídas' },
      },
      detailExtract: {
        title: 'Detalhar lançamentos',
        type: 'checkbox',
        value: false,
      },
    },
    dowloadCsvButton: {
      text: 'Baixar CSV',
      icon: 'fa fa-download',
      display: true,
    },
    balance: 0,
    extract: null,
    collapse: false,
    perPage: 15,
    showBalance: false,
  }),

  computed: {
    isAndroid: function () {
      return navigator.userAgent.match(/Android/i);
    },

    isIOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },

    isMobile: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
    },

    ...mapGetters({
      permissions: 'account/permissions',
      whoAmI: 'account/whoAmI',
    }),

    isAccountHolder() {
      if (this.whoAmI) {
        return this.whoAmI.accessedByHolder;
      }
    },

    paymentAccountTransferPermissionIndex() {
      return this.permissions.findIndex((x) => x.name === 'paymentAccounts');
    },

    addTransferPermission() {
      if (
        this.permissions[this.paymentAccountTransferPermissionIndex].grant ===
          'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  async created() {
    this.$bus.$emit('spinner-run');
    await this.getBalanceAccount();
    this.$bus.$emit('spinner-stop');
  },

  methods: {
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },

    async getExtractAccountFormFilter(dateFilter) {
      var extractFilter = {
        detailExtract: this.newFilterScheme.detailExtract.value,
        params: {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          index: 0,
          length: 15,
          movementType: this.newFilterScheme.movementType.value.value,
          movementCategory: this.newFilterScheme.movementCategory.value.value,
        },
      };

      await this.getExtractAccount(extractFilter);
    },

    async downloadExtract(dateFilter) {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      var params = {
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        index: 0,
        length: 15,
        movementType: this.newFilterScheme.movementType.value.value,
        movementCategory: this.newFilterScheme.movementCategory.value.value,
      };
      await api.getStatementsFile(params);
      this.$bus.$emit('spinner-stop');
    },

    async getExtractAccount(filter) {
      this.$bus.$emit('spinner-run');
      this.filter = filter;
      const api = new PaymentAccountsApi();
      if (this.isMobile) {
        const newExtract = await api.getExtract(this.filter.params);
        if (this.filter.params.index === 0 && newExtract.days.length === 0) {
          this.extract = newExtract;
        } else {
          this.addExtracts(newExtract);
        }
      } else {
        this.extract = await api.getExtract(this.filter.params);
        this.extract.days = this.extract.days.slice(0, this.perPage);
      }
      this.$bus.$emit('spinner-stop');
    },
    getMoreReleases() {
      let newFilter = this.filter;
      newFilter.index = this.filter.index + this.filter.length + 1;
      this.getExtractAccount(newFilter);
    },
    addExtracts(newExtract) {
      if (this.extract) {
        this.extract.input += newExtract.input;
        this.extract.output += newExtract.output;
        this.extract.previousBalance += newExtract.previousBalance;

        this.extract.days = this.extract.days.concat(newExtract.days);
      } else {
        this.extract = newExtract;
      }
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.filter.params.index = inicio;
      await this.getExtractAccount(this.filter);
    },
  },
};
</script>

<style>
.text-color-green {
  color: #00d09d;
}
</style>
