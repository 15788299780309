<template>
  <div class="d-flex flex-column content-account-confirm">
    <div class="balance-available">
      <em class="money-output-icon icon gray-2"></em>
      <div class="first">
        <span>Saldo disponível no dia</span>
        <div class="inline">
          <span class="text-uppercase font-weight-bold" v-if="balance">{{
            balance.transferFee
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}</span>
          <span>de taxa de transferencia</span>
        </div>
      </div>
    </div>
    <div class="frequency">
      <em class="antecipate-icon icon gray-2"></em>
      <div>
        <span>Semanal -</span>
        <span class="font-weight-bold">{{ getDaysFormatting }}</span>
      </div>
    </div>
    <AppDetailsBank class="data-account-user" />
    <WhiteLabel class="pl-5 pr-5 align-self-center mt-4 mb-4">
      <button
        class="btn btn-orange btn-gray btn-pill"
        data-toggle="modal"
        data-target="#modall-important-info"
        @click="openImportantInfo"
      >
        Continuar
      </button>
    </WhiteLabel>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import AppDetailsBank from '@/components/account/transference/DetailsBank';
import AppModalImportantInfo from '@/components/account/preferences/ModalImportantInfo';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalPreferencesAccountConfirm',
  components: {
    AppDetailsBank,
    WhiteLabel,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    days: {
      required: true,
    },
  },
  data() {
    return {
      balance: null,
    };
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getBalanceAccount();
    this.$bus.$emit('spinner-stop');
  },
  computed: {
    getDaysFormatting() {
      let daysFormatting = '';
      let daysFormattingArray = ['', '', '', '', ''];

      if (this.days) {
        this.days.map((day) => {
          if (day === 'monday') daysFormattingArray[0] = 'Segunda-feira';
          if (day === 'tuesday') daysFormattingArray[1] = 'Terça-feira';
          if (day === 'wednesday') daysFormattingArray[2] = 'Quarta-feira';
          if (day === 'thursday') daysFormattingArray[3] = 'Quinta-feira';
          if (day === 'friday') daysFormattingArray[4] = 'Sexta-feira';
        });

        let daysFormatted = [];
        for (let i = 0; i < daysFormattingArray.length; i++) {
          if (daysFormattingArray[i] !== '') {
            daysFormatted.push(daysFormattingArray[i]);
          }
        }

        daysFormatting = daysFormatted.join(', ');

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        }
      }

      return daysFormatting;
    },
  },
  methods: {
    openImportantInfo() {
      this.$emit('openImportantInfo', 1.1);
    },
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },
  },
};
</script>
