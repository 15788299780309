export class CardFees {
  emvTerminalAmount = 0
  onlineAmount = null
}

export class CardBrandFees {
  brand = ''
  debit = null
  creditSingleInstallment = 0
  creditMultipleInstallment = 0
  multipleInstallmentPercent7To12 = 0
}

export class AnticipationFees {
  installmentNumber = 0
  manualPercent = 0
  autoPercent = 0
}

export class BankTransferFees {
  bankNumber = ''
  amount = 0
}

export class Fees {
  bankSlip = 0
  cardfee = new CardFees()
  cardBrandFee = [
    new CardBrandFees(),
  ]
  anticipationFee = [
    new AnticipationFees(),
  ]
  bankTransferFee = [
    new BankTransferFees(),
  ]
}
