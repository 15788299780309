<template>
    <div class="container mt-5">
        <div class="row justify-content-around">
            <EditLimits title="Titularidade própria"
                        v-if="!editAnotherHolder"
                        :key="sameHolderKey"
                        :dailyWorkingDaysLimit="sameHolderDailyWorkingDaysLimit"
                        :nocturnalWorkingDaysLimit="sameHolderNocturnalWorkingDaysLimit"
                        :dailyWeekendLimit="sameHolderDailyWeekendLimit"
                        :nocturnalWeekendLimit="sameHolderNocturnalWeekendLimit"
                        :sameHolderDestination="true"
                        @switchBetweenEditViewSameHolder="editSameHolderLimits"
                        @refresh="refreshLimits"/>

            <EditLimits title="Titularidade de terceiros"
                        v-if="!editSameHolder"
                        :key="anotherHolderKey"
                        :dailyWorkingDaysLimit="dailyWorkingDaysLimit"
                        :nocturnalWorkingDaysLimit="nocturnalWorkingDaysLimit"
                        :dailyWeekendLimit="dailyWeekendLimit"
                        :nocturnalWeekendLimit="nocturnalWeekendLimit"
                        :sameHolderDestination="false"
                        @switchBetweenEditViewAnotherHolder="editAnotherHolderLimits"
                        @refresh="refreshLimits"/>
        </div>
    </div>
</template>

<script>
import 'vuelidate/lib/validators';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import EditLimits from '@/components/account/configure-limits/EditLimits';

export default {
    name: 'ConfigureLimits',
    async mounted(){
        await this.refreshLimits();
    },
    components:{
        EditLimits,
    },
    data: () => ({
        editSameHolder: false,
        editAnotherHolder: false,
        dailyWorkingDaysLimit: null,
        nocturnalWorkingDaysLimit: null,
        dailyWeekendLimit: null,
        nocturnalWeekendLimit: null,
        sameHolderDailyWorkingDaysLimit: null,
        sameHolderNocturnalWorkingDaysLimit: null,
        sameHolderDailyWeekendLimit: null,
        sameHolderNocturnalWeekendLimit: null,
        sameHolderKey:0,
        anotherHolderKey:1,
        amount: '',
    }),
    methods: {
        async refreshLimits(){
            this.sameHolderKey += 1;
            this.anotherHolderKey += 1;
            this.$bus.$emit('spinner-run');
            let paymentAccApi = new PaymentAccountsApi();
            var response = await paymentAccApi.getTransferLimits();
            this.fillLimitObjects(response.transferAmountLimits);
            this.$bus.$emit('spinner-stop');
        },
        editSameHolderLimits(value){
            this.editSameHolder = value;
        },
        editAnotherHolderLimits(value){
            this.editAnotherHolder = value;
        },
        fillLimitObjects(limits){
            limits.forEach(limit => {
                if(limit.sameHolderDestination == true){
                    if(limit.workingDaysLimit != null){
                        this.sameHolderDailyWorkingDaysLimit = limit.workingDaysLimit.dailyCumulativeAmount;
                        this.sameHolderNocturnalWorkingDaysLimit = limit.workingDaysLimit.nocturnalCumulativeAmount;
                    }

                    if(limit.weekendLimit != null){
                        this.sameHolderDailyWeekendLimit = limit.weekendLimit.dailyCumulativeAmount;
                        this.sameHolderNocturnalWeekendLimit = limit.weekendLimit.nocturnalCumulativeAmount;
                    }
                }

                if(limit.sameHolderDestination == false){
                    if(limit.workingDaysLimit != null){
                        this.dailyWorkingDaysLimit = limit.workingDaysLimit.dailyCumulativeAmount;
                        this.nocturnalWorkingDaysLimit = limit.workingDaysLimit.nocturnalCumulativeAmount;
                    }

                    if(limit.weekendLimit != null){
                        this.dailyWeekendLimit = limit.weekendLimit.dailyCumulativeAmount;
                        this.nocturnalWeekendLimit = limit.weekendLimit.nocturnalCumulativeAmount;
                    }
                }
            });
        },
    },
};
</script>
