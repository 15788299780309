import CustomApiError from './CustomError';

export default class HolderError extends CustomApiError {
  toDictionary() {
    return {
      CITY_NOT_FOUND: 'Cidade não encontrada.',
      BANK_NOT_FOUND: 'Banco não encontrado.',
      TRANSFER_DAY_NOT_AVAILABLE: 'Dia para repasse não disponível.',
      WRONG_PASSWORD: 'Você digitou a senha errada.',
      CAN_NOT_CHANGE_ADDRESS: 'O endereço não pode ser alterado',
      CAN_NOT_CHANGE_HOLDER: 'Os dados do titular não pode ser alterados',
      EMAIL_ALREADY_TAKEN: 'Email já utilizado',
      TRANSFER_DAYS_NOT_AVAILABLE: 'Dias de transferência não disponíveis',
      TAX_DOCUMENT_MISSING: 'CPF/CNPJ ausente',
      PERSON_IS_NOT_JURISTIC:
        'Contas de pessoa física não podem ter contas bancárias de pessoa jurídica.',
      CAN_NOT_CHANGE_TRANSFER_PLAN: 'O plano não pode ser alterado',
      BANK_BRANCH_DIGIT_IS_NOT_VALID:
        'O dígito da agência bancária não é valido',
      ACCOUNT_DIGIT_IS_NOT_VALID: 'O dígito da conta não é válido',
      BANK_ACCOUNT_IS_NOT_VALID: 'Dados bancários inválidos',
      APPLICATION_TRANSFER_PLAN_INVALID:
        'O plano de transferência do aplicativo está inválido',
      ANTICIPATION_TRANSFER_PLAN_DISABLED: 'Plano de antecipação desabilitado',
      AUTH_CODE_INVALID: 'Código de autorização inválido.',
    };
  }
}
