<template>
  <ButtonFilter>
    <form class="form-filter" @submit.prevent="getListTransferencesAccount">
      <h1 class="sub-title">Período</h1>
      <div class="form-group content-form">
        <div class="dropdown period-mobile">
          <div
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center
            "
            id="dropdownMenuButtonPeriod"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-nowrap lbl-select">{{ periodSelectLabel }}</span>
          </div>
          <div
            class="dropdown-menu w-100"
            aria-labelledby="dropdownMenuButtonPeriod"
            @click.stop
          >
            <div
              class="material-radio"
              v-for="(data, index) in period"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="periodmobile"
                :id="data.value"
                :value="data.value"
                v-model="selectedPeriod"
              />
              <label :for="data.value" class="form-check-label lbl-filter">{{
                data.label
              }}</label>
            </div>
            <div class="report-period" v-if="selectedPeriod === 4">
              <div
                id="calendar-date-start"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="changeCalendarDateStart"
                class="
                  form-control
                  dropdown-select-like
                  rounded-pill
                  text-left text-lg-center text-nowrap
                "
              >
                <div
                  v-if="
                    selectedDate.start.getTime() !== selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">&nbsp;</span>
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                  <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
                  <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
                </div>
                <div
                  v-if="
                    selectedDate.start.getTime() === selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">no dia&nbsp;</span>
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                </div>
              </div>
              <div
                class="dropdown-menu"
                aria-labelledby="calendar-date-start"
                @click.stop
                :class="{ show: calendarDateStart }"
              >
                <v-date-picker
                  mode="range"
                  v-model="selectedDate"
                  :show-day-popover="false"
                  :tint-color="datePickerColor"
                  show-caps
                  is-inline
                ></v-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown period-web">
          <div
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center text-nowrap
            "
            id="dropdownMenuButton-c"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              v-if="selectedDate.start.getTime() !== selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">&nbsp;</span>
              <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
              <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
              <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
            </div>
            <div
              v-if="selectedDate.start.getTime() === selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">no dia&nbsp;</span>
              <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
            </div>
          </div>
          <div
            class="dropdown-menu dropdown-menu-fixed calendar"
            aria-labelledby="dropdownMenuButton-c"
            @click.stop
          >
            <v-date-picker
              mode="range"
              v-model="selectedDate"
              :show-day-popover="false"
              :tint-color="datePickerColor"
              show-caps
              is-inline
              is-double-paned
            ></v-date-picker>
            <hr />
            <div class="footer-calendar">
              <div>
                <div class="dropdown">
                  <div
                    class="
                      form-control
                      dropdown-select-like
                      rounded-pill
                      text-left text-lg-center
                    "
                    id="dropdownMenuButton-x"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="changeShowPeriod"
                  >
                    <span class="text-nowrap lbl-select">{{
                      periodSelectLabel
                    }}</span>
                  </div>
                  <div
                    class="dropdown-menu w-100 period"
                    :class="{ show: showPeriod }"
                    aria-labelledby="dropdownMenuButton-x"
                    @click.stop
                  >
                    <div
                      class="material-radio"
                      v-for="(data, index) in period"
                      :key="index"
                    >
                      <label class="form-check-label lbl-filter">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="status-period"
                          :value="data.value"
                          v-model="selectedPeriod"
                        />
                        {{ data.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-date">
                <div class="date-start border-date">
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                </div>
                <div class="calendar-txt">
                  <p>a</p>
                </div>
                <div class="date-end border-date">
                  <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
                </div>
              </div>
              <div class="content-btn">
                <WhiteLabel>
                  <button
                    class="btn btn-applay"
                    @click="getListTransferencesAccount"
                  >
                    Aplicar
                  </button>
                </WhiteLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 class="sub-title">Status</h1>
      <div class="form-group content-form">
        <div class="dropdown">
          <div
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center
            "
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-nowrap lbl-select">{{ statusSelectLabel }}</span>
          </div>
          <div
            class="dropdown-menu w-100"
            aria-labelledby="dropdownMenuButton"
            @click.stop
          >
            <div
              class="material-radio"
              v-for="(data, index) in status"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="statusradios"
                :id="data.value"
                :value="data.value"
                v-model="filter.status"
              />
              <label :for="data.value" class="form-check-label lbl-filter">{{
                data.label
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex d-md-none mt-5">
        <button type="submit" class="btn btn-send-filter">
          Aplicar filtros
        </button>
      </div>
    </form>
  </ButtonFilter>
</template>

<script>
import 'v-calendar/lib/v-calendar.min.css';
import TransferenceFilters from '@/models/TransferenceFilters';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import moment from 'moment';
import { mask } from 'vue-the-mask';

import ButtonFilter from '@/components/shared/ButtonFilter';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppFormFilterTransference',
  components: {
    ButtonFilter,
    WhiteLabel,
  },
  data() {
    return {
      showPeriod: false,
      calendarDateStart: false,
      status: [
        { value: 'all', label: 'Todos os status' },
        { value: 'approved', label: 'Confirmadas' },
        { value: 'pending', label: 'Pendentes' },
        { value: 'provisioned', label: 'Em processamento' },
        { value: 'canceled', label: 'Canceladas' },
        { value: 'reproved', label: 'Devolvidas' },
      ],
      period: [
        { value: 1, label: 'Últimos 7 dias' },
        { value: 2, label: 'Últimos 30 dias' },
        { value: 3, label: 'Últimos 3 meses' },
        { value: 4, label: 'Informar período' },
      ],
      filter: new TransferenceFilters(),
      selectedDate: {
        start: new Date().addDays(-7),
        end: new Date(),
      },
      selectedPeriod: 1,
      listMoreTransfers: false,
    };
  },
  directives: {
    mask,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    numberPage: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    perPage: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    moreTransfers: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    isMobile: {
      required: true,
    },
  },
  async mounted() {
    this.filter.offset.limit = this.perPage;
    await this.getListTransferencesAccount();

    this.$bus.$on('updateListTransference', (result) => {
      this.getListTransferencesAccount();
    });
  },
  methods: {
    changeShowPeriod: function (event) {
      this.showPeriod = !this.showPeriod;
    },
    changeCalendarDateStart: function (event) {
      this.calendarDateStart = !this.calendarDateStart;
    },
    async getListTransferencesAccount() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      const transferences = await api.getListTransferences(this.filter);
      if (transferences.count === 0) {
        this.$emit(
          'captureNotFound',
          this.filter.startDate,
          this.filter.endDate
        );
      }
      if (this.isMobile) {
        if (this.filter.offset.index === 0) {
          this.transferences = transferences;
        } else {
          this.concatTransfers(transferences);
        }
      } else {
        this.transferences = transferences;
      }
      this.$emit('captureTransference', this.transferences);
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.filter.offset.index = inicio;
      await this.getListTransferencesAccount();
    },
    getMoreTransfers() {
      this.filter.offset.index += this.filter.offset.limit;
      this.getListTransferencesAccount();
    },
    concatTransfers(transferences) {
      if (this.transferences) {
        if (
          this.transferences.days[this.transferences.days.length - 1].day ===
          transferences.days[0].day
        ) {
          this.transferences.days[
            this.transferences.days.length - 1
          ].cashoutRequests = this.transferences.days[
            this.transferences.days.length - 1
          ].cashoutRequests.concat(transferences.days[0].cashoutRequests);
          transferences.days.splice(0, 1);
        }
        this.transferences.days = this.transferences.days.concat(
          transferences.days
        );
      } else {
        this.transferences = transferences;
      }
    },
  },
  computed: {
    statusSelectLabel: function () {
      let objetc = this.status.filter((s) => s.value === this.filter.status);
      if (objetc.length > 0) {
        return objetc[0].label;
      } else {
        return 'Selecione Status';
      }
    },
    periodSelectLabel: function () {
      let objetc = this.period.filter((s) => s.value === this.selectedPeriod);
      if (objetc.length > 0) {
        return objetc[0].label;
      } else {
        return 'Selecione Período';
      }
    },
    datePickerColor: function () {
      return window.localStorage.getItem('tertiaryColor') || '#FF7606';
    },
  },
  watch: {
    'filter.status': async function (newValue) {
      await this.getListTransferencesAccount();
    },
    'selectedDate.start': function (newValue) {
      this.filter.startDate = moment(newValue).format('YYYY-MM-DD');
    },
    'selectedDate.end': function (newValue) {
      this.filter.endDate = moment(newValue).format('YYYY-MM-DD');
    },
    numberPage: async function (newValue) {
      await this.turnPage(newValue);
    },
    moreTransfers: async function (newValue) {
      await this.getMoreTransfers();
    },
    isMobile: function (newValue) {
      this.isMobile = newValue;
    },
    selectedPeriod: function (newValue) {
      switch (newValue) {
        case 1:
          this.selectedDate.start = new Date().addDays(-7);
          this.selectedDate.end = new Date();
          break;
        case 2:
          this.selectedDate.start = new Date().addDays(-30);
          this.selectedDate.end = new Date();
          break;
        case 3:
          this.selectedDate.start = new Date().addDays(-90);
          this.selectedDate.end = new Date();
          break;
        case 4:
          break;
      }
    },
  },
};
</script>
