<template>
  <div class="not-found">
    <em class="icon-calendar calendar"></em>
    <p class="message">{{ message }}</p>
    <p class="text-center">
      Selecione outro período para visualizar mais informações.
    </p>
  </div>
</template>
<script>
export default {
  name: 'AppNotFound',
  props: {
    message: {
      required: true,
      type: String,
      value: 'Sem registros encontrados',
    },
  },
};
</script>
