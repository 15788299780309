<template>
  <div>
    <div class="header-box mb-3 py-4">
      <div class="container">
        <div class="row">
          <div class="d-none d-print-block ml-3 mb-4 mr-auto">
            <img src="/static/img/logo-blue.png" />
          </div>
          <form
            class="px-3 form-inline mb-4 mb-lg-0 mx-auto order-1 order-lg-2 d-print-none"
          >
            <div class="form-group">
              <label class="text-nowrap mr-3 d-none d-lg-block"
                >Ano fiscal:</label
              >
              <WhiteLabel>
                <select
                  class="form-control form-control-lg input-pill px-5"
                  id="selectYear"
                  v-model="selectedYear"
                >
                  <option
                    v-for="(year, index) in yearList"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </WhiteLabel>
            </div>
          </form>
          <div class="col-sm-12 col-lg order-2 order-lg-1">
            <div class="h3 font-weight-light d-flex">
              DIRF - Declaração do Imposto de Renda Retido na Fonte
              <span class="ml-2 d-none d-print-inline-block">{{
                selectedYear
              }}</span>
            </div>
            <div v-if="preset.holder.company !== null">
              <span>{{ preset.holder.company.fullName }}</span>
              <span class="text-nowrap"
                >CPF: {{ preset.holder.company.taxDocument }}</span
              >
            </div>
            <div v-if="preset.holder.company === null">
              <span>{{ preset.holder.fullName }}</span>
              <span class="text-nowrap"
                >CPF: {{ preset.holder.taxDocument }}</span
              >
            </div>
            <div>
              <span>Emissor: PAGCERTO SOLUCOES EM PAGAMENTO S.A.</span>
              <span class="text-nowrap">CNPJ: 17.819.084/0001-92</span>
            </div>
            <div class="mt-3">
              <WhiteLabel>
                <button class="btn btn-orange d-print-none" @click="print">
                  <i class="icon-printer"></i> Imprimir
                </button>
              </WhiteLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-print-none">
      <div class="row mb-3 d-none d-lg-flex d-print-flex" v-if="count !== 0">
        <div class="w-100px d-flex"></div>
        <div class="col-12 col-w-full col-lg">
          <div class="row no-gutters">
            <div class="col-md-6 d-flex">
              <div class="my-auto mx-auto text-muted">
                RENDIMENTO TRIBUTÁVEL
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="my-auto mx-auto text-muted">IMPOSTO DE RENDA</div>
            </div>
          </div>
          <hr class="d-none d-print-block" />
        </div>
      </div>
      <div class="row mb-3" v-for="(taxMonth, index) in taxes" :key="index">
        <div class="w-100px d-flex ml-3 ml-lg-0">
          <span
            class="h6 mb-2 mb-lg-auto mt-0 mt-lg-auto ml-0 ml-lg-auto text-muted font-weight-light text-uppercase"
            >{{ taxMonth.date | moment('MMMM') }}</span
          >
        </div>
        <div class="col-12 col-w-full col-lg">
          <div class="card mb-0">
            <div class="row no-gutters px-0 py-2">
              <div class="col-12 col-w-full col-md-6 d-flex mb-3 mb-md-0">
                <div class="my-auto mx-auto text-center">
                  <small class="text-muted d-block d-lg-none d-print-none">
                    RENDIMENTO TRIBUTÁVEL
                    <br />
                  </small>
                  <span class="h4 m-0">{{
                    taxMonth.amount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}</span>
                </div>
              </div>
              <div class="col-12 col-w-full col-md-6 d-flex">
                <div class="my-auto mx-auto text-center">
                  <small class="text-muted d-block d-lg-none d-print-none">
                    IMPOSTO DE RENDA
                    <br />
                  </small>
                  <span class="h4 m-0">{{
                    taxMonth.tax
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <hr class="d-none d-print-block" />
        </div>
      </div>
      <div class="row mb-5" v-if="count !== 0">
        <div class="w-100px d-flex"></div>
        <div class="col-12 col-w-full col-lg">
          <div class="row no-gutters py-2">
            <div class="col-md-6 d-flex mb-3 mb-md-0">
              <div class="m-auto text-center">
                <small class="text-muted d-block d-lg-none d-print-none">
                  TOTAL DE RENDIMENTO TRIBUTÁVEL
                  <br />
                </small>
                <span class="h4 m-0">{{
                  yearAmount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}</span>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="m-auto text-center">
                <small class="text-muted d-block d-lg-none d-print-none">
                  TOTAL DE IMPOSTO DE RENDA
                  <br />
                </small>
                <span class="h4 m-0">{{
                  yearTaxAmount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PRINT -->
    <div class="container d-none d-print-block">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-5 text-center">RENDIMENTO TRIBUTÁVEL</div>
        <div class="col-5 text-center">IMPOSTO DE RENDA</div>
      </div>
      <hr />
      <div class="row py-3" v-for="(taxMonth, index) in taxes" :key="index">
        <div class="col-2 h5 text-uppercase">
          {{ taxMonth.date | moment('MMMM') }}
        </div>
        <div class="col-5 text-center">
          <span class="h5 m-0">{{
            taxMonth.amount
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}</span>
        </div>
        <div class="col-5 text-center">
          <span class="h4 m-0">{{
            taxMonth.tax
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}</span>
        </div>
      </div>
      <hr />
      <div class="row py-3">
        <div class="col-2 h5 text-uppercase">total</div>
        <div class="col-5 text-center">
          <span class="h4 mb-0">{{
            yearAmount
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}</span>
        </div>
        <div class="col-5 text-center">
          <span class="h4 mb-0">{{
            yearTaxAmount
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import AccountApi from '@/services/AccountApi';
import { TaxesFilter } from '@/models/TaxSummary';
import { Presets } from '@/models/Presets';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppProofOfIncome',
  components: {
    WhiteLabel,
  },
  async mounted() {
    this.selectedYear = this.lastYear;
    await this.getTaxSummary();
    await this.presets();
  },
  data: () => {
    return {
      taxes: [],
      count: 0,
      year: '',
      filters: new TaxesFilter(),
      preset: new Presets(),
      selectedYear: '',
    };
  },
  methods: {
    async find() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      this.payment = await api.findPayment(this.$route.params.paymentId);
      this.$bus.$emit('spinner-stop');
    },
    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },
    async getTaxSummary() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      this.filters.year = this.selectedYear;
      var result = await api.taxSummary(this.filters);
      this.taxes = result.taxes;
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    print() {
      window.print();
    },
  },
  computed: {
    lastYear() {
      const lastYear = new Date().getFullYear();
      return lastYear - 1;
    },
    yearList() {
      const year = new Date().getFullYear();
      return [year, year - 1, year - 2];
    },
    yearAmount() {
      let total = 0;
      for (let i = 0; this.taxes.length > i; i++) {
        total += this.taxes[i].amount;
      }
      return total;
    },
    yearTaxAmount() {
      let total = 0;
      for (let i = 0; this.taxes.length > i; i++) {
        total += this.taxes[i].tax;
      }
      return total;
    },
  },
  watch: {
    selectedYear: async function () {
      await this.getTaxSummary();
    },
  },
};
</script>
