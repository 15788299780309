<template>
  <div class="mt-5">
    <form @submit.prevent="generateAuthCode" autocomplete="off">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-w-full col-lg-8">
            <div class="card">
              <div class="d-flex">
                <h3 class="font-weight-light ml-3 mr-auto pull-left">
                  Alterar Senha
                </h3>
              </div>
              <hr />
              <div class="card-content">
                <div class="row justify-content-center">
                  <div class="container-fluid col-8">
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                        mb-4
                      "
                    >
                      <label>Digite a sua senha atual:</label>
                      <Password
                        v-model="oldPassword"
                        :showStrengthMeter="false"
                        :secureLength="6"
                      />
                    </div>
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                        mb-4
                      "
                    >
                      <label>Digite a sua nova senha:</label>
                      <Password
                        v-model="password"
                        :secureLength="8"
                        :toggle="true"
                        :maxlength="20"
                      />
                      <small
                        class="text-danger"
                        v-if="password.length > 0 && $v.password.$invalid"
                      >
                        A Senha está no formato inválido, Exemplo: Ex4plo@123.
                      </small>
                    </div>
                    <div
                      class="
                        form-group
                        col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                        mb-4
                      "
                    >
                      <label>Repita a sua nova senha:</label>
                      <Password
                        v-model="confirmPassword"
                        :secureLength="8"
                        :toggle="true"
                        :maxlength="20"
                      />
                      <small
                        class="text-danger"
                        v-if="!$v.confirmPassword.sameAsPassword"
                        >Confirme sua senha</small
                      >
                    </div>
                  </div>
                  <div class="container-fluid col-4">
                    <h5>Como sua senha deve ser</h5>
                    <p>
                      Não coloque o seu nome, sobrenome, e-mail, nem caracteres
                      idênticos e consecutivos.
                    </p>
                    <p>
                      A senha deve ter entre 8 e 20 caracteres e conter pelo
                      menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1
                      caractere especial.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 col-lg-4">
            <WhiteLabel>
              <button
                class="btn btn-orange p-3 h4 w-100"
                :disabled="$v.$invalid"
              >
                Continuar
              </button>
            </WhiteLabel>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import swal from 'sweetalert2';
import { Presets } from '@/models/Presets';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import AccountApi from '@/services/AccountApi';
import Alert from '@/models/Alert';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Password from 'vue-password-strength-meter';
import { password } from '@/validations/password';
import CreateNewPasswordError from '@/errors/CreateNewPasswordError';

export default {
  name: 'AppPasswordChange',

  components: {
    WhiteLabel,
    Password,
  },

  data() {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      authCode: '',
      preset: new Presets(),
      authCodeRegex: new RegExp('^[0-9]+$'),
    };
  },

  computed: {
    ...mapGetters({
      token: 'account/token',
      whoAmI: 'account/whoAmI',
    }),
  },

  async mounted() {
    await this.presets();
  },

  methods: {
    ...mapGetters({
      token: 'account/token',
    }),

    ...mapActions({
      auth: 'account/login',
      keepToken: 'account/keepToken',
    }),

    async presets() {
      this.$bus.$emit('spinner-run');
      const api = new AccountApi();
      this.preset = await api.presets();
      this.$bus.$emit('spinner-stop');
    },

    async sendNewPassword() {
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');

      const response = await api.changePassword(
        this.oldPassword,
        this.password,
        this.authCode
      );

      this.$bus.$emit('spinner-stop');

      if (response.status === 422) {
        var alert = new Alert();
        alert.title = 'Ops';
        const passwordError = new CreateNewPasswordError(response.data);
        alert.error = passwordError.getMessage();
        alert.confirmButtonText = 'Ok';
        this.$bus.$emit('alert-error', alert);
      } else if (response.status === 200) {
        this.keepToken(response.data.token);
        const title = 'Pronto!';
        this.$alert.info(title, 'Senha cadastrada com sucesso.');
        this.$router.push({ name: 'root' });
      } else if (response.status === 400) {
        var alert = new Alert();
        alert.title = 'Ops';
        alert.error = 'Não foi possível atulizar a senha.';
        alert.confirmButtonText = 'Ok';
        this.$bus.$emit('alert-error', alert);
      } else {
        var alert = new Alert();
        alert.title = 'Ops';
        alert.error = 'Erro inesperado!';
        alert.confirmButtonText = 'Ok';
        this.$bus.$emit('alert-error', alert);
      }
    },

    async generateAuthCode() {
      const api = new AccountApi();
      var mobile = this.preset.holder.mobile;
      var email = this.preset.holder.email;
      var authCodeModel = {};
      swal({
        title: 'Enviaremos um código para autenticação',
        input: 'select',
        inputOptions: {
          email: `Email : ${email}`,
          mobile: `Celular : ${mobile}`,
        },
        inputPlaceholder: 'Selecione por onde você deseja receber',
        showCancelButton: true,
        preConfirm: (value) => {
          if (value == '') {
            swal.showValidationError('Você deve selecionar uma opção!');
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();

          return;
        }

        this.$bus.$emit('spinner-run');

        if (res.value == 'mobile') {
          authCodeModel = {
            mobile: true,
          };
        } else {
          authCodeModel = {
            mobile: false,
          };
        }

        var authCodeResponse = await api.sendAuthCodeByType(
          authCodeModel,
          'update-presets'
        );

        if (authCodeResponse.status != 204) {
          this.$alert.error('Ops, erro durante envio de código');

          this.$bus.$emit('spinner-stop');

          return;
        }

        this.$bus.$emit('spinner-stop');

        swal({
          title: 'Concluir alteração de dados',
          html: '<input type="text" maxlength="6" id="swal-input-authCode" class="form-control text-center" placeholder="Informe o código recebido">',
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          showCloseButton: true,
          preConfirm: (twoFactorAuthentication) => {
            this.authCode = document.getElementById(
              'swal-input-authCode'
            ).value;

            if (this.authCode == '') {
              swal.showValidationError('Campo obrigatório!');

              return;
            }

            var match = this.authCodeRegex.test(this.authCode);

            if (!match) {
              swal.showValidationError('Digite apenas números!');
            }
          },
          allowOutsideClick: () => !swal.isLoading(),
        }).then(async (res) => {
          if (
            res.dismiss &&
            (res.dismiss === 'close' ||
              res.dismiss === 'cancel' ||
              res.dismiss === 'overlay')
          ) {
            !swal.isLoading();

            return;
          }
          await this.sendNewPassword();
        });
      });
    },
  },

  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
    },
    password: {
      required,
      minLength: minLength(8),
      password,
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>
